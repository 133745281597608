import React, {useContext, useEffect, useState} from 'react';
import {Modal, Button, Divider, Select, DatePicker, Checkbox, Radio} from 'antd';
import {addHealthManagement, addUser, getHealthManagement, getUsers, updateHealthManagement} from "../api";
import {Store} from "../state_store/provider";
import {HEALTH_MANAGEMENT, USERS} from "../state_store/actionType";
import {Option} from "antd/es/mentions";
import {Role} from "../constant";
import * as yub from "yup";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {FormFormat, Request} from "../utils";
import moment from "moment/moment";


const hours = ()=>{
    let a = []
    for(let i=0; i<24; i++)
        if(i<10)
            a.push(`0${i}`)
        else
            a.push(`${i}`)
    return a
}
const schema = yub.object().shape({
    id:yub.string().required(),

}).required()
export default function ManagementEdition(props) {
    const {open, onClose, onOK, data} = props
    const {state, dispatch} = useContext(Store)
    const {register, handleSubmit, control, formState: {errors}, clearErrors, setValue, reset} = useForm({
        resolver: yupResolver(schema)
    });

    const onUpdateManagement = (value) => {

        const data_submit = {
            ...value,
            old_id:parseInt(data.id),
            id:parseInt(value.id),
            old_timing: data.timing,
            site: state.site.find(res=>res.id === parseInt(value.id))?.name,
            notice_time: value.hour + ":" + value.minute,
            from_date: value.from_date.format("YYYY-MM-DD"),
            to_date: value.to_date.format("YYYY-MM-DD"),
            day_of_week: value.day_of_week.reduce((a, b) => a + b, 0),
            timing:value.timing + ":00:00",

        }

        Request(dispatch, updateHealthManagement(FormFormat(data_submit)), onOK).then(error => {
            if (!error) {
                getHealthManagement().then(res=> {
                    if(res.result === 0)
                        dispatch({type: HEALTH_MANAGEMENT, payload: res.healthManagementList})
                })
                reset()
            }
        })

    }

    useEffect(()=>{
        clearErrors()
        reset()
        for(const key in data){
            if(key === "notice_time"){
                let notice_name = data[key].split(":")
                setValue("hour", notice_name[0])
                setValue("minute", notice_name[1])
            }else if(key === "from_date"){
                setValue(key, moment(data[key]))
            }else if(key === "to_date"){
                setValue(key, moment(data[key]))
            }else if(key === "timing"){
                setValue(key, data[key].substring(0, 2))
            }else if(key === "day_of_week"){
                let value = data[key]
                let check = [
                    value&1??1,
                    value&2??2,
                    value&4??4,
                    value&8??8,
                    value&16??16,
                    value&32??32,
                    value&64??64
                ]
                setValue(key, check)
            }

            else setValue(key, data[key])
        }
    },[data])


    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>体調管理項目編集</div>
                <div className={"subtitle"}>編集する体調管理項目を入力してください。</div>
                <Divider className={"divider"}/>


                <div className={"add-dialog-body"}>
                    <form id={"management-edition"} onSubmit={handleSubmit(onUpdateManagement)}>

                        <div className={"input-title"}>現場名</div>
                        <Controller
                            control={control}
                            name={"id"}
                            render={({field}) => (
                                <Select className={`select-field`}
                                        status={errors.id ? "error" : ""}

                                        {...field}
                                    // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                >
                                    {state.site.map(item => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        />
                        <div>{errors.id?.message}</div>


                        <div className={"input-title"}>質問タイミング</div>
                        <div className={"row small-select-layout"}>
                            <Controller
                                control={control}
                                name={"timing"}
                                render={({field}) => (
                                    <Select className={`select-field question-time`}
                                            status={errors.timing ? "error" : ""}

                                            {...field}
                                        // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                    >
                                        {hours().map(item => (
                                            <Option value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                )}
                            />
                            <div className={"label"}>時</div>
                        </div>

                        <div>{errors.timing?.message}</div>

                        <div className={"input-title"}>通知時間</div>

                        <div className={"row small-select-layout"}>
                            <Controller
                                control={control}
                                name={"hour"}
                                render={({field}) => (
                                    <Select className={`select-field notification-time`}
                                            status={errors.hour ? "error" : ""}

                                            {...field}
                                        // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                    >
                                        {hours().map(item => (
                                            <Option value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                )}
                            />
                            <div className={"label"}>時</div>

                            <Controller
                                control={control}
                                name={"minute"}
                                render={({field}) => (
                                    <Select className={`select-field notification-time`}
                                            status={errors.minute ? "error" : ""}

                                            {...field}
                                        // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                    >
                                        {["00","15", "30", "45"].map(item => (
                                            <Option value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                )}
                            />
                            <div className={"label"}>分</div>
                        </div>

                        <div className={"input-title"}>通知開始⽇付</div>

                        <Controller
                            control={control}
                            render={({field}) =>
                                <DatePicker
                                    {...field}
                                    placeholder={""}/>
                            }
                            name={"from_date"}
                        />


                        <div className={"input-title"}>通知終了⽇付</div>
                        <Controller
                            control={control}
                            render={({field}) =>
                                <DatePicker
                                    {...field}
                                    placeholder={""}/>
                            }
                            name={"to_date"}
                        />

                        <div className={"input-title"}>通知曜⽇</div>
                        <div className={"row notification-day"}>
                            <Controller
                                control={control}
                                render={({field}) =>
                                    <Checkbox.Group {...field}>
                                        <div className={"column"}>
                                            <div className={"input-title"}>⽇</div>
                                            <div><Checkbox value={1}/></div>
                                        </div>
                                        <div className={"column"}>
                                            <div className={"input-title"}>⽉</div>
                                            <div><Checkbox value={2}/></div>
                                        </div>
                                        <div className={"column"}>
                                            <div className={"input-title"}>⽕</div>
                                            <div><Checkbox value={4}/></div>
                                        </div>
                                        <div className={"column"}>
                                            <div className={"input-title"}>⽔</div>
                                            <div><Checkbox value={8}/></div>
                                        </div>
                                        <div className={"column"}>
                                            <div className={"input-title"}>⽊</div>
                                            <div><Checkbox value={16}/></div>
                                        </div>
                                        <div className={"column"}>
                                            <div className={"input-title"}>⾦</div>
                                            <div><Checkbox value={32}/></div>
                                        </div>
                                        <div className={"column"}>
                                            <div className={"input-title"}>⼟</div>
                                            <div><Checkbox value={64}/></div>
                                        </div>
                                    </Checkbox.Group>
                                }
                                name={"day_of_week"}/>

                        </div>


                        <div className={"input-title"}>質問１</div>
                        <input type="text"
                               className={`ant-input ${errors.question1 ? "error" : ""}`}
                               {...register("question1")}/>
                        <div className={"helper-text-error"}>
                            {errors.question1?.message}</div>

                        <div className={"input-title"}>質問１回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer1"}
                            />

                        </div>

                        <div className={"input-title"}>質問２</div>
                        <input type="text"
                               className={`ant-input ${errors.question2 ? "error" : ""}`}
                               {...register("question2")}/>
                        <div className={"helper-text-error"}>
                            {errors.question2?.message}</div>


                        <div className={"input-title"}>質問２回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer2"}
                            />
                        </div>


                        <div className={"input-title"}>質問３</div>
                        <input type="text"
                               className={`ant-input ${errors.question3 ? "error" : ""}`}
                               {...register("question3")}/>
                        <div className={"helper-text-error"}>
                            {errors.question3?.message}</div>


                        <div className={"input-title"}>質問３回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer3"}
                            />
                        </div>


                        <div className={"input-title"}>質問４</div>
                        <input type="text"
                               className={`ant-input ${errors.question4 ? "error" : ""}`}
                               {...register("question4")}/>
                        <div className={"helper-text-error"}>
                            {errors.question4?.message}</div>


                        <div className={"input-title"}>質問４回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer4"}
                            />
                        </div>


                        <div className={"input-title"}>質問５</div>
                        <input type="text"
                               className={`ant-input ${errors.question5 ? "error" : ""}`}
                               {...register("question5")}/>
                        <div className={"helper-text-error"}>
                            {errors.question5?.message}</div>


                        <div className={"input-title"}>質問５回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer5"}
                            />
                        </div>

                        <div className={"input-title"}>質問６</div>
                        <input type="text"
                               className={`ant-input ${errors.question6 ? "error" : ""}`}
                               {...register("question6")}/>
                        <div className={"helper-text-error"}>
                            {errors.question6?.message}</div>


                        <div className={"input-title"}>質問６回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer6"}
                            />
                        </div>


                        <div className={"input-title"}>質問７</div>
                        <input type="text"
                               className={`ant-input ${errors.question7 ? "error" : ""}`}
                               {...register("question7")}/>
                        <div className={"helper-text-error"}>
                            {errors.question7?.message}</div>


                        <div className={"input-title"}>質問７回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer7"}
                            />
                        </div>


                        <div className={"input-title"}>質問８</div>
                        <input type="text"
                               className={`ant-input ${errors.question8 ? "error" : ""}`}
                               {...register("question8")}/>
                        <div className={"helper-text-error"}>
                            {errors.question8?.message}</div>


                        <div className={"input-title"}>質問８回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer8"}
                            />
                        </div>

                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor: "#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button

                        htmlType={"submit"}
                        form={"management-edition"}
                        type="primary" style={{backgroundColor: "#377EF3"}}>追加する</Button>
                </div>
            </div>
        </Modal>

    )
};
