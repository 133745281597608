import React, {useContext, useEffect, useState} from 'react';
import {Modal, Button, Divider, Input, Select} from 'antd';

import {Store} from "../state_store/provider";

import {Option} from "antd/es/mentions";
import {FormFormat, Request} from "../utils";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yub from "yup";
import moment from "moment";
import {addHeavyMachineryPoint, getHeavyMachineryPoints, updateHeavyMachineryPointName} from "../api";
import {HEAVY_MACHINE_POINT} from "../state_store/actionType";


const schema = yub.object().shape({
    name:yub.string().min(2).required(),

}).required()

export default function MachineEdition(props) {
    const {open, onClose, onOK, data} = props
    const {target} = data
    const {register, handleSubmit, control, formState: {errors}, clearErrors, setValue, reset} = useForm({
        resolver: yupResolver(schema)
    });
    const {state, dispatch} = useContext(Store)


   const onUpdateMachinePoint = (value)=>{

       Request(dispatch,  updateHeavyMachineryPointName(FormFormat(value)), onOK).then(error=>{
           if(!error){

               getHeavyMachineryPoints(target.data.site_id).then(res=>{
                   if(res.result === 0){
                       dispatch({type:HEAVY_MACHINE_POINT, payload:res.heavyMachineryPoints})
                   }
               })
               reset()
           }
       })
   }


    useEffect(()=>{
        console.log(target)
        clearErrors()
        reset()
        if(target)
            for(const key in target.data){
                setValue(key, target.data[key])
            }
    },[target])
    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >


            <div className={"dialog"}>
                <div className={"title"}>編集</div>
                <div className={"subtitle"}>編集する作業重機位置表示名を入力してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"machine_point-edition"} onSubmit={handleSubmit(onUpdateMachinePoint)}>


                        <div className={"input-title"}>作業重機位置表⽰名</div>
                        <input type="text"
                               className={`ant-input ${errors.name ? "error" : ""}`}
                               {...register("name")}/>
                        <div className={"helper-text-error"}>
                            {errors.name?.message}</div>


                        <div className={"input-title"}>重機</div>
                        <Controller
                            control={control}
                            name={"heavy_machinery_id"}
                            render={({field})=>(
                                <Select className={`select-field`}
                                        status={errors.heavy_machinery_id?"error":""}
                                        {...field}
                                >
                                    {state.heavy_machine.map(item => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        />
                        <div className={"helper-text-error"}>
                            {errors.heavy_machinery_id?.message}</div>


                        <div className={"input-title"}>使⽤業者</div>
                        <Controller
                            control={control}
                            name={"use_carrier_id"}
                            render={({field})=>(
                                <Select className={`select-field`}
                                        status={errors.use_carrier_id?"error":""}
                                        {...field}
                                >
                                    {state.group.map(item => (
                                        <Option value={item.id}>{item.company}</Option>
                                    ))}
                                </Select>
                            )}
                        />
                        <div className={"helper-text-error"}>
                            {errors.use_carrier_id?.message}</div>


                        <div className={"input-title"}>リース業者</div>
                        <Controller
                            control={control}
                            name={"lease_carrier_id"}
                            render={({field})=>(
                                <Select className={`select-field`}
                                        status={errors.lease_carrier_id?"error":""}
                                        {...field}

                                >
                                    {state.group.map(item => (
                                        <Option value={item.id}>{item.company}</Option>
                                    ))}
                                </Select>
                            )}
                        />
                        <div className={"helper-text-error"}>
                            {errors.lease_carrier_id?.message}</div>



                        <div className={"input-title"}>作業内容</div>
                        <Controller
                            control={control}
                            name={"work_content"}
                            render={({field})=>(
                                <Select className={`select-field`}
                                        status={errors.work_content?"error":""}
                                        {...field}
                                >
                                    {state.work_content.map(item => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        />
                        <div className={"helper-text-error"}>
                            {errors.work_content?.message}</div>



                    </form>

                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        htmlType={"submit"}
                        form={"machine_point-edition"}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>


            </div>
        </Modal>
    );
};
