import React, {useContext, useState} from 'react';
import {Modal, Button, Divider, Input, DatePicker, Select} from 'antd';
import {inputTruck} from "../api";
import {Store} from "../state_store/provider";

import {Option} from "antd/es/mentions";
import Checkbox from "antd/es/checkbox/Checkbox";
import {Request} from "../utils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yub from "yup";



export default function VehicleAddition(props) {
    const {open, onClose, onOK, driver, data} = props
    const [order_data, setOrder_data] = useState({on_the_truck: 0})
    const [error, setError] = useState({submit: false, message:"Vehicle Number is required"})
    const {state, dispatch} = useContext(Store)
    const {site, index} = state



    const onCreateOrder = ()=>{

        const data_submit = {
            ...order_data,
            site_id:site[index].id,
            order_id:data.id
        }
        if(!error.message)
        Request(dispatch, inputTruck(data_submit), onOK)
        // inputTruck(data_submit).then(res=>{
        //     if(res.result === 0){
        //         onOK()
        //     }
        // })
        setError({...error, submit: true})
    }


    const onChange = (e)=>{
        const {name, value} = e.target

        setOrder_data({...order_data, [name]:value})
        if(name === "vehicle_number"){
            if(value.length === 0 ){
                setError({...error, message: "Vehicle Number is required"})
            }else {
                setError({...error, message: ""})
            }
        }

    }
    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >


            <div className={"dialog"}>
                <div className={"title"}>配⾞情報を追加</div>
                <div className={"subtitle"}>追加する配⾞情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                        <div className={"input-title"}>⾞両番号</div>
                        <Input  type="text" value={order_data.vehicle_number}
                                className={error.message && error.submit?"error":""}
                                name={"vehicle_number"} onChange={onChange}/>

                        <div className={"helper-text-error"}>
                            {error.submit?error.message:""}</div>

                        <div className={"input-title"}>ドライバーのログインID</div>
                        <Select defaultValue={""} className={"select-field"}
                                onChange={(value)=>onChange({target:{name:"carrier_user_id", value}})}
                        >
                            {driver.map((item, index)=>(
                                <Option value={item.id} key={index}>{item.name}</Option>
                            ))}
                        </Select>

                        <Checkbox className={"checkbox"} name={"on_the_truck"}
                                  style={{marginTop:16, paddingLeft:0}}
                        onClick={(e)=>onChange({target:{name:"on_the_truck", value:e.target.checked?1:0}})}
                        >オンザトラック</Checkbox>

                        <div className={"input-title"}>搬⼊予定⽇</div>
                        <DatePicker
                            showTime
                            onChange={(date, dateString)=>onChange({target:{name:"time_plan",
                                    value:dateString}})}
                            placeholder={""}/>

                        <div className={"input-title"}>納⼊完了⽇</div>
                        <DatePicker
                            showTime
                            onChange={(date, dateString)=>onChange({target:{name:"time_complete",
                                    value:dateString}})}
                            placeholder={""}/>


                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={onCreateOrder}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>

            </div>
        </Modal>
    );
};
