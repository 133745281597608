import React, {useContext, useState, useEffect} from 'react'
import {ExclamationCircleOutlined, SearchOutlined} from "@ant-design/icons";
import position from '../../../asset/position.png'
import scale from "../../../asset/scale.png";
import {DatePicker, Divider, Input, Select} from "antd";
import copy from '../../../asset/copy.png'
import Checkbox from "antd/es/checkbox/Checkbox";
import Position from '../../../asset/position.png'
import Document from '../../../asset/document.png'
import Map from "../../../component/map_view";
import {Store} from "../../../state_store/provider";
import {
    getHeavyMachineryPoints,

    getSiteManagementHistories
} from "../../../api";
import edition from "../../../asset/edit.png";
import {HEAVY_MACHINE, HEAVY_MACHINE_POINT, MANAGEMENT_HISTORY, ORDER} from "../../../state_store/actionType";
import TableIconButton from "../../../component/table_icon_button";
import addition from "../../../asset/addition.png";
import deletion from "../../../asset/deletion.png";
import SiteManagementRegistration from "../../../component/site_management_registration";
import {FormFormat, getDocumentTypeLabel} from "../../../utils";
import HealthReportDelete from "../../../component/health_report_delete";


export default function SiteManagement() {
    const {state, dispatch} = useContext(Store)

    const [selected, setSelected] = useState({})
    const [materialInfo, setMaterialInfo] = useState([])
    const [openDocument, setOpenDocument] = useState(false)
    const [expand, setExpend] = useState(0)
    const [param, setParam] = useState({date:null, checkbox:[true, true, true]})
    const [filter, setFilter] = useState([])
    const {site, index} = state
    const [point, setPoint] = useState({})
    const [openDelete, setOpenDelete] = useState(false)

    useEffect(()=>{
        const id = site[index]?.id
        if(id){
            getHeavyMachineryPoints(id).then(res=>{
                if(res.result === 0){
                    dispatch({type:HEAVY_MACHINE_POINT, payload:res.heavyMachineryPoints})
                }
            })
        }

    },[site])

    const onMachinePointSelect = (value)=>{
        const submit_data =
            {keys:JSON.stringify({
            id:value.id,
            site_id: value.site_id
        })}
        setPoint(value)
        sessionStorage.setItem("point_id", value.id)
        getSiteManagementHistories(FormFormat(submit_data)).then(res=>{
            if(res.result === 0){
                dispatch({type:MANAGEMENT_HISTORY, payload: res.historyList})
                setFilter(res.historyList)
            }

        })
    }


    const onChangeCheckBox = (e, index)=>{
        const {checked} = e.target
        let checkbox = [...param.checkbox]
        checkbox[index] = checked
        setParam(prevState => ({...prevState, checkbox}))
        // setFilter({...filter, check: checked})
    }

    useEffect(()=>{
        const {date, checkbox} = param
        let result = state.management_history.filter(res=>{
            if(date){
                console.log(res.date === date.format("YYYY-MM-DD"))
                if(res.date === date.format("YYYY-MM-DD")){
                    return checkbox[res.type]
                }
                return false
            }else {
                return checkbox[res.type]
            }
        })
        setFilter(result)
        console.log(result)
    }, [param, state.management_history])


    return (
        <div className={"material column"}>

            {[0, 1].includes(expand)?<div className={"row md card-wrapper"}>
                <div className={`location column ${expand===1?"material-expand":""}`}>
                    <div className={"row card_header"}>
                        <div className={"row center"}>
                            <div className={"icon"}><img src={Position} width={16} alt={""}/></div>
                            <div className={"title"}>現場管理履歴</div>
                        </div>
                        <div className={"row center"}/>

                    </div>
                    <div className={"divider-wrapper"}>
                        <Divider className={"divider"}/>
                    </div>
                    <div className={"content"} id={"material_container"}>
                        <Map id={"management_map"}
                             onMachinePointClick={(e)=>onMachinePointSelect(e.target.data)}
                             key={expand} container_id={"material_container"}/>
                        <div className={"expand-btn-w"}
                             onClick={()=>setExpend(expand===0?1:0)}
                        >
                            <img src={scale} className={"expand-icon"} alt={""}/>
                        </div>
                    </div>

                </div>

            </div>:null}


            {
                ([0, 2].includes(expand)? <div className={`order_info`}>
                    <div className={"wrapper"}>
                        <div className={"header row"}>
                            <div className={"row center"}>
                                <div className={"icon"}><img src={Document} width={12} /></div>
                                <div className={"title"}>現場管理履歴</div>
                            </div>
                            <div className={"row center"}>
                                <TableIconButton icon={copy} title={"複製"}
                                    onClick={()=>{
                                        if(selected.worker_id){
                                            sessionStorage.setItem("mode", "copy")
                                            let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no,width=800,height=800';
                                            window.open('/health-management-report/index', 'mypopup', features);
                                        }
                                    }}
                                />
                                <TableIconButton icon={addition} title={"追加"}
                                onClick={()=>{
                                    if(point)
                                        setOpenDocument(true)
                                }}
                                />
                                <TableIconButton icon={edition}
                                                 onClick={()=>{
                                                     if(selected.worker_id){
                                                         sessionStorage.setItem("mode", "edit")
                                                         let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no,width=800,height=800';
                                                         window.open('/health-management-report/index', 'mypopup', features);
                                                     }
                                                 }}
                                                 title={"編集"}/>
                                <TableIconButton icon={deletion}
                                                 onClick={()=>{
                                                     if(selected.worker_id){
                                                         setOpenDelete(true)
                                                     }
                                                 }}
                                                 title={"削除"}/>
                            </div>
                        </div>

                        <Divider className={"divider"}/>
                    </div>
                    <div className={"row search"}>

                        <div className={"row filter-layout"}>
                            <DatePicker
                                onChange={(v)=>setParam(prevState => ({...prevState, date: v}))}
                                format="YYYY-MM-DD"
                                placeholder={"計測時刻"}
                            />
                            <Checkbox

                                className={"checkbox"}
                                    checked={param.checkbox[0]}
                                      onChange={(e)=>onChangeCheckBox(e, 0)}
                            >重機作業計画書</Checkbox>
                            <Checkbox className={"checkbox"}
                                      checked={param.checkbox[1]}
                                      onChange={(e)=>onChangeCheckBox(e, 1)}
                            >KY安全作業指⽰書</Checkbox>
                            <Checkbox className={"checkbox"}
                                      checked={param.checkbox[2]}
                                      onChange={(e)=>onChangeCheckBox(e, 2)}
                            >体調管理報告書</Checkbox>

                            <SearchOutlined />

                        </div>


                    </div>

                    <div className={`column table-wrapper ${expand===2?"expand-table":""}`}>


                            <div className={"row tb-h g-site-management"}>
                                <div>作成⽇</div>
                                <div>分類</div>
                                <div>使⽤機械・⼯具／⽒名</div>
                                <div>作業内容／タイミング</div>

                            </div>
                            {filter.map((res, index)=>(
                                <div className={`row tb-li pointer sbd g-site-management ${res===selected?"row-active":""}`} key={res}

                                     onClick={()=>{
                                         sessionStorage.setItem("selected", JSON.stringify(res))
                                         setSelected(res)
                                     }}
                                >
                                    <div>{res.date}</div>
                                    <div>{getDocumentTypeLabel(res.type)}</div>
                                    <div>{res.worker_name?res.worker_name:res.machinery_tool}</div>
                                    <div>{res.timing?res.timing.slice(0, -3): res.work_content}</div>
                                </div>
                            ))}

                        </div>



                    <div className={"expand-btn-w"}
                         onClick={()=>setExpend(expand===0?2:0)}
                    >
                        <img src={scale} className={"expand-icon"}/>
                    </div>
                </div>:null)
            }

            <SiteManagementRegistration
                open={openDocument}
                onClose={()=>setOpenDocument(false)}
            />
            <HealthReportDelete
                open={openDelete}
                onClose={()=>setOpenDelete(false)}
                onOK={()=>{
                    setOpenDelete(false)
                    setSelected({})
                    sessionStorage.setItem("selected", null)
                }}
                data={selected}
            />
        </div>
    )
}




