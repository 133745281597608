import React, {useContext} from 'react';
import {Modal, Button} from 'antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {removeMapFile} from "../api";
import {Store} from "../state_store/provider";
import {Request} from "../utils";

export default function MapDelete(props) {
    const {open, onClose, onOK} = props
    const {state, dispatch} = useContext(Store)
    const onDelete = ()=>{
        const id = state.site[state.index].id
        Request(dispatch, removeMapFile(id), onOK)
        // removeMapFile(id).then(res=>{
        //     if (res.result === 0){
        //         onOK()
        //     }
        // })
    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>

                <div className={"delete-dialog-body column center"}>
                    <div className={"icon"}>
                        <ExclamationCircleOutlined/>
                    </div>
                    <div className={"title"}>削除</div>
                    <div className={"subtitle"}>
                        現場図⾯を削除します。よろしいですか？
                    </div>

                </div>
                <div className={"delete-dialog-footer row center"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={onDelete}
                        type="primary" style={{backgroundColor:"#377EF3"}}>削除する</Button>
                </div>
            </div>
        </Modal>
    );
};
