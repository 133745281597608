import React from "react";


export default function TableIconButton({icon, title, onClick, selected}){

    return (

        <div className={`column center user-list-icon-btn ${selected ? "icon-btn-active" : ""}`}
             onClick={onClick}
        >
            <img src={icon} alt={"no image"}/>
            <div className={"btn-title"}>{title}</div>
        </div>

    )
}
