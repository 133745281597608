import React, {useContext, useEffect} from 'react'
import {Tabs} from "antd";
import Company from "./company";
import UserList from "./user_list";
import SystemSetting from "./system_setting";
import WorkerList from "./worker_list";
import Heavy from "./heavy";
import WorkContent from "./work_content";
import Qualification from "./qualification";
import Management from "./management";
import {Store} from "../../state_store/provider";
import {getAuthUser} from "../../api";
import {USER} from "../../state_store/actionType";
const { TabPane } = Tabs;

function callback(key) {
    console.log(key);
}

export default function Maintenance(){
    const {state, dispatch} = useContext(Store)

    useEffect(()=>{
        if(!state.user.id)
        getAuthUser().then(res=>{

            if(res){
                localStorage.setItem("user", res.id)
                dispatch({type:USER, payload:res})

            }

        })
    },[])
    return(
        <div className={"maintenance"}>
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="グループ登録" key="1">
                    <Company/>
                </TabPane>
                <TabPane tab="ユーザー登録" key="2">
                    <UserList/>
                </TabPane>
                <TabPane tab="システム設定" key="3">
                    <SystemSetting/>
                </TabPane>
                <TabPane tab="作業者登録" key="4">
                    <WorkerList/>
                </TabPane>
                <TabPane tab="重機登録" key="5">
                    <Heavy/>
                </TabPane>
                <TabPane tab="作業内容登録" key="6">
                    <WorkContent/>
                </TabPane>
                <TabPane tab="資格登録" key="7">
                    <Qualification/>
                </TabPane>
                <TabPane tab="体調管理項⽬登録" key="8">
                    <Management/>
                </TabPane>

            </Tabs>
        </div>

)
}