import React, {useContext, useEffect, useState} from 'react'
import addition from '../../asset/addition.png'
import edition from '../../asset/edit.png'
import deletion from '../../asset/deletion.png'
import {Divider} from "antd";
import {getGroup} from "../../api";
import {Store} from "../../state_store/provider";
import {GROUP} from "../../state_store/actionType";
import UserIcon from '../../asset/user.png'
import CompanyRegistration from "../../component/company_registration";
import CompanyEdition from "../../component/company_edition";
import CompanyDelete from "../../component/company_delete";
import TableIconButton from '../../component/table_icon_button'


export default function Company(){
    const {state, dispatch} = useContext(Store)
    const [openAddition, setOpenAddition] = useState(false)
    const [openEdition, setOpenEdition] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [selected, setSelected] = useState({})

    useEffect(()=>{
        getGroup().then(res=>{

            if(res.result === 0)
                dispatch({type:GROUP, payload:res.groupList})
        })
        //addGroup({id:1, company:"jthink", email:"varathana12@gmail.com"}).then(res=>console.log(res))
    },[])



    return(
        <div className={"company"}>
            <div className={"wrapper"}>
                <div className={"header row"}>
                    <div className={"row center"}>
                        <div className={"icon"}><img src={UserIcon} width={16}/></div>
                        <div className={"title"}>グループ登録</div>
                    </div>
                    <div className={"row center"}>
                        <TableIconButton icon={addition} title={"追加"}
                                         selected={openAddition}
                                         onClick={()=>setOpenAddition(true)}/>
                        <TableIconButton icon={edition} title={"編集"}
                                         selected={openEdition}
                        onClick={()=>setOpenEdition(!!selected.id)}
                        />
                        <TableIconButton icon={deletion} title={"削除"}
                                         selected={openDelete}
                                         onClick={()=>setOpenDelete(!!selected.id)}
                        />
                    </div>
                </div>

                    <Divider className={"divider"}/>
            </div>
                <div className={"column table-wrapper"}>
                    <div className={"row tb-h g-group"}>
                        <div>グループ名</div>
                        <div>メールアドレス</div>
                    </div>
                    {state.group.map(res=>(
                        <div className={`row tb-li sbd g-group pointer ${res.id===selected.id ?"row-active":""}`}
                             onClick={()=>setSelected(res)}>
                            <div>{res.company}</div>
                            <div>{res.mail}</div>
                        </div>


                    ))}
                </div>
            <CompanyRegistration open={openAddition}
                                 onOK={()=>setOpenAddition(false)}
                                 onClose={()=>setOpenAddition(false)}/>
            <CompanyEdition open={openEdition}
                                data={selected}
                                 onOK={()=>setOpenEdition(false)}
                                 onClose={()=>setOpenEdition(false)}/>
            <CompanyDelete open={openDelete}
                           data={selected}
                           onOK={()=>setOpenDelete(false)}
                           onClose={()=>setOpenDelete(false)}
            />
        </div>
    )
}


