import React, {useContext} from 'react';
import {Modal, Button} from 'antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {deleteTruckLoading} from "../api";
import {Request} from "../utils";
import {Store} from "../state_store/provider";


export default function TruckDelete(props) {
    const {open, onClose, onOK, data} = props
    const {dispatch} = useContext(Store)
    const onDelete = ()=>{
        const {truck_id, order} = data
        Request(dispatch, deleteTruckLoading(truck_id, order), onOK)
        // deleteTruckLoading(truck_id, order).then(res=>{
        //     if (res.result === 0) {
        //         onOK()
        //     }
        // })
    }


    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>


                <div className={"delete-dialog-body column center"}>
                    <div className={"icon"}>
                        <ExclamationCircleOutlined/>
                    </div>
                    <div className={"title"}>積載品⽬情報を削除</div>
                    <div className={"subtitle"}>
                        この積載品⽬情報に関連する全ての情報が失われます。
                    </div>
                    <div className={"subtitle"}>
                        削除してよろしいですか？
                    </div>

                </div>
                <div className={"delete-dialog-footer row center"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={onDelete}
                        type="primary" style={{backgroundColor:"#377EF3"}}>削除する</Button>
                </div>
            </div>
        </Modal>
    );
};
