import React, {useContext, useState} from 'react';
import {Modal, Button, Divider, Input, Select} from 'antd';
import {addTruckLoading} from "../api";
import {Store} from "../state_store/provider";

import {Option} from "antd/es/mentions";
import {Request} from "../utils";

export default function TruckAddition(props) {
    const {open, onClose, onOK, data, order_selected} = props
    const [order_data, setOrder_data] = useState({amaunt:0})
    const [error, setError] = useState({submit: false, message:"Item is required"})
    const {state, dispatch} = useContext(Store)
    const {order, point} = state
    const onCreateOrder = ()=>{

        const data_submit = {
            ...order_data,
            // order_id:order_selected.id,
            truck_id:data.id
        }

        if(!error.message)
        Request(dispatch, addTruckLoading(data_submit), onOK).then(error=>{
            if(!error){
                setOrder_data({amaunt:0})
            }
        })
        // addTruckLoading(data_submit).then(res=>{
        //     if(res.result === 0){
        //         onOK()
        //     }
        // })
        setError({...error, submit: true})
    }

    const onChange = (e)=>{
        const {name, value} = e.target
        console.log(value)
        setOrder_data({...order_data, [name]:value})

        if(name === "order_id"){
            if(value.length === 0 ){
                setError({...error, message: "Item is required"})
            }else {
                setError({...error, message: ""})
            }
        }
    }

    const onCloseDialog = () => {
      setOrder_data({amaunt: 0})
        onClose()
    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >


            <div className={"dialog"}>
                <div className={"title"}>配⾞情報を追加</div>
                <div className={"subtitle"}>追加する配⾞情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>


                    <div className={"input-title"}>品⽬</div>
                    <Select className={"select-field " + (error.message && error.submit?"error":"")}
                            value={order_data.order_id}
                            onChange={(value)=>onChange({target:{name:"order_id", value}})}
                    >
                        {order.map((item, index)=>(
                            <Option value={item.id} key={index}>{item.name}</Option>
                        ))}
                    </Select>
                    <div className={"helper-text-error"}>
                        {error.submit?error.message:""}</div>

                    <div className={"input-title"}>事業者希望数量</div>
                    <Input  type="text" defaultValue={order_selected.amaunt?order_selected.amaunt:0} disabled/>

                    <div className={"input-title"}>分納済数</div>
                    <Input  type="text" value={0} name={"vehicle_number"} disabled/>

                    <div className={"input-title"}>数量</div>
                    <Input  type="text" defaultValue={0} name={"amaunt"} onChange={onChange}/>

                    <div className={"input-title"}>残数</div>
                    <Input  type="text" value={order_selected.amaunt - order_data.amaunt} disabled/>


                    <div className={"input-title"}>位置</div>
                    <Select defaultValue={""} className={"select-field"}
                            onChange={(value)=>onChange({target:{name:"pid", value}})}
                    >

                        {point.map((item, index)=>(
                            <Option value={item.id} key={index}>{item.name}</Option>
                        ))}
                    </Select>



                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onCloseDialog}
                    >キャンセル</Button>
                    <Button
                        onClick={onCreateOrder}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>


            </div>
        </Modal>
    );
};
