export const SITE = "SITE"
export const GROUP = "GROUP"
export const USERS = "USERS"
export const INDEX = "INDEX"
export const USER = "USER"
export const ORDER = "ORDER"
export const WORKER = "WORKER"
export const WORK_CONTENT = "WORK_CONTENT"
export const HEAVY_MACHINE = "HEAVY_MACHINE"
export const LICENSE = "LICENSE"
export const HEALTH_MANAGEMENT = "HEALTH_MANAGEMENT"
export const HEAVY_MACHINE_POINT = "HEAVY_MACHINE_POINT"
export const MANAGEMENT_HISTORY = "MANAGEMENT_HISTORY"
export const SELECTED_COMPANY = "SELECTED_COMPANY"
export const POINT = "POINT"
export const PLACEMENT_POINT = "PLACEMENT_POINT"
export const TAB = "TAB"
export const ROUTE_NAME = "ROUTE_NAME"
export const LOADING = "LOADING"
export const ERROR = "ERROR"
export const SITE_TAB = "SITE_TAB"