import {ERROR, LOADING} from "../state_store/actionType";

export const Error = async (dispatch, res, onOK)=>{
   Loading(dispatch, false, onOK)
    if(res.result !== 0){
        dispatch({type:ERROR, payload: {open:true, message:res.msg}})
        return true
    }
    return false
}


export const Loading = (dispatch, status, onOK=null)=>{
    if(onOK)
        onOK()
    dispatch({type:LOADING, payload: status})
}

export const Request = (dispatch, request, onOK)=>{
    Loading(dispatch, true, onOK)
    return request.then(res=>{
        return Error(dispatch, res, onOK)
    })
}

export const FormFormat = (obj)=>{
    let form_data = new FormData();
    for ( let key in obj ) {
        form_data.append(key, obj[key]);
    }
    return  form_data

}

export const getConstructionMachineNameLabel = (construction_machine_name)=>{
    switch (construction_machine_name) {
        case '0':
            return 'バケット';
        case '1':
            return '排土板';
        case '2':
            return 'その他(ブレーカー)';
        default:
            return '';
    }
};

export const getWeightLabel = (weight)=>{
    switch (weight) {
        case '0':
            return '3t以上';
        case '1':
            return '3t未満';
        default:
            return '';
    }
}

export const getDayOfWeekLabel = (dayOfWeek)=>{
    let dayOfWeekLabel = '';

    // 日曜
    if ((dayOfWeek & 1) > 0) {
        dayOfWeekLabel += '日/';
    }

    // 月
    if ((dayOfWeek & 2) > 0) {
        dayOfWeekLabel += '月/'
    }

    // 火
    if ((dayOfWeek & 4) > 0) {
        dayOfWeekLabel += '火/'
    }

    // 水
    if ((dayOfWeek & 8) > 0) {
        dayOfWeekLabel += '水/'
    }

    // 木
    if ((dayOfWeek & 16) > 0) {
        dayOfWeekLabel += '木/'
    }

    // 金
    if ((dayOfWeek & 32) > 0) {
        dayOfWeekLabel += '金/'
    }

    // 土
    if ((dayOfWeek & 64) > 0) {
        dayOfWeekLabel += '土/'
    }

    return dayOfWeekLabel.slice(0, -1);
}

export const getAnswerLabel = (answer)=>{
    switch (answer) {
        case 0:
            return 'はい/いいえ';
        case 1:
            return 'フリー入力';
        default:
            return '';
    }
}

export const getDocumentTypeLabel = (documentType)=>{
    switch (documentType) {
        case 0:
            return '作業計画書';
        case 1:
            return 'KY安全作業指示書';
        case 2:
            return '体調管理報告書';
    }
}



export const changeFrequencyOrImportance = (f, i)=>{

    if (f && i) {

        let evaluation = Number(f) * Number(i)

            switch (evaluation) {
                case 1:
                    return [evaluation, 'Ⅰ']
                case 3:
                    return [evaluation, 'Ⅱ']
                case 5:
                case 9:
                    return [evaluation, 'Ⅲ']

                case 15:
                    return [evaluation, 'Ⅳ']
                case 25:
                    return [evaluation, 'Ⅴ']
                default:
                    return null

            }
    }
    return null
}

export const convertRomanLabel = (value)=>{

    switch (value) {
        case 'Ⅰ':
            return 1
        case 'Ⅱ':
            return 2
        case 'Ⅲ':
            return 3
        case 'Ⅳ':
            return 4
        case 'Ⅴ':
            return 5
        default:
            return null

    }
}

export const printPDF = (option)=>{
    window.print()

}