import React, {useContext, useState, useEffect} from 'react';
import {Modal, Button, Divider, Input, DatePicker, Select} from 'antd';
import {inputTruck} from "../api";
import {Store} from "../state_store/provider";

import {Option} from "antd/es/mentions";
import Checkbox from "antd/es/checkbox/Checkbox";
import moment from "moment";
import {Request} from "../utils";
import {EDIT} from "../constant/variables";

export default function VehicleEdition(props) {
    const {open, onClose, onOK, driver, data, vehicle} = props
    const [truck_data, setTruck_data] = useState({})
    const [error, setError] = useState({submit: false, message:"Vehicle Number is required"})
    const {dispatch} = useContext(Store)

    const onCreateOrder = ()=>{

        const data_submit = {
            ...truck_data,
            time_plan:truck_data.time_plan?.format("YYYY-MM-DD HH:mm:ss"),
            time_complete:truck_data.time_complete?.format("YYYY-MM-DD HH:mm:ss")
        }
        if(!error.message)
        Request(dispatch, inputTruck(data_submit), onOK)
        // inputTruck(data_submit).then(res=>{
        //     if(res.result === 0){
        //         onOK()
        //     }
        // })
        setError({...error, submit: true})

    }

    useEffect(()=>{
        const {time_plan, time_complete, on_the_truck, vehicle_number} = vehicle
        const [tp, tc] = [time_plan?moment(time_plan):null, time_complete?moment(time_complete):null]

        setTruck_data({...vehicle, time_plan:tp, time_complete:tc, on_the_truck:on_the_truck===1})

        setError({...error, message: vehicle_number?"":"Vehicle Number is required"})

    }, [vehicle])



    const onChange = (e)=>{
        const {name, value} = e.target
        setTruck_data({...truck_data, [name]:value})

        if(name === "vehicle_number"){
            if(value.length === 0 ){
                setError({...error, message: "Vehicle Number is required"})
            }else {
                setError({...error, message: ""})
            }
        }

    }
    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >


            <div className={"dialog"}>
                <div className={"title"}>配⾞情報を追加</div>
                <div className={"subtitle"}>追加する配⾞情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <div className={"input-title"}>⾞両番号</div>
                    <Input  type="text" value={truck_data.vehicle_number}
                            className={error.message && error.submit?"error":""}
                            name={"vehicle_number"} onChange={onChange}/>
                    <div className={"helper-text-error"}>
                        {error.submit?error.message:""}</div>
                    <div className={"input-title"}>ドライバーのログインID</div>
                    <Select className={"select-field"}
                            value={truck_data.carrier_user_id}
                            onChange={(value)=>onChange({target:{name:"carrier_user_id", value}})}
                    >
                        {driver.map((item, index)=>(
                            <Option value={item.id} key={index}>{item.name}</Option>
                        ))}
                    </Select>


                    <Checkbox className={"checkbox"} name={"on_the_truck"}
                              checked={truck_data.on_the_truck}
                              style={{marginTop:16, paddingLeft:0}}
                              onChange={(e)=>onChange({target:{name:"on_the_truck", value:e.target.checked}})}
                    >オンザトラック</Checkbox>


                    <div className={"input-title"}>搬⼊予定⽇</div>
                    <DatePicker
                        showTime

                        value={truck_data.time_plan}
                        onChange={(date, dateString)=>onChange({target:{name:"time_plan",
                                value:date}})}
                        placeholder={""}/>

                    <div className={"input-title"}>納⼊完了⽇</div>
                    <DatePicker
                        showTime

                        value={truck_data.time_complete}
                        onChange={(date, dateString)=>onChange({target:{name:"time_complete",
                                value:date}})}
                        placeholder={""}/>
                </div>

                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={onCreateOrder}
                        type="primary" style={{backgroundColor:"#377EF3"}}>{EDIT}</Button>
                </div>

            </div>
        </Modal>
    );
};
