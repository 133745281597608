import { useMap} from 'react-leaflet'

import React, {useContext, useEffect, useState} from 'react'
import {Store} from "../state_store/provider";


const L = window.L
let map = {}
let kml_layer = []
let layer = undefined
let machine_point = []
let routePointIcon = undefined
export default function MapView({id, onMachinePointClick, heavy_machinery_point}) {
    const {state} = useContext(Store)
    const {kml, center, error} = state.placement_point
    useEffect(()=>{

        map = L.map(id, {
            center: [0, 0],
            zoom: 15
        });
        L.tileLayer('https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',  {maxZoom:24,maxNativeZoom:18,attribution:'<a href="https://maps.gsi.go.jp/development/ichiran.html" target="_blank">地理院タイル</a>'}).addTo(map);
    },[])

    const removeKMLLayer = ()=>{
        if(map)
            kml_layer.forEach(res=>{
                map.removeLayer(res)
                kml_layer = []
            })
    }

    const removeMachinePoint = ()=>{
        machine_point.forEach(marker=>{
            map.removeLayer(marker)

        })
        machine_point = []
    }

    const addMachinePoint = ()=>{
        const {site, index, heavy_machine_point} = state
        const id = site[index]?.id
        heavy_machine_point.forEach(item=> {
            routePointIcon = L.icon({
                iconUrl: '/images/' + item.image,
                iconRetinaUrl: '/images/' + item.image,
                iconSize: [40, 40],
                iconAnchor: [20, 40],
                popupAnchor: [0, -40],
            });

            let road = L.marker([item.lat, item.lon], {
                icon: routePointIcon,
                draggable: false,
                title: '画像をアップロードする場合はこちらへ画像ファイルをドラッグ＆ドロップしてください。' +
                    '\n（サポートしている画像ファイルは png/jpeg/jpg です。）',
                alt: item.name,
                riseOnHover: true
            }).bindTooltip(item.name, {
                permanent: true,
                direction: 'top',
                offset: L.point(0, -4)
            }).addTo(map);
            road.routePointId = item.id;
            road.siteId = id;
            road.focus = false;
            road.name = item.name
            road.image_src = '/images/' + item.image
            road.data = item

            road.on('click', function (ev) {
                onMachinePointClick(ev)
            })


            machine_point.push(road)
        })


    }


    useEffect(()=>{
        removeKMLLayer()
        if(!error && kml && window.omnivore) {
            let track = new window.omnivore.kml.parse(kml);
            map.addLayer(track);
            const bounds = track.getBounds();
            map.fitBounds(bounds);
            kml_layer.push(track)
        }
    }, [center, state.index])

    useEffect(()=>{
        if(state.tab === "3"){
            addMachinePoint()
        }else {
            removeMachinePoint()
        }
    }, [state.tab, state.heavy_machine_point])

    useEffect(()=>{
        if(heavy_machinery_point)
            renderWorkPlanPoint(heavy_machinery_point)
    },[heavy_machinery_point])

    const renderWorkPlanPoint = (item)=>{
        if(id==="work_plan_map"){
            const icon = L.icon({
                iconUrl: '/images/' + item.image,
                iconRetinaUrl: '/images/' + item.image,
                iconSize: [40, 40],
                iconAnchor: [20, 40],
                popupAnchor: [0, -40],
            });

            L.marker([item.lat, item.lon], {
                icon: icon,
                draggable: false,
                title: '画像をアップロードする場合はこちらへ画像ファイルをドラッグ＆ドロップしてください。' +
                    '\n（サポートしている画像ファイルは png/jpeg/jpg です。）',
                alt: item.name,
                riseOnHover: true
            }).bindTooltip(item.name, {
                permanent: true,
                direction: 'top',
                offset: L.point(0, -4)
            }).addTo(map);

        }
    }

    return (

        <div id={id} style={{height:"100%"}}/>

    )
}
