import React from 'react'





export function AppbarMenuV(props){
    return <div className={"appbar-btn-v"}
                onClick={props.onClick}
                style={props.style}>
            <img src={props.icon}/>
            <p className={props.className}>{props.title}</p>
    </div>
}

export function AppbarMenuH(props){
    return <div className={"appbar-btn-h"}
                onClick={props.onClick}
                style={props.style}>
        <img src={props.icon}/>
        <p>{props.title}</p>
    </div>
}
