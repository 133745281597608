import React, {useContext, useState} from 'react';
import {Modal, Button, Divider, Select, DatePicker, Checkbox, Radio} from 'antd';
import {addHealthManagement, addUser, getHealthManagement, getUsers} from "../api";
import {Store} from "../state_store/provider";
import {HEALTH_MANAGEMENT, USERS} from "../state_store/actionType";
import {Option} from "antd/es/mentions";
import {Role} from "../constant";
import * as yub from "yup";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {FormFormat, Request} from "../utils";
import moment from "moment";


const hours = ()=>{
    let a = []
    for(let i=0; i<24; i++)
        if(i<10)
            a.push(`0${i}`)
        else
            a.push(`${i}`)
    return a
}

const QA = (obj)=>{
    let question = {}
    let answer = {}
    for(let i=0; i<8; i++){
        question[`question${i+1}`] = obj[`question${i+1}`]?obj[`question${i+1}`]:""
        answer[`answer${i+1}`] = obj[`answer${i+1}`]?obj[`answer${i+1}`]:0
    }
    return {question, answer}
}

// if (!id || !timing || !notice_time_hour || !notice_time_minute || !from_date || !to_date || !day_of_week || day_of_week <= 0) {
//     Swal.showValidationMessage('現場ID、タイミング、通知時間、通知開始日付、通知終了日付、通知曜日項目は必須です。');
//     return false;
// }


const schema = yub.object().shape({
    id: yub.string().required(),
    timing: yub.string().required(),
    hour: yub.string().required(),
    minute: yub.string().required(),
    from_date: yub.string().required(),
    to_date: yub.string().required(),
    day_of_week: yub.array().min(1).required(),

    // email: yub.string().email().required(),
    // admin_flg: yub.string().required("Role is a required field"),
    // carrier_id: yub.string().required("Company is a required field")
}).required()
export default function ManagementRegistration(props) {
    const {open, onClose, onOK} = props
    const [workerData, setWorkerData] = useState({})
    const {state, dispatch} = useContext(Store)
    const {register, handleSubmit, formState: {errors}, reset, control} = useForm({
        resolver: yupResolver(schema)
    });
    console.log(errors)

    const onCreateManagement = (value) => {
        const {question, answer} = QA(value)
        const data_submit = {
            ...value,
            site: state.site.find(res=>res.id === value.id)?.name,
            notice_time: value.hour + ":" + value.minute,
            from_date: moment(value.from_date).format("YYYY-MM-DD"),
            to_date: moment(value.to_date).format("YYYY-MM-DD"),
            day_of_week: value.day_of_week.reduce((a, b) => a + b, 0),
            timing:value.timing + ":00:00",
            ...question,
            ...answer
        }

        Request(dispatch, addHealthManagement(FormFormat(data_submit)), onOK).then(error => {
            if (!error) {
                getHealthManagement().then(res=> {
                    if(res.result === 0)
                        dispatch({type: HEALTH_MANAGEMENT, payload: res.healthManagementList})
                })
                reset()
            }
        })

    }



    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>体調管理項⽬を追加</div>
                <div className={"subtitle"}>追加する体調管理項⽬を⼊⼒してください。</div>
                <Divider className={"divider"}/>


                <div className={"add-dialog-body"}>
                    <form id={"management-registration"} onSubmit={handleSubmit(onCreateManagement)}>

                        <div className={"input-title"}>現場名</div>
                        <Controller
                            control={control}
                            name={"id"}
                            render={({field}) => (
                                <Select className={`select-field`}
                                        status={errors.id ? "error" : ""}

                                        {...field}
                                    // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                >
                                    {state.site.map(item => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        />
                        <div className={"helper-text-error"}>{errors.id?.message}</div>


                        <div className={"input-title"}>質問タイミング</div>
                        <div className={"row small-select-layout"}>
                            <Controller
                                control={control}
                                name={"timing"}
                                render={({field}) => (
                                    <Select className={`select-field question-time`}
                                            status={errors.timing ? "error" : ""}

                                            {...field}
                                        // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                    >
                                        {hours().map(item => (
                                            <Option value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                )}
                            />
                            <div className={"label"}>時</div>
                        </div>

                        <div className={"helper-text-error"}>{errors.timing?.message}</div>

                        <div className={"input-title"}>通知時間</div>

                        <div className={"row small-select-layout"}>
                            <Controller
                                control={control}
                                name={"hour"}
                                render={({field}) => (
                                    <Select className={`select-field notification-time`}
                                            status={errors.hour ? "error" : ""}

                                            {...field}
                                        // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                    >
                                        {hours().map(item => (
                                            <Option value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                )}/>

                            <div className={"label"}>時</div>

                            <Controller
                                control={control}
                                name={"minute"}
                                render={({field}) => (
                                    <Select className={`select-field notification-time`}
                                            status={errors.minute ? "error" : ""}

                                            {...field}
                                        // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                    >
                                        {["00","15", "30", "45"].map(item => (
                                            <Option value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                )}
                            />
                            <div className={"label"}>分</div>


                        </div>

                        <div className={"input-title"}>通知開始⽇付</div>

                        <Controller
                            control={control}
                            render={({field}) =>
                                <DatePicker
                                    {...field}
                                    placeholder={""}/>
                            }
                            name={"from_date"}
                        />
                        <div className={"helper-text-error"}>{errors.from_date?.message}</div>


                        <div className={"input-title"}>通知終了⽇付</div>
                        <Controller
                            control={control}
                            render={({field}) =>
                                <DatePicker
                                    {...field}
                                    placeholder={""}/>
                            }
                            name={"to_date"}
                        />
                        <div className={"helper-text-error"}>{errors.to_date?.message}</div>

                        <div className={"input-title"}>通知曜⽇</div>
                        <div className={"row notification-day"}>
                            <Controller
                                control={control}
                                render={({field}) =>
                                <Checkbox.Group {...field}>
                                    <div className={"column"}>
                                        <div className={"input-title"}>⽇</div>
                                        <div><Checkbox value={1}/></div>
                                    </div>
                                    <div className={"column"}>
                                        <div className={"input-title"}>⽉</div>
                                        <div><Checkbox value={2}/></div>
                                    </div>
                                    <div className={"column"}>
                                        <div className={"input-title"}>⽕</div>
                                        <div><Checkbox value={4}/></div>
                                    </div>
                                    <div className={"column"}>
                                        <div className={"input-title"}>⽔</div>
                                        <div><Checkbox value={8}/></div>
                                    </div>
                                    <div className={"column"}>
                                        <div className={"input-title"}>⽊</div>
                                        <div><Checkbox value={16}/></div>
                                    </div>
                                    <div className={"column"}>
                                        <div className={"input-title"}>⾦</div>
                                        <div><Checkbox value={32}/></div>
                                    </div>
                                    <div className={"column"}>
                                        <div className={"input-title"}>⼟</div>
                                        <div><Checkbox value={64}/></div>
                                    </div>
                                </Checkbox.Group>
                            }
                                name={"day_of_week"}/>

                        </div>
                        <div className={"helper-text-error"}>{errors.day_of_week?.message}</div>


                        <div className={"input-title"}>質問１</div>
                        <input type="text"
                               className={`ant-input ${errors.question1 ? "error" : ""}`}
                               {...register("question1")}/>
                        <div className={"helper-text-error"}>
                            {errors.question1?.message}</div>

                        <div className={"input-title"}>質問１回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                <Radio.Group {...field}>
                                    <div className={"row radio-item-layout"}>

                                        <div><Radio value={0}/></div>
                                        <div className={"radio-title"}>はい／いいえ</div>
                                    </div>
                                    <div className={"row radio-item-layout"}>

                                        <div><Radio value={1}/></div>
                                        <div className={"radio-title"}>フリー⼊⼒</div>
                                    </div>
                                </Radio.Group>
                            }
                                        name={"answer1"}
                            />

                        </div>

                        <div className={"input-title"}>質問２</div>
                        <input type="text"
                               className={`ant-input ${errors.question2 ? "error" : ""}`}
                               {...register("question2")}/>
                        <div className={"helper-text-error"}>
                            {errors.question2?.message}</div>


                        <div className={"input-title"}>質問２回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer2"}
                            />
                        </div>


                        <div className={"input-title"}>質問３</div>
                        <input type="text"
                               className={`ant-input ${errors.question3 ? "error" : ""}`}
                               {...register("question3")}/>
                        <div className={"helper-text-error"}>
                            {errors.question3?.message}</div>


                        <div className={"input-title"}>質問３回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer3"}
                            />
                        </div>


                        <div className={"input-title"}>質問４</div>
                        <input type="text"
                               className={`ant-input ${errors.question4 ? "error" : ""}`}
                               {...register("question4")}/>
                        <div className={"helper-text-error"}>
                            {errors.question4?.message}</div>


                        <div className={"input-title"}>質問４回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer4"}
                            />
                        </div>


                        <div className={"input-title"}>質問５</div>
                        <input type="text"
                               className={`ant-input ${errors.question5 ? "error" : ""}`}
                               {...register("question5")}/>
                        <div className={"helper-text-error"}>
                            {errors.question5?.message}</div>


                        <div className={"input-title"}>質問５回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer5"}
                            />
                        </div>

                        <div className={"input-title"}>質問６</div>
                        <input type="text"
                               className={`ant-input ${errors.question6 ? "error" : ""}`}
                               {...register("question6")}/>
                        <div className={"helper-text-error"}>
                            {errors.question6?.message}</div>


                        <div className={"input-title"}>質問６回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer6"}
                            />
                        </div>


                        <div className={"input-title"}>質問７</div>
                        <input type="text"
                               className={`ant-input ${errors.question7 ? "error" : ""}`}
                               {...register("question7")}/>
                        <div className={"helper-text-error"}>
                            {errors.question7?.message}</div>


                        <div className={"input-title"}>質問７回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer7"}
                            />
                        </div>


                        <div className={"input-title"}>質問８</div>
                        <input type="text"
                               className={`ant-input ${errors.question8 ? "error" : ""}`}
                               {...register("question8")}/>
                        <div className={"helper-text-error"}>
                            {errors.question8?.message}</div>


                        <div className={"input-title"}>質問８回答形式</div>
                        <div className={"row radio-layout"}>
                            <Controller
                                control={control}
                                render={({field})=>
                                    <Radio.Group {...field}>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={0}/></div>
                                            <div className={"radio-title"}>はい／いいえ</div>
                                        </div>
                                        <div className={"row radio-item-layout"}>

                                            <div><Radio value={1}/></div>
                                            <div className={"radio-title"}>フリー⼊⼒</div>
                                        </div>
                                    </Radio.Group>
                                }
                                name={"answer8"}
                            />
                        </div>

                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor: "#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        // onClick={onCreateUser}
                        htmlType={"submit"}
                        form={"management-registration"}
                        type="primary" style={{backgroundColor: "#377EF3"}}>追加する</Button>
                </div>
            </div>
        </Modal>

    )
};
