import React, {useContext, useState} from 'react';
import {Modal, Button, Divider, Select, AutoComplete, Input} from 'antd';
import {addPlanPoint, getPlanPointList, getSite} from "../api";
import {Store} from "../state_store/provider";
import {POINT} from "../state_store/actionType";
import {Option} from "antd/es/mentions";
import {Request} from "../utils";

export default function PointAddition(props) {

    const {open, onClose, data:{lat, lng}} = props
    const [point, setPoint] = useState({name:""})
    const [error, setError] = useState({submit: false, message:"Name is required"})
    const {state, dispatch} = useContext(Store)

    const onCreateSite = ()=>{
        const id = state.site[state.index].id
        if(!error.message)
        Request(dispatch, addPlanPoint({siteId:id, lat, lng, name:point.name}), onClose).then(err=>{
            if(!err){
                getPlanPointList(id).then(res=>{
                    dispatch({type: POINT, payload:res.plan_points})
                    // onClose()
                    setPoint({name:""})
                })
            }

        })
        setError({...error, submit: true})
        // addPlanPoint({siteId:id, lat, lng, name:point.name})
        //     .then(res=>{
        //         if(res.result === 0){
        //
        //             getPlanPointList(id).then(res=>{
        //                 dispatch({type: POINT, payload:res.plan_points})
        //                 onClose()
        //             })
        //         }
        //     })
    }
    const onChange = (value)=>{
        setPoint({name:value})
        setError({...error, message: value?"":"Name is required"})

    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>資材希望位置表⽰名追加</div>
                <div className={"subtitle"}>追加する資材希望位置表⽰名を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>

                    <AutoComplete style={{width:"100%"}}
                                  value={point.name}
                                  onChange={onChange}
                                    options={state.point?.map((res, index)=>({value:res.name, key:index}))}
                                  filterOption={(inputValue, option) =>{
                                      return option.value.toLowerCase().includes(inputValue.toLowerCase())
                                  }
                                  }
                    >

                        <input type="text"

                               onChange={(e)=>onChange(e.target.value)}
                               className={`ant-input`}/>
                    </AutoComplete>
                    {/*<Select className={"select-field"}*/}
                    {/*        value={point.name}*/}
                    {/*        showSearch*/}
                    {/*        status={(error.message && error.submit?"error":"")}*/}
                    {/*        showArrow={false}*/}
                    {/*        onChange={(value)=>{*/}
                    {/*            if (value){*/}
                    {/*                onChange(value)*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*        onSearch={(e)=>onChange(e)}*/}
                    {/*>*/}
                    {/*    {state.point.map((item, index)=>(*/}
                    {/*        <Option value={item.name}>{item.name}</Option>*/}
                    {/*    ))}*/}
                    {/*</Select>*/}
                    <div className={"helper-text-error"}>
                        {error.submit?error.message:""}</div>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={onCreateSite}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>
            </div>
        </Modal>
    );
};
