import React, {useContext, useEffect} from 'react';
import {Modal, Button, Divider} from 'antd';
import {editSite} from "../api";
import {Store} from "../state_store/provider";
import {SITE} from "../state_store/actionType";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yub from "yup";
import {EDIT} from "../constant/variables";


const schema = yub.object().shape({
    site_name:yub.string().required().min(2),
    site_address: yub.string().required().min(2),

}).required()
export default function EditDialog(props) {
    const {open, onClose, onOK} = props
    const {state, dispatch} = useContext(Store)
    const {site, index} = state
    const {register, handleSubmit, formState: {errors}, setValue} = useForm({
        resolver: yupResolver(schema)
    });

    const onEditSite = (data)=>{
        editSite({...data, id:site[index].id}).then(res=>{
            if(res.result === 0){
                site[index] = {...site[index], name:data.site_name, address:data.site_address}
                dispatch({type:SITE, payload:[...site]})
                onOK()

            }
        })
    }


    useEffect(()=>{
        if(site.length > 0){
            if(site[index]){
                const {name, address} = site[index]
                setValue("site_name", name)
                setValue("site_address", address)
            }
        }

    }, [index, site])
    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>現場を追加</div>
                <div className={"subtitle"}>追加する現場情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"site_edition"} onSubmit={handleSubmit(onEditSite)}>
                        <div className={"input-title"}>現場名</div>
                        <input type="text"
                               className={`ant-input ${errors.site_name?"error":""}`}
                               {...register("site_name")}
                        />
                        <div className={"helper-text-error"}>
                            {errors.site_name?.message}</div>

                        <div className={"input-title"}>住所</div>
                        <input  type="text"
                                className={`ant-input ${errors.site_address?"error":""}`}
                                {...register("site_address")}
                        />
                        <div className={"helper-text-error"}>
                            {errors.site_address?.message}</div>
                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        htmlType={"submit"}
                        form={"site_edition"}
                        type="primary" style={{backgroundColor:"#377EF3"}}>{EDIT}</Button>
                </div>
            </div>
        </Modal>
    );
};
