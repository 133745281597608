import React, {useContext} from 'react';
import {Modal, Button, Divider} from 'antd';
import {addSite, getSite} from "../api";
import {Store} from "../state_store/provider";
import {SITE} from "../state_store/actionType";
import * as yub from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Request} from "../utils";

const schema = yub.object().shape({
    site_name:yub.string().required().min(2),
    site_address: yub.string().required().min(2),

}).required()

export default function AddDialog(props) {
    const {open, onClose, onOK} = props
    const {dispatch} = useContext(Store)
    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(schema)
    });
    const onCreateSite = (data)=>{
            Request(dispatch, addSite(data), onOK).then(error=>{
                if(!error){
                    getSite().then(res=>dispatch({type:SITE, payload:res.sites}))
                    reset()
                }
            })
            // addSite(data).then(res=>{
            //     if(res.result === 0){
            //         getSite().then(res=>dispatch({type:SITE, payload:res.sites}))
            //         onOK()
            //         reset()
            //     }
            // })
    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>現場を追加</div>
                <div className={"subtitle"}>追加する現場情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"site_addition"} onSubmit={handleSubmit(onCreateSite)}>
                        <div className={"input-title"}>現場名</div>
                        <input type="text"
                               className={`ant-input ${errors.site_name?"error":""}`}
                               {...register("site_name")}
                               />
                        <div className={"helper-text-error"}>
                            {errors.site_name?.message}</div>

                        <div className={"input-title"}>住所</div>
                        <input  type="text"
                                className={`ant-input ${errors.site_address?"error":""}`}
                                {...register("site_address")}
                                />
                        <div className={"helper-text-error"}>
                            {errors.site_address?.message}</div>
                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                    onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        htmlType={"submit"}
                        form={"site_addition"}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>
            </div>
        </Modal>
    );
};
