// import {useMap} from 'react-leaflet'

import React, {useContext, useEffect} from 'react'
import {Store} from "../state_store/provider";
import {updateHeavyMachineryPointLatLon, updatePlanPointLatLng, updateRoutePointLatLng} from "../api";
import {FormFormat} from "../utils";

const L = window.L
// function ComponentMap({center}){
//
//     const map = useMap()
//     map.setView(center,15)
//     return null
// }
let map = undefined
// let layer = undefined


const planPointSelectIcon = L.icon({
    iconUrl: '/images/plan_point_select.png',
    iconRetinaUrl: '/images/plan_point_select.png',
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
});

const planPointIcon = L.icon({
    iconUrl: '/images/plan_point.png',
    iconRetinaUrl: '/images/plan_point.png',
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
});

let routePointIcon = undefined
let markers = [];
let roads = []
let machine_point = []
const resetPlanPointMarkerFocus = function() {
    markers.forEach(res=>{
        res.focus = false
        res.setIcon(planPointIcon)
        res.dragging.disable();

    })
}

let kml_layer = []

export default function Map({id, onClick, onMarkerClick, active, onRoadDbClick, onRoadClick, onMachinePointClick}) {
    const {state} = useContext(Store)
    const {kml, center, placement_points, error} = state.placement_point



    const removeMarkers = ()=>{
        markers.forEach(marker=>{
            map.removeLayer(marker)

        })
        markers = []
    }
    const removeMachinePoint = ()=>{
        machine_point.forEach(marker=>{
            map.removeLayer(marker)

        })
        machine_point = []
    }

    const removeRoadsPoint = ()=>{
        roads.forEach(road=>{
            map.removeLayer(road)
        })
        roads = []
    }

    const resetRoutePointMarkerFocus = ()=>{
        roads.forEach(road=>{
            let routePointIcon = L.icon({
                iconUrl: '/upload/' + encodeURIComponent(road.siteId) + '_' + road.routePointId + '.jpg',
                iconRetinaUrl: '/upload/' + encodeURIComponent(road.siteId) + '_' + road.routePointId + '.jpg',
                iconSize: [40, 40],
                iconAnchor: [20, 40],
                popupAnchor: [0, -40],
            });
            road.focus = false;
            road.setIcon(routePointIcon);
            road.dragging.disable();
        })
    }

    const resetMachinePointMarkerFocus = ()=>{
        machine_point.forEach(item=>{
            console.log(item)
            let routePointIcon = L.icon({
                iconUrl: item.image_src,
                iconRetinaUrl: item.image_src,
                iconSize: [40, 40],
                iconAnchor: [20, 40],
                popupAnchor: [0, -40],
            });
            item.focus = false;
            item.setIcon(routePointIcon);
            item.dragging.disable();
        })
    }

    const addMarkers = ()=>{
        state.point?.forEach(res=>{
            L.tooltip()
            let marker = L.marker([res.lat, res.lon], {
                icon : planPointIcon,
                draggable : false,
                title : res.name,
                alt : res.name,
                riseOnHover : true
            }).bindTooltip(res.name,
                {
                    permanent: true,
                    direction: 'top',
                    offset: L.point(0, -4)
                }).addTo(map)

            marker.planPointId = res.id;
            marker.siteId = res.site_id;

            marker.focus = false;

            markers.push(marker)

            marker.on('click', function(ev) {
                resetPlanPointMarkerFocus(placement_points);
                ev.target.focus = true;
                ev.target.setIcon(planPointSelectIcon);
                ev.target.dragging.enable();
                onMarkerClick(ev)

            });

            marker.on('dragend', async function (ev) {
                const {planPointId} = ev.target
                const {lat, lng} = ev.target.getLatLng()

                await updatePlanPointLatLng({id: planPointId, lat, lng})
            })

        })

    }

    const addRoadsPoint = ()=>{
        const {site, index, route_name} = state
        const id = site[index].id
        let time = new Date();
        route_name.forEach(route=> {
            routePointIcon = L.icon({
                iconUrl: '/upload/' + encodeURIComponent(id) + '_' + route.id + '.jpg?' + time.getHours() + time.getMinutes() + time.getSeconds(),
                iconRetinaUrl: '/upload/' + encodeURIComponent(id) + '_' + route.id + '.jpg?' + time.getHours() + time.getMinutes() + time.getSeconds(),
                iconSize: [40, 40],
                iconAnchor: [20, 40],
                popupAnchor: [0, -40],
            });

            let road = L.marker([route.lat, route.lon], {
                icon: routePointIcon,
                draggable: false,
//            title : json.routePoints[i].name,
                title: '画像をアップロードする場合はこちらへ画像ファイルをドラッグ＆ドロップしてください。\n（サポートしている画像ファイルは png/jpeg/jpg です。）',
                alt: route.name,
                riseOnHover: true
            }).bindTooltip(route.name, {
                permanent: true,
                direction: 'top',
                offset: L.point(0, -4)
            }).addTo(map);
            road.routePointId = route.id;
            road.siteId = id;
            road.focus = false;
            road.name = route.name
            road.image_src = '/upload/' + encodeURIComponent(id) + '_' + route.id + '.jpg?' + time.getHours() + time.getMinutes() + time.getSeconds()
            roads.push(road)

            road.on('click', function (ev) {
                resetRoutePointMarkerFocus();
                ev.target.focus = true;
                ev.target.dragging.enable();
                ev.target.setIcon(
                    L.icon({
                        iconUrl: '/upload/' + encodeURIComponent(id) + '_' + ev.target.routePointId + '.jpg?' + time.getHours() + time.getMinutes() + time.getSeconds(),
                        iconRetinaUrl: '/upload/' + encodeURIComponent(id) + '_' + ev.target.routePointId + '.jpg?' + time.getHours() + time.getMinutes() + time.getSeconds(),
                        iconSize: [80, 80],
                        iconAnchor: [20, 40],
                        popupAnchor: [0, -40],
                    })
                );
                onRoadClick(ev)

            })

            road.on('dblclick', (e)=>{
                resetRoutePointMarkerFocus();
                onRoadDbClick(e)
            })

            road.on("dragend", async function (ev) {
                const {routePointId} = ev.target
                const {lat, lng} = ev.target.getLatLng()

                await updateRoutePointLatLng({id: routePointId, lat, lng})
            })
        })


    }



    const addMachinePoint = ()=>{
        const {site, index, heavy_machine_point} = state
        const id = site[index].id
        heavy_machine_point.forEach(item=> {
            routePointIcon = L.icon({
                iconUrl: '/images/' + item.image,
                iconRetinaUrl: '/images/' + item.image,
                iconSize: [40, 40],
                iconAnchor: [20, 40],
                popupAnchor: [0, -40],
            });

            let road = L.marker([item.lat, item.lon], {
                icon: routePointIcon,
                draggable: false,
//            title : json.routePoints[i].name,
                title: '画像をアップロードする場合はこちらへ画像ファイルをドラッグ＆ドロップしてください。\n（サポートしている画像ファイルは png/jpeg/jpg です。）',
                alt: item.name,
                riseOnHover: true
            }).bindTooltip(item.name, {
                permanent: true,
                direction: 'top',
                offset: L.point(0, -4)
            }).addTo(map);
            road.routePointId = item.id;
            road.siteId = id;
            road.focus = false;
            road.name = item.name
            road.image_src = '/images/' + item.image
            road.data = item

            road.on('click', function (ev) {
                resetMachinePointMarkerFocus();
                ev.target.focus = true;
                ev.target.dragging.enable();
                ev.target.setIcon(
                    L.icon({
                        iconUrl:  ev.target.image_src,
                        iconRetinaUrl:  ev.target.image_src,
                        iconSize: [80, 80],
                        iconAnchor: [20, 40],
                        popupAnchor: [0, -40],
                    })
                );
                onMachinePointClick(ev)

            })

            road.on("dragend", async function (ev) {
                const {routePointId} = ev.target
                const {lat, lng} = ev.target.getLatLng()

                await updateHeavyMachineryPointLatLon(FormFormat({id: routePointId, lat, lng}))
            })
            machine_point.push(road)
        })


    }

    const resetMarkerPoint = ()=>{
        removeRoadsPoint()
        removeMarkers()
        removeMachinePoint()
    }

    useEffect(()=>{
        if([1, 2].includes(active)){
            if(map){
                resetMarkerPoint()
                addMarkers()
            }

        }else if(active === 3){
            if(map){
                resetMarkerPoint()
                addRoadsPoint()
            }
        }else if(active === 4){
                resetMarkerPoint()
                addMachinePoint()
        } else {
            removeRoadsPoint()
            removeMarkers()
        }
    }, [active, map, state.point, state.route_name, state.heavy_machine_point])

    useEffect(()=>{
        // map = new LayeredMap(document.getElementById(id), {
        //     center: [0, 0],
        //     zoom: 15
        // })

       map = L.map(id, {
            center: [0, 0],
            zoom: 13
        });
        L.tileLayer('https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
            {maxZoom:24,maxNativeZoom:18,attribution:'<a href="https://maps.gsi.go.jp/development/ichiran.html" target="_blank">地理院タイル</a>'}

            ).addTo(map);
        // document.getElementsByClassName( 'leaflet-control-attribution' )[0].style.display = 'none';
    },[])




    useEffect(()=>{
        if(onClick){
            map.off('click')
            map.on("click", (e)=>onClick(e))

        }
    }, [onClick])

    const removeKMLLayer = ()=>{
        if(map)
        kml_layer.forEach(res=>{
            map.removeLayer(res)
            kml_layer = []
        })
    }
    useEffect(()=>{

        removeKMLLayer()
        if(!error && kml && window.omnivore){
            let track = new window.omnivore.kml.parse(kml);
            map.addLayer(track);

            // Adjust map to show the kml
            const bounds = track.getBounds();
            map.fitBounds(bounds);
            kml_layer.push(track)
        }


    }, [center])


    // const renderDialog = ()=>{
    //     return (
    //         <div>
    //             <OrderAddition/>
    //         </div>
    //     )
    // }


    return (

        <div id={id} style={{height:"100%"}}/>

)
}
