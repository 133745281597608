import React, { useState} from 'react';
import {Modal, Button, Divider} from 'antd';
import position from '../asset/position.png'
import {updateMaterialInfoStatus} from "../api";
import Success from "./success_dialog";

export default function InfoConfirmationDialog(props) {
    const {open, onClose, data} = props
    const [openSuccess, setOpenSuccess] = useState(false)
    const {order_id, plant_amaunt, pid, amaunt_sum} = data



    const onOK = () => {
        const sum = parseInt(amaunt_sum)?parseInt(amaunt_sum):0

        const a_sum = sum + plant_amaunt
        const orderStatus = a_sum === plant_amaunt ? 1 : 6

        updateMaterialInfoStatus({order_id, pid, plant_amaunt, orderStatus, memo:"", status:2}).then(res=>{
            if(res.result === 0){
                onClose()
                setOpenSuccess(true)
            }
        })
    }


    const onNG = () =>{
        updateMaterialInfoStatus({status:3, orderStatus:4, memo:""}).then(res=>{
            if(res.result === 0){
                onClose()
                setOpenSuccess(true)
            }
        })
    }


    return (
        <>
            <Modal visible={open}
                   footer={null}
                   closable={true}
                   width={450}
                   onCancel={onClose}
            >
                <div className={"dialog"}>
                    <div className={"title"} style={{fontSize:18}}>メモ</div>
                    <div className={"group-subtitle column"}>
                        <div className={"row info-subtitle"}>
                            <img src={position} alt={"position"}/>
                            <div>側溝設置 視察</div>
                        </div>
                        <div className={"info-plant_name"}>{data.name + " ✕ " + data.plan_amaunt + "個"}</div>
                        <div className={"info-date"}>{data.status === 2?"完了 ： "+data.confirm_time:"予定 ： " + data.time_plan}</div>
                    </div>
                    <Divider className={"divider"}/>


                    <div className={"add-dialog-body"}>
                        <div style={{fontSize:15, fontWeight:"bold"}}>
                            数量：{data.amount?data.amount:0}
                        </div>
                    </div>

                    <div className={"add-dialog-footer row"}>
                        <Button
                            className={"btn-ng"}
                            onClick={onNG}
                            type="primary" >確認NG</Button>
                        <Button
                            className={"btn-ok"}
                            onClick={onOK}
                            type="primary" >確認OK</Button>
                    </div>
                </div>
            </Modal>

            <Success
                open={openSuccess}
                onClose={()=>setOpenSuccess(false)}
            />
        </>



    )
};
