import React from 'react'
import Drawing from '../../asset/drawing.png'
import {Button, Divider, Input} from "antd";


export default function SystemSetting(){

    return(
        <div className={"system-setting"}>
            <div className={"wrapper"}>
                <div className={"header row"}>
                    <div className={"row center"}>
                        <div className={"icon"}><img src={Drawing} width={16}/></div>
                        <div className={"title"}>現場図面</div>
                    </div>
                    <div/>
                </div>
                <div className={"small-text"}>
                    背景地図にGoogleMapを使⽤する場合はキーを設定します。
                </div>
                <Divider className={"divider"}/>
                <div className={"row input"}>
                    <Input />
                    <Button type="primary">更新する</Button>
                </div>
            </div>




        </div>
    )
}


