import React, {useContext, useEffect, useState} from 'react';
import {Modal, Button, Divider, Select, AutoComplete} from 'antd';
import { editPlanPoint, getPlanPointList} from "../api";
import {Store} from "../state_store/provider";
import {POINT} from "../state_store/actionType";

import {Request} from "../utils";
import {EDIT} from "../constant/variables";

export default function PointEdition(props) {

    const {open, onClose, id} = props
    const [point, setPoint] = useState({name:""})
    const [error, setError] = useState({submit: false, message:"Name is required"})
    const {state, dispatch} = useContext(Store)
    const editPoint = ()=>{
        const id = state.site[state.index].id
        if(!error.message)
        Request(dispatch, editPlanPoint(point), onClose).then(error=>{
            if(!error){
                getPlanPointList(id).then(res=>{
                    dispatch({type: POINT, payload:res.plan_points})
                })
            }
        })
        setError({...error, submit: true})
    }
    const onChange = (value)=>{
        if(value){
            setPoint({...point, name:value})
            setError({...error, message: ""})
        }else {
            setPoint({...point, name:""})
            setError({...error, message: "Name is required"})
        }
    }

    useEffect(()=>{
        if(id){
            const plan_point = state.point.find(res=>res.id === id)
            setPoint(plan_point)
            setError({...error, message: plan_point.name?"":"Name is required"})

        }

    }, [id])
    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>資材希望位置表⽰名追加</div>
                <div className={"subtitle"}>追加する資材希望位置表⽰名を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>

                    <AutoComplete style={{width:"100%"}}
                                  value={point.name}
                                  onChange={onChange}
                                  options={state.point?.map((res, index)=>({value:res.name, key:index}))}
                                  filterOption={(inputValue, option) =>{
                                      return option.value.toLowerCase().includes(inputValue.toLowerCase())
                                  }
                                  }
                    >

                        <input type="text"
                               onChange={(e)=>onChange(e.target.value)}
                               className={`ant-input`}/>
                    </AutoComplete>
                    <div className={"helper-text-error"}>
                        {error.submit?error.message:""}</div>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={editPoint}
                        type="primary" style={{backgroundColor:"#377EF3"}}>{EDIT}</Button>
                </div>
            </div>
        </Modal>
    );
};
