import React, {useContext, useState} from 'react';
import {Modal, Button, Divider, } from 'antd';
import {addGroup, getGroup} from "../api";
import {Store} from "../state_store/provider";
import {GROUP} from "../state_store/actionType";
import * as yub from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Request} from "../utils";


const schema = yub.object().shape({
    id: yub.string().required(),
    company: yub.string().required().min(2),
    email: yub.string().email().required()
}).required()




export default function CompanyRegistration(props) {
    const {open, onClose, onOK} = props
    const [group_data, setGroup_data] = useState({})
    const {dispatch} = useContext(Store)
    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(schema)
    });
    const onCreateGroup = (data)=>{
        Request(dispatch,addGroup(data), onOK).then(error=>{
            if(!error){
                getGroup().then(res=>dispatch({type:GROUP, payload:res.groupList}))
                reset()
            }

        })
        // addGroup(data).then(res=>{
        //     if(res.result === 0){
        //         getGroup().then(res=>dispatch({type:GROUP, payload:res.groupList}))
        //         onOK()
        //         // setGroup_data({})
        //         reset()
        //     }
        // })
    }


    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>グループ追加</div>
                <div className={"subtitle"}>作成するグループ情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"company-registration"} onSubmit={handleSubmit(onCreateGroup)}>
                        <div className={"input-title"}>ID</div>
                        <input type="text"
                               className={`ant-input ${errors.id?"error":""}`}
                               {...register("id")}/>
                        <div className={"helper-text-error"}>
                            {errors.id?.message}</div>

                        <div className={"input-title"}>グループ名</div>
                        <input  type="text"
                                className={`ant-input ${errors.company?"error":""}`}
                                {...register("company")}/>
                        <div className={"helper-text-error"}>
                            {errors.company?.message}</div>

                        <div className={"input-title"}>メールアドレス</div>
                        <input  type="text" className={`ant-input ${errors.email?"error":""}`}
                                {...register("email")}/>
                        <div className={"helper-text-error"}>
                            {errors.email?.message}</div>
                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        htmlType={"submit"}
                        form={"company-registration"}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>
            </div>
        </Modal>
    );
};
