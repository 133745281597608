import React from 'react'
import {Routes, Route} from "react-router-dom";
import AppBar from "../component/app_bar";
import Home from "./home/home";
import Maintenance from "./maintenance/maintenance";
import Work from "./work";
import Progress from "../component/progress";
import ErrorDialog from "../component/error_dialog";
export default function Main(){

            return(
                    <div>
                        <Progress/>
                        <ErrorDialog/>
                        <AppBar/>
                        <Routes>
                                <Route exact path={"/maintenance"} element={<Maintenance/>}/>
                                <Route exact path={"/work"} element={<Work/>}/>
                                <Route exact path={"/*"} element={<Home/>}/>

                            {/*<Route path={"/home/*"} element={<Home/>}/>*/}

                        </Routes>

                    </div>

            )
}