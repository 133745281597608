import React from 'react';
import {Modal, Button} from 'antd';

import {CloseCircleOutlined} from "@ant-design/icons";


export default function ErrorSafety({open, message, onClose}) {

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>

                <div className={"delete-dialog-body column center"}>
                    <div className={"icon"}>
                        <CloseCircleOutlined style={{color:"#ff4d4f"}}/>
                    </div>

                    <div className={"subtitle"}>{message}</div>

                </div>
                <div className={"delete-dialog-footer row center"}>

                    <Button
                        onClick={onClose}
                        type="primary" style={{backgroundColor:"#377EF3"}}>OK</Button>
                </div>
            </div>
        </Modal>
    );
};
