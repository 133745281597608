import React, {useEffect, useState} from "react";
import {CaretDownOutlined} from "@ant-design/icons";
import {Option} from "antd/es/mentions";
import {Button, DatePicker, Select} from "antd";
import './safety_index.css'
import {
    addOrUpdateHealthManagementReports,
    getHealthSelectOptions,
    getHealthWorkers,
} from "../../api";
import {FormFormat, printPDF, Request} from "../../utils";
import moment from "moment";

import Success from "../../component/success_dialog";
import Warning from "../../component/warning";
import ErrorDialog from "../../component/error_dialog";
import ErrorSafety from "../../component/error_safety";

export default function SafetyIndex() {
    const [option, setOption] = useState({})
    const [isPrint, setIsPrint] = useState(false)
    const [update, setUpdate] = useState({})
    const [worker, setWorker] = useState([])
    const [value, setValue] = useState({})
    const [qa, setQa] = useState({})
    const [warning, setWarning] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState({})
    const site_id = localStorage.getItem("site_id")
    const selected = sessionStorage.getItem("selected")
    const point_id = sessionStorage.getItem("point_id")
    const mode = sessionStorage.getItem("mode")
    const user = localStorage.getItem("user")
    const data = selected?JSON.parse(selected): {}
    let health = []
    useEffect(()=>{

        window.onafterprint = ()=>setIsPrint(false)
        getHealthSelectOptions(FormFormat({site_id, worker_id:""})).then(res=>{
            if(res.result === 0){
                setOption(res)
                if(data.timing) {
                    const temp = res.health_managements.find(res => res.value === data.timing)
                    if(temp){
                        setQa(temp.question)
                    }

                }
            }
        })

        getHealthWorkers(FormFormat({site_id, carrier:data.carrier})).then(res=>{
            if(res.result === 0){
                setWorker(res.workers)
            }
        })
        if(["edit", "copy"].includes(mode)){
            let work_date = data.date?moment(data.date):null
            setUpdate({work_date, timing:data.timing, carrier:data.carrier})
            setValue(prevState => ({...prevState, ...data, work_date}))
        }


    },[])

    useEffect(()=>{
        if(update.timing) {
            const temp = option.health_managements?.find(res => res.value === update.timing)
            if(temp){
                setQa(temp.question)
            }

        }
    }, [update.timing])

    useEffect(()=>{
        getHealthWorkers(FormFormat({site_id, carrier:update.carrier})).then(res=>{
            if(res.result === 0){
                setWorker(res.workers)
                health = [...Array(res.workers.length).keys()]

            }
        })
    }, [update.carrier])

    useEffect(()=>{
        if(isPrint)
            printPDF()
    }, [isPrint])

    const renderQuestion = (q, a, index, r)=>{
        if(q){
            if(a === 0){
                return (<Select
                    onChange={(e)=>{
                        return health[r] = {...health[r], [`answer${index}`]: e}
                    }}
                    className={`st-9 s-column ${index===8?"":"bd-r"}`}
                    suffixIcon={<CaretDownOutlined />}
                >
                    <Option value={""}></Option>
                    <Option value={"0"}>はい</Option>
                    <Option value={"1"}>いいえ</Option>

                </Select>)
            }else {
                return (
                    <div className={`txt-f-center st-9 s-column full-h ${index===8?"":"bd-r"}`}>
                        <input className={"wp-input"}
                                      onChange={(e)=>{
                                          return health[r] = {...health[r], [`answer${index}`]: e.target.value}
                                      }}
                        />
                    </div>
                        )
            }
        }
        return <div className={`txt-f-center st-9 s-column full-h ${index===8?"":"bd-r"}`}/>
    }

    const onSubmit = ()=>{
        let answer = []
        const {timing, work_date, carrier} = update
        if(!(timing && work_date && carrier)){
            setWarning(true)
            return
        }

        Array.from(Array(worker.length).keys()).forEach((i, row)=>{
            let res = health[i]
            answer[row] = {site_id, mode: ["copy", "add"].includes(mode) ? "add":"edit",
                worker:user,carrier: update.carrier,
                report_date:update.work_date.format("YYYY-MM-DD"),
                timing:update.timing}
            if(res){
                answer[row]["body_temperature"] = res["body_temperature"]?res["body_temperature"]:null
                answer[row]["work_class"] = res["work_class"]?res["work_class"]:""
            }else{
                answer[row]["body_temperature"] =null
                answer[row]["work_class"] = ""
            }
            [1,2,3,4,5,6,7,8].forEach(item=>{
                if(res)
                    answer[row][`answer${item}`] = res[`answer${item}`]?res[`answer${item}`]:""
                else
                    answer[row][`answer${item}`] = ""

            })
        })
        const submit_data = {site_id, point_id, worker_id: user,
            health_management_reports: JSON.stringify(answer)}

       addOrUpdateHealthManagementReports(FormFormat(submit_data)).then(res=>{
                if(res.result === 0){
                    setSuccess(true)
                }else{
                    setError({open:true, message:res.msg})
                }
       })


    }

    return <div className={"full-w"} style={{height: "100vh", overflowX: "auto"}}>
        <div className={"si column"} style={{justifyContent:"space-between"}}>
            <div className={"column"}>
                <div>体調管理報告書</div>
                <div style={{height:12}}></div>
                <div className={"column"}>
                    <div className={"row bd"} style={{width: "30%"}}>
                        <div className={"st-9 label text-center bd-r"} style={{width:100}}>
                            報告日
                        </div>
                        <div className={"col-height"} style={{width:"calc(100% - 100px)"}}>
                            <div><DatePicker
                                onChange={(e)=>setUpdate(prevState => ({...prevState, work_date:e}))}
                                value={update.work_date}
                                name={"work_date"}
                                placeholder={"年-月-日"}
                                className={"wp-date full-w"}
                                style={{height:32, fontsize:12, margin:0, borderRadius:0}}
                            /></div>
                        </div>
                    </div>
                    <div style={{height:4}}></div>
                    <div className={"row bd"} style={{width: "30%"}}>
                        <div className={"label st-9 text-center bd-r"} style={{width:100}}>
                            業者名
                        </div>
                        <div className={"col-height"} style={{width:"calc(100% - 100px)"}}>
                            <Select
                                onChange={(e)=>setUpdate(prevState => ({...prevState, carrier:e}))}
                                value={update.carrier}
                                className={"st-9 full-w"}

                                suffixIcon={<CaretDownOutlined />}
                            >
                                <Option value={""}></Option>
                                {
                                    option.carriers?.map(res=>{
                                        return <Option value={res.value} key={res.value}>{res.text}</Option>
                                    })
                                }

                            </Select>
                        </div>
                    </div>

                </div>
                <div style={{height:40}}></div>
                <div className={"column"}>
                    <div className={"row"}>
                        <div className={"st-9 text-center"} style={{width:100}}>報告タイミング</div>
                        {/*<div style={{width:20}}></div>*/}
                        <div className={"col-height"} style={{width:250}}>
                            <Select
                                onChange={(e)=>setUpdate(prevState => ({...prevState, timing:e}))}
                                value={update.timing}
                                className={"st-9 full-w bd"}
                                suffixIcon={<CaretDownOutlined />}
                            >
                                {
                                    option.health_managements?.map(res=>{
                                        return <Option value={res.value} key={res.value}>{res.text}</Option>
                                    })
                                }

                            </Select>
                        </div>
                    </div>
                </div>
                <div style={{height:8}}></div>
                <div className={"column bd"}>
                    <div className={"row col-height"} style={{backgroundColor:"#F6F6F6"}}>
                        <div className={"bd-r txt-f-center full-h st-9 s-column"}>氏　名</div>
                        <div className={"bd-r txt-f-center full-h st-9"} style={{width:80}}>年 齢</div>
                        <div className={"bd-r txt-f-center full-h st-9 s-column"}>作 業 分 類</div>
                        <div className={"bd-r txt-f-center full-h st-9"} style={{width:80}}>体 温</div>
                        <div className={"bd-r txt-f-center full-h st-9 s-column"}>{qa.question1}</div>
                        <div className={"bd-r txt-f-center full-h st-9 s-column"}>{qa.question2}</div>
                        <div className={"bd-r txt-f-center full-h st-9 s-column"}>{qa.question3}</div>
                        <div className={"bd-r txt-f-center full-h st-9 s-column"}>{qa.question4}</div>
                        <div className={"bd-r txt-f-center full-h st-9 s-column"}>{qa.question5}</div>
                        <div className={"bd-r txt-f-center full-h st-9 s-column"}>{qa.question6}</div>
                        <div className={"bd-r txt-f-center full-h st-9 s-column"}>{qa.question7}</div>
                        <div className={"txt-f-center full-h st-9 s-column"}>{qa.question8}</div>
                    </div>

                    {
                        worker.map((res, index)=>{
                            return (
                                <div className={"row bd-t col-height"}>
                                    <div className={"bd-r txt-f-center st-9 s-column full-h"}>{res.worker_name}</div>
                                    <div className={"bd-r txt-f-center st-9 full-h"} style={{width:80}}>{res.age}</div>
                                    <div className={"bd-r txt-f-center st-9 s-column full-h"}>
                                        <input className={"wp-input"} value={res.work_class}
                                               onChange={(e)=>{
                                                   return health[index] = {...health[index], ["work_class"]:e.target.value}
                                               }}
                                        />
                                    </div>
                                    <div className={"bd-r txt-f-center st-9 full-h"} style={{width:80}}>
                                        <input
                                            className={"wp-input"}
                                            type={"number"}
                                            min={34.0}
                                            max={45.0}
                                            step={0.1}
                                            onChange={(e)=>{
                                                return health[index] = {...health[index], ["body_temperature"]:e.target.value}
                                            }}
                                        />
                                    </div>
                                    {
                                        [1,2,3,4,5,6,7,8].map(i=>{
                                            return renderQuestion(qa[`question${i}`], qa[`answer${i}`], i, index)
                                        })
                                    }


                                </div>
                            )
                        })
                    }

                </div>

            </div>

            <div className={"row"} style={{marginTop:26, justifyContent:"end", display: `${isPrint?"none":"flex"}`}}>
                <Button type="primary" style={{backgroundColor:"#387FF7"}}
                        onClick={()=> setIsPrint(true)}
                        className={"wp-btn"}
                >印刷する</Button>
                <Button type="primary" style={{marginLeft:44, backgroundColor:"#606060"}}
                        className={"wp-btn"}
                        onClick={()=>window.close()}
                >閉じる</Button>
                <Button type="primary" style={{marginLeft:14, backgroundColor:"#387FF7"}}
                        className={"wp-btn"}
                        onClick={onSubmit}
                >保存</Button>
            </div>
        </div>
        <Warning
            open={warning}
            title={"報告日、報告会社名、報告タイミング項目は必須です。"}
            onClose={()=>setWarning(false)}
        />

        <Success
            open={success}
            title={"重機作業計画書を登録しました"}
            onClose={()=>{
                setSuccess(false)
                window.close()
            }
            }
        />
        <ErrorSafety
            {...error}
            onClose={()=>setError({})}
        />

    </div>
}