import React, {useContext} from 'react';
import {Modal, Button} from 'antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {deleteTruck, getHealthManagement, getWorkers, removeHealthManagement, removeWorker} from "../api";
import {Request} from "../utils";
import {Store} from "../state_store/provider";
import {HEALTH_MANAGEMENT, WORKER} from "../state_store/actionType";


export default function ManagementDelete(props) {
    const {open, onClose, onOK, data} = props
    const {dispatch} = useContext(Store)
    const onDelete = ()=>{
        const {id, timing, name} = data
        Request(dispatch, removeHealthManagement(encodeURIComponent(parseInt(id)), encodeURIComponent(timing), encodeURI(name)), onOK).then(error=>{
            if(!error){
                getHealthManagement().then(res=> {
                    if(res.result === 0)
                        dispatch({type: HEALTH_MANAGEMENT, payload: res.healthManagementList})
                })
            }
        })

    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>


                <div className={"delete-dialog-body column center"}>
                    <div className={"icon"}>
                        <ExclamationCircleOutlined/>
                    </div>
                    <div className={"title"}>体調管理項目削除</div>
                    <div className={"subtitle"}>
                        この体調管理項目を削除しますか？
                    </div>

                </div>
                <div className={"delete-dialog-footer row center"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >いいえ</Button>
                    <Button
                        onClick={onDelete}
                        type="primary" style={{backgroundColor:"#377EF3"}}>はい</Button>
                </div>
            </div>
        </Modal>
    );
};
