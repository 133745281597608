import React, {useState} from 'react'
import logo from '../asset/logo.png'
import {Button} from "antd";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';


import * as yub from 'yup'


const schema = yub.object().shape({

    email: yub.string().required(),
    password: yub.string().required().min(6)
}).required()

function sendData(path, parameters, method = 'post') {

    const form = document.createElement('form');
    form.method = method;
    form.action = path;
    document.body.appendChild(form);
    for (const key in parameters) {
        const formField = document.createElement('input');
        formField.type = 'hidden';
        formField.name = key;
        formField.value = parameters[key];
        form.appendChild(formField);
    }
    form.submit();


}


export default function Login() {
    const navigate = useNavigate()
    const [isLogin, setIsLogin] = useState(false)
    const { register, handleSubmit, formState:{errors} } = useForm({
        resolver: yupResolver(schema)
    });
    const onChange = (e) => {
        // const {name, value} = e.target
        // let data = {...loginData, [name]: value}
        // setLoginData(data)
        //
        // const result = validate(data, validation_rule)
        // setError(result ? result : {})

    }
    const onSubmit = (data) => {
        setIsLogin(true)
        // const result = validate(loginData, validation_rule)
        // setError(result ? result : {})
        const {email, password} =data
        sendData('/login/login', {loginId: email, password: password});
    }


    return (
        <div className={"column center login-layout"}>
            <div className={"login"}>
                <div className={"login-logo"}>
                    <img src={logo} alt={"no image"}/>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} id={"login-form"}>
                    <div className={"login-input"}>

                        <div className={"input-title p-12 p-500 f-gray"}>ログインID</div>

                        <input placeholder="info@gmail.com"
                               className={`ant-input ${errors.email?"error":""}`}
                               {...register("email")}
                               // className={isLogin && error.username ? "error" : ""}
                               // value={loginData.username}
                               name="email" />
                        <div className={"helper-text-error"}>
                            {errors.email?.message}</div>
                        {/*<div className={"input-space-box"}/>*/}
                        <div className={"input-title p-12 p-500 f-gray"}>パスワード</div>
                        <input placeholder="password"
                               {...register("password")}
                               // className={isLogin && error.password ? "error" : ""}
                               // value={loginData.password}
                               type={"password"}
                               className={`ant-input ${errors.password?"error":""}`}
                               name="password" />

                            <div className={"helper-text-error"}>
                                {errors.password?.message}</div>
                        <div className={"input-space-box"}/>
                        <div className={"input-space-box"}/>
                        <Button type="primary"
                                htmlType={"submit"}
                                form={"login-form"}
                                className={"login-btn raise-btn bg-blue p-600"}
                                >ログイン</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}
