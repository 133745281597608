

import 'antd/dist/antd.css';
import './App.css';
import './small_screen.css'
// import './backup.css'
import React from 'react'
import Index from './route/index'
import Provider from "./state_store/provider";


function App() {
  return (
      <Provider>
          <div className="app">
              <Index/>
          </div>
      </Provider>

  );
}

export default App;
