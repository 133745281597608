import "./index.css"
import {Button, Checkbox, DatePicker, Select} from "antd";
import {CaretDownOutlined} from "@ant-design/icons";
import {Store} from "../../state_store/provider";
import React, {useContext, useEffect, useState} from "react";
import {addWorkPlan, getMachineryPoints, getPlacementPointInfo, getSelectOptions} from "../../api";
import {FormFormat, getConstructionMachineNameLabel, getWeightLabel, printPDF} from "../../utils";

import Map from "../../component/map_view";
import {PLACEMENT_POINT} from "../../state_store/actionType";
import TextArea from "antd/es/input/TextArea";
import Warning from "../../component/warning";
import Success from "../../component/success_dialog";
import ErrorSafety from "../../component/error_safety";
const { Option } = Select;
const initialValue = {
    point_id: "",
    work_date: "",
    meeting_date: "",
    use_carrier: "",
    lease_carrier: "",
    work_content: "",
    heavy_machinery: "",
    device: "",
    construction_machine_name: "",
    model: "",
    weight: "",
    ability: "",
    driver: "",
    driver_license: "",
    operation_pattern: "",
    multi_control_point: "",
    work_chief: "",
    earth_retaining_support: "",
    skill_traning_complete: "",
    drilling: "",
    work_conductor: "",
    signaler: "",
    guide_manager: "",
    survery_record: "",
    geology_topography: "",
    signal_method: "",
    signal_method_other: "",
    fall_prevent: "",
    collision_prevent: "",
    multi_control_locking: "",
    multi_control_change_res_person: "",
    attendee1: "",
    attendee2: "",
    attendee3: "",
    attendee4: "",
    attendee5: "",
    attendee6: "",
    work_method: ""
};

export default function WorkPlan(){
    const [option, setOption] = useState({})
    const [point, setPoint] = useState({})
    const [value, setValue] = useState(initialValue)
    const [license, setLicense] = useState([])
    const [warning, setWarning] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState({})
    const [isPrint, setIsPrint] = useState(false)
    const {dispatch} = useContext(Store)
    const site_id = localStorage.getItem("site_id")
    const point_id = sessionStorage.getItem("point_id")
    useEffect(()=>{

        window.onafterprint = ()=>setIsPrint(false)

        getSelectOptions(FormFormat({site_id})).then(res=>{
            if(res.result === 0){
                setOption(res)
            }
        })
        getMachineryPoints(FormFormat({site_id, point_id})).then(res=>{
            if(res.result === 0){
                setPoint(res)
            }
        })
        renderMap(site_id)
    }, [])

    const renderMap = (id)=>{
        getPlacementPointInfo(id).then(placement=>{
            if (placement.result === 0){
                fetch(
                    "/mapfiles/" + placement.mapfile
                )
                    .then((res) => res.text())
                    .then((kmlText) => {
                        const parser = new DOMParser();
                        const kml = parser.parseFromString(kmlText, "text/xml");
                        let long = parseFloat(kml.getElementsByTagName("longitude")[0].childNodes[0].data)
                        let lat = parseFloat(kml.getElementsByTagName("latitude")[0].childNodes[0].data)
                        dispatch({type:PLACEMENT_POINT, payload:{...placement, center:[lat, long], kml:kmlText}})

                    }).catch(error=>{
                    dispatch({type:PLACEMENT_POINT, payload:{...placement, error:true}})
                });
            }
        })
    }

    const onChange = ({target})=>{
        const {name, value} = target
        setValue(prevState => ({...prevState, [name]:value}))
    }

    const onSubmit =()=>{
        const user = localStorage.getItem("user")
        if(!value.work_date){
            setWarning(true)
            return null
        }
        const submit_data = {

            ...value, ...point.heavy_machinery_info,
            work_date: value.work_date?.format("YYYY-MM-DD"),
            meeting_date: value.meeting_date?.format("YYYY-MM-DD"),
            point_id
        }
        let data = {work_plan: JSON.stringify(submit_data), site_id, worker_id:user}

        addWorkPlan(FormFormat(data)).then(res=>{
            if(res.result === 0){
                setSuccess(true)
            }else{
                setError({open:true, message:res.msg})
            }
        })
    }

    useEffect(()=>{
        if(isPrint)
            printPDF()
    }, [isPrint])

    return <div className={"full-w"} style={{height:"100vh", overflowX:"auto"}}>
    <div className={"workplan-layout"}>
            <div className={"row content-layout"}>
                <div className={"column"} style={{width:"50%"}}>
                    <div className={"column header-layout bd-r"}>
                        <div className={"row header-wrapper"} style={{justifyContent:"space-between"}}>
                            <div className={"title-wrapper"}>
                                <div className={"title"}>作　業　計　画　書</div>
                            </div>
                            <div className={"row subtitle-wrapper"}>
                                <div className={"subtitle"}>(車両系建設機械)聖地・運搬・積込用地域 </div>
                                <div className={"subtitle"}>安衛則155</div>
                            </div>
                        </div>
                        <div className={"row"} style={{justifyContent:"space-between", alignItems:"flex-end", height:60, marginTop:10}}>
                            <div className={"subtitle secondary"}
                                 style={{width:"50%"}}
                            >※クレーンモード使用時は、別途クレーンの計画表が必要です。</div>
                            <div className={"column subtitle date-layout"} >
                                <div className={"row date-item-wrapper"} style={{alignItems:"center"}}>
                                    <div>打合せ日</div>
                                    <div className={"row date-item"}>
                                        {/*<div>年</div>*/}
                                        {/*<div>月</div>*/}
                                        {/*<div>日</div>*/}
                                        <DatePicker
                                            name={"meeting_date"}
                                            placeholder={"年-月-日"}
                                            className={"wp-date"}
                                            style={{height:20, fontsize:9}}
                                            onChange={(e)=>onChange({target: {name:"meeting_date", value:e}})} />
                                    </div>
                                </div>
                                <div className={"row date-item-wrapper"} style={{alignItems:"center", paddingBottom:0}}>
                                    <div>作業日</div>
                                    <div className={"row date-item"}>
                                        {/*<div>年</div>*/}
                                        {/*<div>月</div>*/}
                                        {/*<div>日</div>*/}
                                        <DatePicker
                                            name={"work_date"}
                                            placeholder={"年-月-日"}
                                            className={"wp-date"}
                                            style={{height:20, fontsize:9}}
                                            onChange={(e)=>onChange({target:{name:"work_date", value:e}})} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"bd-t"} style={{width:"100%"}}></div>
                    <div className={"row"} style={{width:"100%"}}>
                        <div className={"vt bd-b"} >作業内容</div>
                        <div className={"column"} style={{width:"calc(100% - 30px)"}}>
                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9"} style={{width:100,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;使用業者</div>
                                <Select defaultValue={point.use_carrier}
                                        className={"bd-r st-9"}
                                        onChange={(value)=>onChange({target: {name:"use_carrier", value}})}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width:"calc(50% - 100px)" }} >
                                        <Option value={""}></Option>
                                    {
                                        option.carriers?.map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }
                                </Select>
                                <div className={"bd-r st-9"} style={{width:100,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;リース業者名</div>
                                <Select defaultValue={point.lease_carrier}
                                        className={"st-9"}
                                        onChange={(value)=>onChange({target: {name:"lease_carrier", value}})}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width:"calc(50% - 100px)" }} >
                                        <Option className={""}></Option>
                                    {
                                        option.carriers?.map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }
                                </Select>
                            </div>
                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9"} style={{width:100,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;作業内容</div>
                                <Select defaultValue={point.work_content}
                                        className={"st-9"}
                                        suffixIcon={<CaretDownOutlined />}
                                        onChange={(value)=>onChange({target: {name:"work_content", value}})}
                                        style={{ width:"calc(50% - 100px)" }} >
                                        <Option className={""}></Option>
                                    {
                                        option.work_contents?.map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={"row"} style={{width:"100%"}}>
                        <div className={"vt bd-b"} >機　種　・　性　能</div>
                        <div className={"column"} style={{width:"calc(100% - 30px)"}}>
                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9"} style={{width:100,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;使用機械</div>

                                <div className={"bd-r st-9"} style={{width:"calc(50% - 100px)",
                                    display:"flex",
                                    height:"100%", alignItems:"center", paddingLeft:10}}>{point.heavy_machinery_info
                                    ?.name}</div>
                                <div className={"column bd-r st-9"} style={{width:100,
                                    height:"100%", justifyContent:"center"}}>
                                    <div className={"st-9"}> &nbsp;作業装置</div>
                                    <div className={"st-7"}>（使用アタッチメント）</div>
                                </div>

                                <div className={"st-9"} style={{width:"calc(50% - 100px)",
                                    display:"flex",
                                    height:"100%", alignItems:"center", paddingLeft:10}}>{point.heavy_machinery_info
                                    ?.device}</div>

                            </div>
                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9"} style={{width:100,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;車両系建設機械名</div>
                                <div className={"bd-r st-9"} style={{width:"calc(50% - 100px)",
                                    display:"flex",
                                    height:"100%", alignItems:"center", paddingLeft:10}}>
                                    {getConstructionMachineNameLabel(point.heavy_machinery_info
                                    ?.construction_machine_name)}</div>

                                <div className={"column bd-r st-9"} style={{width:100,
                                    height:"100%", justifyContent:"center"}}>
                                    <div className={"st-9"}>&nbsp;型式</div>

                                </div>
                                <div className={"st-9"} style={{width:"calc(50% - 100px)",
                                    display:"flex",
                                    height:"100%", alignItems:"center", paddingLeft:10}}>{point.heavy_machinery_info
                                    ?.model}</div>

                            </div>
                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"st-9 bd-r"} style={{width:100,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;機体重量</div>
                                <div className={"st-9"} style={{width:"calc(50% - 100px)",
                                    display:"flex",
                                    height:"100%", alignItems:"center", paddingLeft:10}}>{getWeightLabel(point.heavy_machinery_info
                                    ?.weight)}</div>
                            </div>

                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9 full-h"} style={{width:100,
                                    display:"flex",
                                     alignItems:"center"}}>&nbsp;機体能力</div>
                                <div className={"st-9"} style={{width:"calc(50% - 100px)",
                                    display:"flex",
                                    height:"100%", alignItems:"center", paddingLeft:10}}>{point.heavy_machinery_info
                                    ?.ability}</div>
                                {/*<div className={"column st-9"} style={{width:"50%",*/}
                                {/*    height:"100%", justifyContent:"center"}}>*/}
                                {/*    <div className={"st-7"}>&nbsp; その他（　　　　　　　　　　　　　 　　　　　　　 ）</div>*/}

                                {/*</div>*/}

                            </div>



                        </div>
                    </div>


                    <div className={"row"} style={{width:"100%"}}>
                        <div className={"vt bd-b"} >人　員　・　配　置</div>
                        <div className={"column"} style={{width:"calc(100% - 30px)"}}>
                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9"} style={{width:100,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;運転者名</div>
                                <Select defaultValue={point.driver}
                                        className={"bd-r st-9"}
                                        onChange={(value)=>{

                                            setLicense(Object.values(option.drivers).find(res=>res.value === value)?.licenses)
                                            onChange({target:{name:"driver", value}})
                                            setValue(prevState => ({...prevState, driver_license:""}))
                                        }}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width:"calc(50% - 100px)" }} >
                                    {
                                        (option.drivers?Object.values(option.drivers):[]).map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }
                                </Select>
                                <div className={"column bd-r st-9"} style={{width:100,
                                    height:"100%", justifyContent:"center"}}>
                                    <div className={"st-9"}> &nbsp;資格証番号</div>
                                </div>
                                <Select
                                        className={"st-9"}
                                        value={value.driver_license}
                                        onChange={(value)=>onChange({target:{name:"driver_license", value}})}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width:"calc(50% - 100px)" }} >
                                        <Option value={""}></Option>
                                    {
                                        license.map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }


                                </Select>
                            </div>
                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9"} style={{width:100,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;操作パターン</div>
                                <div className={"bd-r full-h"} style={{ width:"calc(50% - 100px)" }}>
                                    <input className={"wp-input"}
                                           onChange={onChange}
                                           name={"operation_pattern"} />
                                </div>

                                <div className={"column bd-r st-9"} style={{width:100,
                                    height:"100%", justifyContent:"center"}}>
                                    <div className={"st-9"}> マルチコントロール</div>
                                    <div className={"st-9"} style={{display:"flex", justifyContent:"center"}} >の　位　置</div>
                                </div>
                                <div className={"full-h"} style={{ width:"calc(50% - 100px)" }}>
                                    <input className={"wp-input"}
                                           onChange={onChange}
                                           name={"multi_control_point"} />
                                </div>
                            </div>

                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9"} style={{width:100,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;作業主任者名</div>
                                <Select defaultValue=""
                                        className={"bd-r st-9"}
                                        onChange={(value)=>onChange({target:{name:"work_chief", value}})}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width:"calc(50% - 100px)" }} >
                                    {
                                        option.workers?.map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }
                                </Select>
                                <div className={"column bd-r st-9"} style={{width:100,
                                    height:"100%", justifyContent:"center"}}>
                                    <div className={"st-9"}>&nbsp;土留支保工</div>

                                </div>
                                <Select defaultValue="0"
                                        className={"st-9"}
                                        onChange={(value)=>
                                            onChange({target:{name:"earth_retaining_support", value}})}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width:"calc(50% - 100px)" }} >
                                    <Option className={"wp-input"} value="0">必要なし</Option>
                                    <Option value="1">必要あり</Option>
                                </Select>
                            </div>


                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9"} style={{width:100,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;技能講修了</div>
                                <div className={"bd-r full-h"} style={{ width:"calc(50% - 100px)" }}>
                                    <input className={"wp-input"}
                                           onChange={onChange}
                                           name={"skill_traning_complete"} />
                                </div>
                                <div className={"column bd-r st-9"} style={{width:100,
                                    height:"100%", justifyContent:"center"}}>
                                    <div className={"st-9"}>&nbsp; 2m以上の地山</div>
                                    <div className={"st-9"} style={{display:"flex", justifyContent:"center"}}>の掘　削</div>

                                </div>
                                <Select defaultValue="0"
                                        className={"st-9"}
                                        onChange={(value)=>onChange({target:{name:"drilling", value}})}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width:"calc(50% - 100px)" }} >
                                    <Option className={"wp-input"} value="0">必要なし</Option>
                                    <Option value="1">必要あり</Option>
                                </Select>
                            </div>


                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9 full-h"} style={{width:100,
                                    display:"flex",
                                     alignItems:"center"}}>&nbsp;作業指揮者名</div>
                                <Select defaultValue=""
                                        className={"st-9"}
                                        onChange={(value)=>onChange({target:{name:"work_conductor", value}})}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width:"calc(50% - 100px)" }} >
                                        <Option value={""}></Option>
                                    {
                                        (option.work_conductors?Object.values(option.work_conductors):[])?.map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }
                                </Select>

                            </div>


                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9 full-h"} style={{width:100,
                                    display:"flex",
                                     alignItems:"center"}}>&nbsp;合図者名</div>
                                <Select defaultValue=""
                                        className={"st-9"}
                                        onChange={(value)=>onChange({target:{name:"signaler", value}})}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width:"calc(50% - 100px)" }} >
                                    {
                                        (option.signalers?Object.values(option.signalers):[])?.map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }
                                </Select>

                            </div>


                            <div className={"row bd-r bd-b bd-l col-height"}>
                                <div className={"bd-r st-9 full-h"} style={{width:100,
                                    display:"flex",
                                     alignItems:"center"}}>&nbsp;誘導監視員名</div>
                                <Select defaultValue=""
                                        className={"st-9"}
                                        onChange={(value)=>onChange({target:{name:"guide_manager", value}})}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width:"calc(50% - 100px)" }} >
                                    {
                                        (option.guide_managers?Object.values(option.guide_managers):[])?.map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }
                                </Select>

                            </div>




                        </div>
                    </div>


                    <div className={"row"} style={{width:"100%"}}>

                        <div className={"column"} style={{width:"calc(100%)"}}>
                            <div className={"row bd-r bd-b col-height"}>
                                <div className={"bd-r st-9"} style={{width:131,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;調査・記録</div>
                                <div className={"full-h bd-r"} style={{ width:"calc(50% - 115px)" }}>
                                    <input className={"wp-input"} onChange={onChange} name={"survery_record"} />
                                </div>
                                <div className={"column bd-r st-9"} style={{width:100,
                                    height:"100%", justifyContent:"center"}}>
                                    <div className={"st-9"}> &nbsp;地質・地形</div>
                                </div>
                                <div className={"full-h"} style={{ width:"calc(50% - 116px)" }}>
                                    <input className={"wp-input"} onChange={onChange} name={"geology_topography"} />
                                </div>
                            </div>




                        </div>
                    </div>

                    <div className={"row"} style={{width:"100%"}}>

                        <div className={"column"} style={{width:"calc(100%)"}}>
                            <div className={"row bd-r bd-b col-height"}>
                                <div className={"bd-r st-9 "} style={{width:131,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;合図方法</div>

                                <div className={"row"} style={{width:"calc(100% - 131px)"}}>
                                    <div className={"row checkbox-layout"}
                                         style={{height:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                        <Checkbox onChange={({target})=>onChange({target:{name:"signal_method_hand",
                                                value: target.check}})}>手</Checkbox>
                                        <Checkbox onChange={({target})=>onChange({target:{name:"signal_method_whistle",
                                                value: target.check}})}>笛</Checkbox>
                                        <Checkbox onChange={({target})=>onChange({target:{name:"signal_method_wireless",
                                                value: target.check}})}>無線</Checkbox>
                                        <Checkbox onChange={({target})=>onChange({target:{name:"signal_method_other",
                                                value: target.check}})}>その他</Checkbox>
                                    </div>
                                    <div className={"row full-h"} style={{ width:"calc(50% - 80px)", alignItems:"center" }}>
                                        (<input className={"wp-input"}
                                                onChange={onChange}
                                                name={"signal_method_other_value"} />)
                                    </div>
                                </div>

                            </div>




                        </div>
                    </div>


                    <div className={"row"} style={{width:"100%"}}>

                        <div className={"column"} style={{width:"calc(100%)"}}>
                            <div className={"row bd-r bd-b col-height"}>
                                <div className={"bd-r st-9"} style={{width:131,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;転落の防止</div>
                                <div className={"full-h bd-r"} style={{ width:"calc(50% - 115px)" }}>
                                    <input className={"wp-input"}
                                           onChange={onChange}
                                           name={"fall_prevent"} />
                                </div>
                                <div className={"column bd-r st-9"} style={{width:100,
                                    height:"100%", justifyContent:"center"}}>
                                    <div className={"st-9"}> &nbsp;接触の防止</div>
                                </div>
                                <div className={"full-h"} style={{ width:"calc(50% - 116px)" }}>
                                    <input className={"wp-input"}
                                           onChange={onChange}
                                           name={"collision_prevent"} />
                                </div>
                            </div>




                        </div>
                    </div>

                    <div className={"row"} style={{width:"100%"}}>

                        <div className={"column"} style={{width:"calc(100%)"}}>
                            <div className={"row bd-r bd-b col-height"}>
                                <div className={"bd-r st-9"} style={{width:131,
                                    display:"flex",
                                    height:"100%", alignItems:"center"}}>&nbsp;マルチコントロール施錠</div>
                                <div className={"full-h bd-r"} style={{ width:"calc(50% - 115px)" }}>
                                    <input className={"wp-input"}
                                           onChange={onChange}
                                           name={"multi_control_locking"} />
                                </div>
                                <div className={"column bd-r st-9"} style={{width:100,
                                    height:"100%", justifyContent:"center"}}>
                                    <div className={"st-9"}> &nbsp;マルチコントロール切り替え責任者</div>
                                </div>
                                <Select defaultValue=""
                                        className={"st-9"}
                                        onChange={(value)=>onChange({target:{name:"multi_control_change_res_person", value}})}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width:"calc(50% - 116px)" }} >
                                    {
                                        option.workers?.map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }
                                </Select>
                            </div>

                        </div>
                    </div>



                    <div className={"row"} style={{width:"100%"}}>
                        <div className={"vt"} >作業内容</div>
                        <div className={"column"} style={{width:"calc(100% - 30px)"}}>
                            <div className={"row bd-r bd-l text-layout"} >
                                <div className={"column text-column"}>
                                    <div className={"st-9"}>1. 運転者以外に労働者を乗せないこと。</div>
                                    <div className={"st-9"}>2. 作業開始前にブレーキ・クラッチ等の点検を行うこと。</div>
                                    <div className={"st-9"}>3. 合図者、誘導者等との打合せを行うこと。</div>
                                    <div className={"st-9"}>4. 作業中は運転席から離れないこと。</div>
                                    <div className={"st-9"}>5. 作業打合せ以外の作業をしないこと。</div>
                                    <div className={"st-9"}>6. 安全度、最大使用荷重を守ること。</div>
                                </div>

                                <div className={"column text-column"} >
                                    <div className={"st-9"}>7. 地形、地質を確認すること。</div>
                                    <div className={"st-9"}>8. 運転席から離れる場合はキーを保管しておくこと。 </div>
                                    <div className={"st-9"}>9. 機体から離れるときは、パーキングブレーキにすること。 </div>
                                    <div className={"st-9"}>10. 用途外使用の禁止。</div>
                                    <div className={"st-9"}>11. 資格証を携帯しているか確認すること。</div>
                                    <div className={"st-9"}>12. マルチの施錠と責任者を決めること。</div>
                                </div>

                            </div>




                        </div>
                    </div>


                </div>

                <div className={"column"} style={{width:"50%"}}>
                    <div className={"row"} style={{justifyContent:"space-between"}} >
                        <div className={"column center bd-b"} style={{height:121}}>
                            <div className={"row subtitle bd-r center"} style={{height:24, width:"100%"}}><b>承　認　欄</b></div>
                            <div className={"row small-subtitle"} style={{height:"100%"}}>
                                <div className={"column"} style={{width:85}}>
                                    <div className={"column bd-t bd-r center"} style={{height:26}}>
                                        <div>統括安全衛生責任者</div>
                                        <div>（または準ずる担当者）</div>
                                    </div>
                                    <div className={"bd-r bd-t"} style={{height:"100%"}}></div>
                                </div>
                                <div className={"column"} style={{width:85}}>
                                    <div className={"column bd-t bd-r center"} style={{height:26}}>元方安全衛生管理者</div>
                                    <div className={"bd-r bd-t"} style={{height:"100%"}}></div>
                                </div>
                                <div className={"column"} style={{width:85}}>
                                    <div className={"column bd-t bd-r center"} style={{height:26}}>担　当　者</div>
                                    <div className={"bd-r bd-t"} style={{height:"100%"}}></div>
                                </div>
                            </div>
                        </div>

                        <div className={"column bd-b"} style={{width:"calc(100% - 280px)", height:121}}>
                            <div className={"row small-subtitle"} style={{height:24,
                                alignItems:"center", justifyContent:"flex-end", marginRight:18}}>2022.05.30 本社　安全管理部　改訂</div>
                            <div className={"row small-subtitle"} style={{height:"100%"}}>
                                <div className={"column"} style={{width:85}}>
                                    <div className={"column bd-t bd-l bd-r center"} style={{height:26}}>
                                        職長・安責者
                                    </div>
                                    <div className={"bd-r bd-t bd-l"} style={{height:"100%"}}></div>
                                </div>
                                <div className={"column"} style={{width:"100%"}}>
                                    <div className={"column bd-t center"} style={{height:26}}>
                                        <div>打　合　せ　出　席　者　サ　イ　ン </div>
                                        <div>（作　業　に　関　わ　る　全　員　の　サ　イ　ン）</div>
                                    </div>
                                    <div className={"bd-t"} style={{height:"100%"}}>
                                        <div className={"column"} style={{height:"100%", justifyContent:"space-around", padding:8}}>
                                            <div className={"row"}>
                                                <Select defaultValue=""
                                                        className={"st-9"}
                                                        onChange={(value)=>onChange({target:{name:"attendee1", value}})}
                                                        suffixIcon={<CaretDownOutlined />}
                                                        style={{ width:"calc(100%/3)" }} >v
                                                    {
                                                        option.workers?.map(res=>{
                                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                                        })
                                                    }
                                                </Select>
                                                <Select defaultValue=""
                                                        className={"st-9"}
                                                        onChange={(value)=>onChange({target:{name:"attendee2", value}})}
                                                        suffixIcon={<CaretDownOutlined />}
                                                        style={{ width:"calc(100%/3)" }} >
                                                    {
                                                        option.workers?.map(res=>{
                                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                                        })
                                                    }
                                                </Select>
                                                <Select defaultValue=""
                                                        className={"st-9"}
                                                        onChange={(value)=>onChange({target:{name:"attendee3", value}})}
                                                        suffixIcon={<CaretDownOutlined />}
                                                        style={{ width:"calc(100%/3)" }} >
                                                    {
                                                        option.workers?.map(res=>{
                                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                            <div className={"row"}>
                                                <Select defaultValue=""
                                                        className={"st-9"}
                                                        onChange={(value)=>onChange({target:{name:"attendee4", value}})}
                                                        suffixIcon={<CaretDownOutlined />}
                                                        style={{ width:"calc(100%/3)" }} >
                                                    {
                                                        option.workers?.map(res=>{
                                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                                        })
                                                    }
                                                </Select>
                                                <Select defaultValue=""
                                                        className={"st-9"}
                                                        onChange={(value)=>onChange({target:{name:"attendee5", value}})}
                                                        suffixIcon={<CaretDownOutlined />}
                                                        style={{ width:"calc(100%/3)" }} >
                                                    {
                                                        option.workers?.map(res=>{
                                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                                        })
                                                    }
                                                </Select>
                                                <Select defaultValue=""
                                                        className={"st-9"}
                                                        onChange={(value)=>onChange({target:{name:"attendee6", value}})}
                                                        suffixIcon={<CaretDownOutlined />}
                                                        style={{ width:"calc(100%/3)" }} >
                                                    {
                                                        option.workers?.map(res=>{
                                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{padding:"0 10px"}}>
                        <div className={"st-7 col-height"} style={{display:"flex", alignItems:"center"}}>
                            <b>作業方法（元請または一次下請等からの指示事項、連絡調整等）</b>
                        </div>
                        {/*<div className={"bd-t bd-l bd-r"}>*/}
                        {/*    <div className={"st-9 text-center col-height "}>路床部分鍬取り後転圧</div>*/}
                        {/*</div>*/}
                        {/*<div className={"bd-t bd-l bd-r"}>*/}
                        {/*    <div className={"st-9 text-center col-height "}>作業範囲全体を立ち入り禁止範囲とする</div>*/}
                        {/*</div>*/}
                        {/*<div className={"bd-t bd-l bd-r"}>*/}
                        {/*    <div className={"st-9 text-center col-height "}>機械移動時は徐行して、機械の転倒等がないようにする</div>*/}
                        {/*</div>*/}
                        {/*<div className={"bd-t bd-l bd-r"}>*/}
                        {/*    <div className={"st-9 text-center col-height "}></div>*/}
                        {/*</div>*/}
                        {/*<div className={"bd-t bd-l bd-r bd-b"}>*/}
                        {/*    <div className={"st-9 text-center col-height "}></div>*/}
                        {/*</div>*/}

                        <TextArea className={"wp-text-area"} name={"work_method"}
                                  autoSize
                                  onChange={onChange}/>
                        <div className={"col-height"}>
                        </div>
                        <div className={"bd"}>
                            <div className={"column"} style={{height:495}}>
                                <div className={"row bd-b"}
                                     style={{justifyContent:"space-between", padding:8, height:60}}>
                                    <div className={"st-7"}>平面図 <span className={"st-5"}>（建設機械等配置図）</span></div>

                                    <div className={"row"} style={{display:"flex", alignItems:"center"}}>
                                        <div className={"st-9"}>建設重機</div>
                                        <div style={{marginLeft:12}}><img src={`/images/${point.heavy_machinery_info?.image}`} width={36} alt={""}/></div>
                                    </div>
                                </div>

                                    <Map id={"work_plan_map"}
                                         heavy_machinery_point={point.heavy_machinery_point}
                                         container_id={"work_plan_container"}/>
                            </div>
                        </div>
                    </div>

                </div>



            </div>

        <div className={"row"} style={{marginTop:26, justifyContent:"end", display: `${isPrint?"none":"flex"}`}}>
            <Button type="primary" style={{backgroundColor:"#387FF7"}}
                    onClick={()=>setIsPrint(true)}
            className={"wp-btn"}
            >印刷する</Button>
            <Button type="primary" style={{marginLeft:44, backgroundColor:"#606060"}}
                    className={"wp-btn"}
                    onClick={()=>window.close()}
            >閉じる</Button>
            <Button type="primary" style={{marginLeft:14, backgroundColor:"#387FF7"}}
                    className={"wp-btn"}
                    onClick={onSubmit}
            >保存</Button>
        </div>
    </div>

        <Warning
            open={warning}
            title={"作業日項目は必須です。"}
            onClose={()=>setWarning(false)}
        />

        <Success
            open={success}
            title={"重機作業計画書を登録しました"}
            onClose={()=>{
                setSuccess(false)
                window.close()
            }
            }
        />
        <ErrorSafety
            {...error}
            onClose={()=>setError({})}
        />
    </div>
}