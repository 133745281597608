import React, {useContext, useEffect, useState} from 'react'
import addition from '../../asset/addition.png'
import edition from '../../asset/edit.png'
import deletion from '../../asset/deletion.png'
import operation from '../../asset/operation.png'
import {Divider} from "antd";
import {Store} from "../../state_store/provider";
import {getHealthManagement, getSite, getUsers} from "../../api";
import {HEALTH_MANAGEMENT, INDEX, SITE, USERS} from "../../state_store/actionType";
import healthcare from '../../asset/healthcare.png'
import TableIconButton from "../../component/table_icon_button";
import ManagementRegistration from "../../component/management_registration";
import ManagementEdition from "../../component/management_edition";
import ManagementDelete from "../../component/management_delete";
import {getAnswerLabel, getDayOfWeekLabel} from "../../utils";




export default function Management(){

    const {state, dispatch} = useContext(Store)
    const [openAddition, setOpenAddition] = useState(false)
    const [openEdition, setOpenEdition] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [selected, setSelected] = useState({})
    useEffect(()=>{
        getSite().then(res=>{
            if(res.result === 0){
                dispatch({type:SITE, payload:res.sites})
            }


        })
        getHealthManagement().then(res=> {
            if(res.result === 0)
                dispatch({type: HEALTH_MANAGEMENT, payload: res.healthManagementList})
        })
    },[])

    const onOK =()=>{
        setSelected({})
        setOpenDelete(false)
        setOpenAddition(false)
        setOpenEdition(false)
    }
    return(
        <div className={"user-list management"}>
            <div className={"wrapper"}>
                <div className={"header row"}>
                    <div className={"row center"}>
                        <div className={"icon"}><img src={healthcare} width={16}  alt={""}/></div>
                        <div className={"title"}>体調管理項⽬登録</div>
                    </div>
                    <div className={"row center"}>
                        <TableIconButton icon={addition} title={"追加"}
                                         selected={openAddition}
                                         onClick={()=>setOpenAddition(true)}/>
                        <TableIconButton icon={edition} title={"編集"}
                                         selected={openEdition}
                                         onClick={()=>setOpenEdition(!!selected.id)}
                        />

                        <TableIconButton icon={deletion} title={"削除"}
                                         selected={openDelete}
                                         onClick={()=>setOpenDelete(!!selected.id)}
                        />
                    </div>
                </div>

                <Divider className={"divider"}/>
            </div>

            <div className={"column table-wrapper"}>
                <div className={"row tb-h g-management"}>
                    <div>現場名</div>
                    <div>質問タイミング</div>
                    <div>通知時間</div>
                    <div>通知開始⽇付</div>
                    <div>通知終了⽇付</div>
                    <div>通知曜⽇</div>
                    <div>質問1</div>
                    <div>質問2</div>
                    <div>質問3</div>
                    <div>質問4</div>
                    <div>質問5</div>
                    <div>質問6</div>
                    <div>質問7</div>
                    <div>質問8</div>
                </div>
                {state.health_management.map((res, index)=>(
                    <div className={`row tb-li g-management sbd pointer ${index===selected.index?"row-active":""}`} key={index}

                         onClick={()=>setSelected({...res, index})}
                    >
                        <div className={"text-wrapper"}>{res.name}</div>
                        <div className={"text-wrapper"}>{res.timing.substr(0, 2) } 時</div>
                        <div className={"text-wrapper"}>{res.notice_time.substr(0, 5)}</div>
                        <div className={"text-wrapper"}>{res.from_date}</div>
                        <div className={"text-wrapper"}>{res.to_date}</div>
                        <div className={"text-wrapper"}>{res.day_of_week ? getDayOfWeekLabel(Number(res.day_of_week)) : ''}</div>
                        <div className={"text-wrapper"}>{res.question1 ? getAnswerLabel(res.answer1) : ''}</div>
                        <div className={"text-wrapper"}>{res.question2 ? getAnswerLabel(res.answer2) : ''}</div>
                        <div className={"text-wrapper"}>{res.question3 ? getAnswerLabel(res.answer3) : ''}</div>
                        <div className={"text-wrapper"}>{res.question4 ? getAnswerLabel(res.answer4) : ''}</div>
                        <div className={"text-wrapper"}>{res.question5 ? getAnswerLabel(res.answer5) : ''}</div>
                        <div className={"text-wrapper"}>{res.question6 ? getAnswerLabel(res.answer6) : ''}</div>
                        <div className={"text-wrapper"}>{res.question7 ? getAnswerLabel(res.answer7) : ''}</div>
                        <div className={"text-wrapper"}>{res.question8 ? getAnswerLabel(res.answer8) : ''}</div>
                    </div>
                ))}

            </div>

            <ManagementRegistration open={openAddition}
                                     onOK={onOK}
                                     onClose={()=>setOpenAddition(false)}
            />


            <ManagementEdition open={openEdition}
                                onOK={onOK}
                                onClose={()=>setOpenEdition(false)}
                                data={selected}
            />

            <ManagementDelete open={openDelete}
                               onOK={onOK}
                               onClose={()=>setOpenDelete(false)}
                               data={selected}
            />



        </div>
    )

}



