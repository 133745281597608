import React, {useContext, useEffect, useState} from 'react';
import {Modal, Button, Divider, Input, Select} from 'antd';

import {Store} from "../state_store/provider";

import {Option} from "antd/es/mentions";
import {FormFormat, Request} from "../utils";
import {Controller, useForm} from "react-hook-form";
import * as yub from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {addHeavyMachineryPoint, addWorker, getHeavyMachineryPoints, getWorkers} from "../api";
import {HEAVY_MACHINE_POINT, WORKER} from "../state_store/actionType";


const schema = yub.object().shape({
    name:yub.string().min(2).required(),
    heavy_machinery_id:yub.string().required(),
    use_carrier_id: yub.string().required(),
    lease_carrier_id: yub.string().required(),
    work_content:yub.string().required()


}).required()

export default function MachineRegistration(props) {
    const {open, onClose, onOK, data, order_selected} = props
    const {register, handleSubmit, formState: {errors}, reset, control} = useForm({
        resolver: yupResolver(schema)
    });
    const {state, dispatch} = useContext(Store)
    const {site, index} = state
    const onCreateMachinePoint = (value)=>{
        const site_id = site[index]?.id
        const {lat, lng} = data


        if(site_id)
        Request(dispatch, addHeavyMachineryPoint(FormFormat({...value, site_id, lat, lng})), onOK).then(error=>{
            if(!error){
                getHeavyMachineryPoints(site_id).then(res=>{
                    if(res.result === 0){
                        dispatch({type:HEAVY_MACHINE_POINT, payload:res.heavyMachineryPoints})
                    }
                })
                reset()
            }
        })
    }



    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >


            <div className={"dialog"}>
                <div className={"title"}>作業重機位置を追加</div>
                <div className={"subtitle"}>追加する作業重機の位置と情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"machine_point-registration"} onSubmit={handleSubmit(onCreateMachinePoint)}>


                    <div className={"input-title"}>作業重機位置表⽰名</div>
                    <input type="text"
                           className={`ant-input ${errors.name ? "error" : ""}`}
                           {...register("name")}/>
                    <div className={"helper-text-error"}>
                        {errors.name?.message}</div>


                    <div className={"input-title"}>重機</div>
                    <Controller
                        control={control}
                        name={"heavy_machinery_id"}
                        render={({field})=>(
                            <Select className={`select-field`}
                                    status={errors.heavy_machinery_id?"error":""}
                                    {...field}
                            >
                                {state.heavy_machine.map(item => (
                                    <Option value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        )}
                    />
                    <div className={"helper-text-error"}>
                        {errors.heavy_machinery_id?.message}</div>


                    <div className={"input-title"}>使⽤業者</div>
                    <Controller
                        control={control}
                        name={"use_carrier_id"}
                        render={({field})=>(
                            <Select className={`select-field`}
                                    status={errors.use_carrier_id?"error":""}
                                    {...field}
                            >
                                {state.group.map(item => (
                                    <Option value={item.id}>{item.company}</Option>
                                ))}
                            </Select>
                        )}
                    />
                    <div className={"helper-text-error"}>
                        {errors.use_carrier_id?.message}</div>


                    <div className={"input-title"}>リース業者</div>
                    <Controller
                        control={control}
                        name={"lease_carrier_id"}
                        render={({field})=>(
                            <Select className={`select-field`}
                                    status={errors.lease_carrier_id?"error":""}
                                    {...field}

                            >
                                {state.group.map(item => (
                                    <Option value={item.id}>{item.company}</Option>
                                ))}
                            </Select>
                        )}
                    />
                    <div className={"helper-text-error"}>
                        {errors.lease_carrier_id?.message}</div>



                    <div className={"input-title"}>作業内容</div>
                    <Controller
                        control={control}
                        name={"work_content"}
                        render={({field})=>(
                            <Select className={`select-field`}
                                    status={errors.work_content?"error":""}
                                    {...field}
                            >
                                {state.work_content.map(item => (
                                    <Option value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        )}
                    />
                    <div className={"helper-text-error"}>
                        {errors.work_content?.message}</div>



                    </form>

                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        htmlType={"submit"}
                        form={"machine_point-registration"}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>


            </div>
        </Modal>
    );
};
