import React, {useEffect, useState} from 'react';
import {Modal, Button, Divider, Input, Select} from 'antd';
import position from '../asset/position.png'

import {addMemo, getMemoList} from "../api";

export default function MaterialInfoDialog(props) {
    const {open, onClose, data} = props
    const [memo, setMemo] = useState([])
    const [message, setMessage] = useState("")
    const onCloseModal = () => {
      onClose()
    }

    useEffect(()=>{
        const {pid, truck_id, order_id} = data

        getMemoList({pid, truck_id, id:order_id}).then(res=>{
            if(res.result === 0){
                setMemo(res.list)
            }
        })
    }, [data])

    const updateMessage = ()=>{
        const {pid, truck_id, order_id} = data
        addMemo({id:order_id, pid, truck_id, memo:message}).then(res=>{
            if(res.result === 0){

                onClose()
            }
        })
    }

    return (

        <Modal visible={open}
               footer={null}
               closable={true}
               width={450}

        >
            <div className={"dialog"}>
                <div className={"title"} style={{fontSize:18}}>メモ</div>
                <div className={"group-subtitle column"}>
                    <div className={"row info-subtitle"}>
                        <img src={position} alt={"position"}/>
                        <div>側溝設置 視察</div>
                    </div>
                    <div className={"info-plant_name"}>{data.name + " ✕ " + data.plan_amaunt + "個"}</div>
                    <div className={"info-date"}>{data.status === 2?"完了 ： "+data.confirm_time:"予定 ： " + data.time_plan}</div>
                </div>
                <Divider className={"divider"}/>


                <div className={"add-dialog-body"}>
                    {
                        memo.map(res=>{
                            return(
                                <div className={"column info-li"}>
                                    <div className={"info-message"}>{res.content}</div>
                                    <div className={"row sub-info"}>
                                        {res.title}
                                    </div>
                                </div>
                            )
                        })
                    }


                    <div className={"column input-message"}>
                        <textarea rows={4} style={{ height: 54 }} value={message} onChange={(e)=>setMessage(e.target.value)}/>
                        <div className={"row info-action"}>
                            <div className={"action-close"} onClick={onCloseModal}>キャンセル</div>
                            <div className={"action-submit"} onClick={updateMessage}>送信</div>
                        </div>
                    </div>
                </div>

                <div className={"add-dialog-footer row"}>

                    <Button
                        onClick={onCloseModal}
                        type="primary" style={{backgroundColor:"#377EF3", height:42, width:140}}>閉じる</Button>
                </div>
            </div>
        </Modal>


    )
};
