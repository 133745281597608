import {
    SITE,
    INDEX,
    GROUP,
    USERS,
    USER,
    ORDER,
    POINT,
    SELECTED_COMPANY,
    PLACEMENT_POINT,
    TAB,
    ROUTE_NAME,
    LOADING,
    ERROR,
    SITE_TAB,
    WORKER,
    WORK_CONTENT,
    HEAVY_MACHINE,
    LICENSE,
    HEALTH_MANAGEMENT,
    HEAVY_MACHINE_POINT, MANAGEMENT_HISTORY
} from "./actionType";
import {safari} from "leaflet/src/core/Browser";

export function reducer(state, action) {
    switch (action.type) {
        case SITE:
            return {...state, site:action.payload};
        case INDEX:
            return {...state, index:action.payload}
        case GROUP:
            return {...state, group:action.payload}
        case USERS:
            return {...state, users:action.payload}
        case USER:
            return {...state, user:action.payload}
        case ORDER:
            return {...state, order:action.payload}
        case SELECTED_COMPANY:
            return {...state, selected_company:action.payload}
        case POINT:
            return {...state, point:action.payload}
        case PLACEMENT_POINT:
            return {...state, placement_point: action.payload}
        case TAB:
            return {...state, tab:action.payload}
        case ROUTE_NAME:
            return {...state, route_name:action.payload}
        case LOADING:
            return {...state, loading: action.payload}
        case ERROR:
            return {...state, error: action.payload}
        case SITE_TAB:
            return {...state, site_tab:action.payload}
        case WORKER:
            return {...state, worker:action.payload}
        case WORK_CONTENT:
            return {...state, work_content:action.payload}
        case HEAVY_MACHINE:
            return {...state, heavy_machine: action.payload}
        case LICENSE:
            return {...state, license: action.payload}
        case HEALTH_MANAGEMENT:
            return {...state, health_management: action.payload}
        case HEAVY_MACHINE_POINT:
            return {...state, heavy_machine_point: action.payload}
        case MANAGEMENT_HISTORY:
            return {...state, management_history: action.payload}
        default:
            throw new Error();
    }
}

