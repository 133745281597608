import axios from "axios";

let instance = axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})
const url = ''
export const getSite = ()=>axios.get(url+"/home/getSiteList").then(res=>res.data)
export const getGroup = ()=>axios.get(url+"/mainte/getGroup").then(res=>res.data)
export const getUsers = ()=>axios.get(url+"/mainte/getUser").then(res=>res.data)
export const getWorkers =()=>axios.post(url+"/mainte/getWorker").then(res=>res.data)
export const getAuthUser = ()=>axios.get(url+"/home/getAuthUser").then(res=>res.data)
export const getOrderList = (id)=>axios.get(url+"/home/getOrderList/"+id).then(res=>res.data)
export const getPlanPointList = (id)=>axios.get(url+"/home/getPlanPointList/"+id).then(res=>res.data)
export const getDriver = (id)=>axios.get(url+"/home/getDriverList/"+id).then(res=>res.data)
export const getTruck = (id)=>axios.get("/home/getTruckList/"+id).then(res=>res.data)
export const getTruckLoading = (id)=>axios.get("/home/getTruckLoadingList/"+id).then(res=>res.data)
export const moveTruckLoading = (id)=>axios.get("/home/moveTruckLoading/"+id).then(res=>res.data)
export const getMaterialInfo = (id)=>axios.get("/home/getPlacementMaterialInfo/"+id).then(res=>res.data)
export const getMemoList = (data)=>axios.post("/home/getMemoList", data).then(res=>res.data)
export const getPlacementPointInfo = (id)=>axios.post("/home/getPlacementPointInfo/"+id).then(res=>res.data)
export const getRoutePointName = (id)=>axios.post(`/home/getRoutePoints/${id}`).then(res=>res.data)
export const getTruckLoadingInfo = (id)=>axios.post('/home/getTruckLoadingInfo/'+id).then(res=>res.data)
export const getHealthManagement = ()=>axios.post("/mainte/getHealthManagement").then(res=>res.data)
export const getWorkContent = ()=>axios.post("/mainte/getWorkContent").then(res=>res.data)
export const getHeavyMachinery = ()=> axios.post("/mainte/getHeavyMachinery").then(res=>res.data)
export const getLicense = ()=>axios.post("mainte/getLicense").then(res=>res.data)
export const getHeavyMachineryPoints = (id )=>axios.post("/home/getHeavyMachineryPoints/"+id).then(res=>res.data)
export const getSiteManagementHistories = (data)=>instance.post("/home/getSiteManagementHistories", data).then(res=>res.data)
export const getSelectOptions = (data) => instance.post("/work-plan/getSelectOptions", data).then(res=>res.data)
export const getSelectOptionsSafety = (data) => instance.post("/ky-safe-work-instruction/getSelectOptions", data).then(res=>res.data)
export const getMachineryPoints = (data)=>instance.post("/work-plan/getHeavyMachineryPoints",data).then(res=>res.data)
export const getHealthWorkers = (data)=>instance.post("/health-management-report/getWorkers", data).then(res=>res.data)
export const getHealthSelectOptions = (data)=> instance.post("/health-management-report/getSelectOptions", data).then(res=>res.data)


export const editSite = (data)=>axios.post("/home/updateSite", data).then(res=>res.data)
export const addSite = (data)=>axios.post("/home/addSite", data).then(res=>res.data)
export const deleteSite = (id)=>axios.post("/home/removeSite/"+id).then(res=>res.data)


export const addGroup = (data)=>axios.post("/mainte/addGroup", data).then(res=>res.data)
export const editGroup = (data)=>axios.post("/mainte/renameGroup", data).then(res=>res.data)
export const deleteGroup = (id)=>axios.post("/mainte/removeGroup/"+id).then(res=>res.data)


export const addUser = (data)=>axios.post("/mainte/addUser", data).then(res=>res.data)
export const editUser = (data)=>axios.post("/mainte/updateUser", data).then(res=>res.data)
export const deleteUser = (id)=>axios.post("/mainte/removeUser/"+id).then(res=>res.data)

export const addWorker = (data)=>instance.post("/mainte/addworker", data).then(res=>res.data)
export const updateWorker = (data)=>instance.post("/mainte/updateworker", data).then(res=>res.data)
export const removeWorker = (id)=>axios.post("/mainte/removeWorker/"+id).then(res=>res.data)


export const addHeavyMachine = (data)=>instance.post("/mainte/addHeavyMachinery", data).then(res=>res.data)
export const updateHeavyMachine = (data)=>instance.post("/mainte/updateHeavyMachinery", data).then(res=>res.data)
export const removeHeavyMachine = (id)=>axios.post("/mainte/removeHeavyMachinery/"+id).then(res=>res.data)


export const addWorkContent = (data)=>instance.post("/mainte/addWorkContent", data).then(res=>res.data)
export const updateWorkContent = (data)=>instance.post("/mainte/updateWorkContent", data).then(res=>res.data)
export const removeWorkContent = (id)=>axios.post("/mainte/removeWorkContent/"+id).then(res=>res.data)

export const addLicense = (data)=>instance.post("mainte/addLicense", data).then(res=>res.data)
export const updateLicense = (data)=>instance.post("mainte/updateLicense", data).then(res=>res.data)
export const removeLicense = (id)=>axios.post("mainte/removeLicense/"+id).then(res=>res.data)

export const addHealthManagement = (data)=>instance.post("mainte/addHealthManagement", data).then(res=>res.data)
export const updateHealthManagement = (data)=>instance.post("mainte/updateHealthManagement", data).then(res=>res.data)
export const removeHealthManagement = (id, timing, name)=>
    axios.post(`mainte/removeHealthManagement/${id}/${timing}/${name}`).then(res=>res.data)

export const addOrder = (data)=>axios.post("/home/inputOrder", data).then(res=>res.data)
export const deleteOrder = (id)=>axios.post("/home/removeOrder/"+id).then(res=>res.data)


export const inputTruck = (data)=>axios.post("/home/inputTruck", data).then(res=>res.data)
export const deleteTruck = (id)=>axios.post("/home/removeTruck/"+id).then(res=>res.data)

export const addTruckLoading = (data)=>axios.post('/home/inputTruckLoading', data).then(res=>res.data)
export const deleteTruckLoading = (truck_id, loader_id)=>axios.post(`/home/removeTruckLoading/${truck_id}/${loader_id}`).then(res=>res.data)

export const addHeavyMachineryPoint = (data)=>instance.post("/home/addHeavyMachineryPointCustom",data).then(res=>res.data)
export const updateHeavyMachineryPointName = (data)=>instance.post("/home/updateHeavyMachineryPointNameCustom", data).then(res=>res.data)
export const removeHeavyMachineryPointById = (id)=>axios.post("/home/removeHeavyMachineryPointById/"+id).then(res=>res.data)

export const addMemo = (data)=>axios.post("/home/addMemo", data).then(res=>res.data)


export const updateMaterialInfoStatus = (data)=>axios.post("/home/updateMaterialInfoStatus",data).then(res=>res.data)

export const removeMapFile = (id)=>axios.post("/home/removeMapfile/"+id).then(res=>res.data)


export const addPlanPoint = (data)=>axios.post("/home/addPlanPoint", data).then(res=>res.data)
export const editPlanPoint = (data)=>axios.post("/home/updatePlanPointName", data).then(res=>res.data)
export const deletePlanPoint = (id)=>axios.post(`/home/removePlanPointById/${id}`).then(res=>res.data)

export const removeRoadFile = (id)=>axios.post(`/home/removeRoadfile/${id}`).then(res=>res.data)
export const addRoutePoint = (data)=>axios.post("/home/addRoutePoint", data).then(res=>res.data)
export const editRoutePoint = ({id, name})=>axios.post(`/home/updateRoutePointName/${id}/${name}`).then(res=>res.data)
export const removeRoutePointById = ({siteId, id})=>axios.post(`/home/removeRoutePointById/${id}/${siteId}`).then(res=>res.data)
export const updatePlanPointLatLng = (data)=>axios.post(`/home/updatePlanPointLatLng`, data).then(res=>res.data)

export const updateRoutePointLatLng = (data)=>axios.post(`/home/updateRoutePointLatLng`, data).then(res=>res.data)
export const updateHeavyMachineryPointLatLon = (data)=>instance.post("/home/updateHeavyMachineryPointLatLonCustom", data).then(res=>res.data)


export const addKySafeWorkInstruction = (data)=>instance.post("/ky-safe-work-instruction/addKySafeWorkInstruction",
    data).then(res=>res.data)

export const addOrUpdateHealthManagementReports =
    (data)=>instance.post("/health-management-report/addOrUpdateHealthManagementReports", data).then(res=>res.data)

export const addWorkPlan = (data)=>instance.post("/work-plan/addWorkPlan", data).then(res=>res.data)

export const removeHealthManagementReport = (data)=>instance.post("/home/removeHealthManagementReport", data).then(res=>res.data)