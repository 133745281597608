import React, {useReducer, createContext} from "react";
import {reducer} from "./store";
import {initialState} from "./state";

export const Store = createContext();


export default function(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };
    return (
        <Store.Provider value={value}>{props.children}</Store.Provider>
    );
}
