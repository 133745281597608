import React, {useContext, useEffect, useState} from 'react'
import addition from '../../asset/addition.png'
import edition from '../../asset/edit.png'
import deletion from '../../asset/deletion.png'
import {Checkbox, Divider} from "antd";
import {Store} from "../../state_store/provider";
import {getSite, getUsers, getWorkContent, getWorkers} from "../../api";
import worker from '../../asset/worker.png'
import {INDEX, SITE, USERS, WORK_CONTENT, WORKER} from "../../state_store/actionType";
import TableIconButton from "../../component/table_icon_button";
import WorkerRegistration from "../../component/worker_registration";
import WorkerEdition from "../../component/worker_edition";
import WorkerDelete from "../../component/worker_delete";




const join_string =(obj)=>{
    let l = obj.map(res=>res.name)
    return [...new Set(l)].join("/")
}


export default function WorkerList(){

    const {state, dispatch} = useContext(Store)
    const [openAddition, setOpenAddition] = useState(false)
    const [openEdition, setOpenEdition] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [selected, setSelected] = useState({})
    const [worker, setWorker] = useState([])
    useEffect(()=>{

        getSite().then(res=>{
            if(res.result === 0){
                dispatch({type:SITE, payload:res.sites})
            }
        })
        getWorkers().then(res=>{
            if(res.result ===0){
                dispatch({type:WORKER, payload:res.workerList})
            }
        })
        getWorkContent().then(res=>{
            if(res.result === 0){
                dispatch({type:WORK_CONTENT, payload:res.workContentList})
            }
        })
        getUsers().then(res=> {
            if(res.result === 0)
                dispatch({type: USERS, payload: res.userList})


        })
    },[])

    const onOK =()=>{
        setSelected({})
        setOpenDelete(false)
        setOpenAddition(false)
        setOpenEdition(false)
    }

    return(
        <div className={"user-list worker-list"}>
            <div className={"wrapper"}>
                <div className={"header row"}>
                    <div className={"row center"}>
                        <div className={"icon"}><img src={worker} width={12}  alt={""}/></div>
                        <div className={"title"}>作業者登録</div>
                    </div>
                    <div className={"row center"}>
                        <TableIconButton icon={addition} title={"追加"}
                                         selected={openAddition}
                                         onClick={()=>setOpenAddition(true)}/>
                        <TableIconButton icon={edition} title={"編集"}
                                         selected={openEdition}
                                         onClick={()=>setOpenEdition(!!selected.id)}
                        />

                        <TableIconButton icon={deletion} title={"削除"}
                                         selected={openDelete}
                                         onClick={()=>setOpenDelete(!!selected.id)}
                        />
                    </div>
                </div>

                <Divider className={"divider"}/>
            </div>

            <div className={"column table-wrapper"}>
                <div className={"row g-worker tb-h"}>
                    <div>ログインID</div>
                    <div>ユーザー名</div>
                    <div>パスワード</div>
                    <div>メールアドレス</div>
                    <div>⽣年⽉⽇</div>
                    <div>グループ名</div>
                    <div>作業分類</div>
                    <div>現場</div>
                    <div>運転者</div>
                    <div>作業指揮者</div>
                    <div>合図者</div>
                    <div>誘導管理者</div>
                    <div>資格情報</div>
                </div>
                {state.worker.map(res=>(
                    <div className={`row g-worker tb-li sbd pointer ${res.id===selected.id?"row-active":""}`} key={res.id}

                         onClick={()=>setSelected(res)}
                    >
                        <div className={"text-wrapper"}>{res.id}</div>
                        <div className={"text-wrapper"}>{res.name}</div>
                        <div className={"text-wrapper"}>{res.password}</div>
                        <div className={"text-wrapper"}>{res.mail}</div>
                        <div className={"text-wrapper"}>{res.birthday}</div>
                        <div className={"text-wrapper"}>{res.group_name}</div>
                        <div className={"text-wrapper"}>{res.work_class}</div>
                        <div className={"text-wrapper"}>{join_string(res.sites)}</div>
                        <div className={"v-center"}><Checkbox checked={res.driver}/></div>
                        <div className={"v-center"}><Checkbox checked={res.work_conductor}/></div>
                        <div className={"v-center"}><Checkbox checked={res.signaler}/></div>
                        <div className={"v-center"}><Checkbox checked={res.guide_manager}/></div>
                        <div className={"text-wrapper"}>{join_string(res.licenses)}</div>
                    </div>
                ))}



            </div>

            <WorkerRegistration open={openAddition}
                              onOK={()=>setOpenAddition(false)}
                              onClose={()=>setOpenAddition(false)}
            />


            <WorkerEdition  open={openEdition}
                          onOK={onOK}
                          onClose={()=>setOpenEdition(false)}
                          data={selected}
            />

            <WorkerDelete  open={openDelete}
                         onOK={onOK}
                         onClose={()=>setOpenDelete(false)}
                         data={selected}
            />



        </div>
    )

}



