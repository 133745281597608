import React, {useContext, useEffect, useState} from 'react'
import addition from '../../asset/addition.png'
import edition from '../../asset/edit.png'
import deletion from '../../asset/deletion.png'
import qualification from '../../asset/qualification.png'
import {Divider} from "antd";
import {Store} from "../../state_store/provider";
import {getLicense, getUsers} from "../../api";
import {LICENSE, USERS} from "../../state_store/actionType";
import TableIconButton from "../../component/table_icon_button";
import QualificationRegistration from "../../component/qualification_registration";
import QualificationEdition from "../../component/qualification_edition";
import QualificationDelete from "../../component/qualification_delete";




export default function Qualification(){

    const {state, dispatch} = useContext(Store)
    const [openAddition, setOpenAddition] = useState(false)
    const [openEdition, setOpenEdition] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [selected, setSelected] = useState({})
    useEffect(()=>{
        getLicense().then(res=> {
            if(res.result === 0)
                dispatch({type: LICENSE, payload: res.licenseList})
        })
    },[])


    const onOK =()=>{
        setSelected({})
        setOpenDelete(false)
        setOpenAddition(false)
        setOpenEdition(false)
    }
    return(
        <div className={"user-list"}>
            <div className={"wrapper"}>
                <div className={"header row"}>
                    <div className={"row center"}>
                        <div className={"icon"}><img src={qualification} width={16}  alt={""}/></div>
                        <div className={"title"}>資格登録</div>
                    </div>
                    <div className={"row center"}>
                        <TableIconButton icon={addition} title={"追加"}
                                         selected={openAddition}
                                         onClick={()=>setOpenAddition(true)}/>
                        <TableIconButton icon={edition} title={"編集"}
                                         selected={openEdition}
                                         onClick={()=>setOpenEdition(!!selected.id)}
                        />

                        <TableIconButton icon={deletion} title={"削除"}
                                         selected={openDelete}
                                         onClick={()=>setOpenDelete(!!selected.id)}
                        />
                    </div>
                </div>

                <Divider className={"divider"}/>
            </div>

            <div className={"column table-wrapper"}>
                <div className={"row g-qualification tb-h"}>
                    <div>資格名</div>
                    <div>対象作業内容</div>


                </div>
                {state.license.map((res, index)=>(
                    <div className={`row sbd g-qualification pointer tb-li ${res.id===selected.id?"row-active":""}`} key={res.id}

                         onClick={()=>setSelected(res)}
                    >
                        <div className={"text-wrapper"}>{res.name}</div>
                        <div className={"text-wrapper"}>{res.work_content_name}</div>
                    </div>
                ))}

            </div>

            <QualificationRegistration open={openAddition}
                                     onOK={onOK}
                                     onClose={()=>setOpenAddition(false)}
            />


            <QualificationEdition open={openEdition}
                                onOK={onOK}
                                onClose={()=>setOpenEdition(false)}
                                data={selected}
            />

            <QualificationDelete open={openDelete}
                               onOK={onOK}
                               onClose={()=>setOpenDelete(false)}
                               data={selected}
            />

        </div>
    )

}



