import React, {useContext} from 'react';
import {Modal, Button} from 'antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {deletePlanPoint, getPlanPointList} from "../api";
import {Store} from "../state_store/provider";
import {POINT} from "../state_store/actionType";
import {Request} from "../utils";

export default function PointDeletion(props) {
    const {open, onClose, id} = props
    const {state, dispatch} = useContext(Store)
    const onDelete = ()=>{
        const siteId = state.site[state.index].id
        Request(dispatch, deletePlanPoint(id), onClose).then(error=>{
            if(!error){
                getPlanPointList(siteId).then(res=>{
                    dispatch({type: POINT, payload:res.plan_points})

                })
            }
        })
        // deletePlanPoint(id).then(res=>{
        //     if (res.result === 0){
        //         getPlanPointList(siteId).then(res=>{
        //             dispatch({type: POINT, payload:res.plan_points})
        //             onClose()
        //         })
        //     }
        // })
    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>

                <div className={"delete-dialog-body column center"}>
                    <div className={"icon"}>
                        <ExclamationCircleOutlined/>
                    </div>
                    <div className={"title"}>削除</div>
                    <div className={"subtitle"}>
                        選択している搬⼊計画点を削除します。
                    </div>
                    <div className={"subtitle"}>よろしいですか？</div>

                </div>
                <div className={"delete-dialog-footer row center"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={onDelete}
                        type="primary" style={{backgroundColor:"#377EF3"}}>削除する</Button>
                </div>
            </div>
        </Modal>
    );
};
