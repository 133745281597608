import React, {useContext, useEffect, useState} from 'react'
import addition from '../../../asset/addition.png'
import edition from '../../../asset/edit.png'
import deletion from '../../../asset/deletion.png'
import position from '../../../asset/position.png'
import drawing from '../../../asset/drawing.png'
import { Divider} from "antd";
import Trash from '../../../asset/trash.png'
import Map from '../../../component/map'
import MapDelete from "../../../component/map_delete";
import {Store} from "../../../state_store/provider";
import axios from "axios";
import {
    HEAVY_MACHINE, HEAVY_MACHINE_POINT,
    PLACEMENT_POINT,
    POINT,
    ROUTE_NAME,
    SITE_TAB,
    WORK_CONTENT
} from "../../../state_store/actionType";
import PointAddition from "../../../component/point_addition";
import OrderAddition from "../../../component/order_addition";
import PointEdition from "../../../component/point_edition";
import PointDeletion from "../../../component/point_delete";
import RoadFileDeletion from "../../../component/road_file_deletion";
import RoadAddition from "../../../component/road_addition";
import RoadDialog from "../../../component/road_dialog";
import RoadEdition from "../../../component/road_edition";
import RoadDeletion from "../../../component/road_deletion";
import {
    getHeavyMachinery, getHeavyMachineryPoints,
    getPlacementPointInfo,
    getPlanPointList,
    getRoutePointName,
    getWorkContent
} from "../../../api";
import MachineRegistration from "../../../component/machine_registration";
import MachineEdition from "../../../component/machine_edition";
import MachineDelete from "../../../component/machine_delete";
function TableIconButton({icon, title, onClick, size, styles}){
    return (
        <div className={"column center company-icon-btn"}
             style={{...styles}}
             onClick={onClick}
        >
            <img src={icon} alt={"no"} style={{width:size??size, height:size??size}}/>
            <div className={"btn-title"}>{title}</div>
        </div>

    )
}

function RoutButton(props){
    const {title,active, ...res} = props
    return (<div {...res} className={"route-btn " + (active?"active-route":"")}>
        {title}
    </div>)
}
const route_list = ["現場図⾯", "搬⼊位置", "搬⼊経路", "搬⼊経路位置", "作業重機位置"]
const initState = {state:false, open:false, data:{}}
export default function SiteInfo(){
    const [openDeleteMap, setOpenDeleteMap]= useState(false)
    const [markerState, setMarkerState] = useState(false)
    const [addPoint ,setAddPoint] = useState({state:false, open:false, data:{}})
    const [editPoint, setEditPoint] = useState({state:false, open:false, id:undefined})
    const [deletePoint, setDeletePoint] = useState(false)
    const [openOrder, setOpenOrder] = useState({open:false, pid:undefined})
    const [openRoad, setOpenRoad] = useState(false)
    const [roadState, setRoadState] = useState(false)
    const [roadView, setRoadView] = useState({open:false, data:undefined})
    const [markerRoad, setMarkerRoad] = useState(false)
    const [roadAddition, setRoadAddition] = useState({open:false, data:{}})
    const [roadEdition, setRoadEdition] = useState({open:false, data:undefined})
    const [roadDeletion, setRoadDeletion] = useState({open:false, id:undefined})
    const [addMachine, setAddMachine] = useState(initState)
    const [editMachine, setEditMachine] = useState(initState)

    const [openMachine, setOpenMachine] = useState(false)

    const [machineDeletion, setMachineDeletion] = useState(initState)

    const {state, dispatch} = useContext(Store)
    const {site, index, site_tab} = state
    const site_id = site[index]?.id
    useEffect(()=>{
        if(site_id){
            getPlanPointList(site_id).then(res => {
                dispatch({type: POINT, payload: res.plan_points})
            })

            getHeavyMachineryPoints(site_id).then(res=>{
                if(res.result === 0){
                    dispatch({type:HEAVY_MACHINE_POINT, payload:res.heavyMachineryPoints})
                }
            })
        }

    },[site_id])

    useEffect(()=>{
        setAddMachine(initState)
        setAddPoint(initState)
    }, [site_tab])

    useEffect(()=>{
        getHeavyMachinery().then(res=>{
            if(res.result === 0){
                dispatch({type:HEAVY_MACHINE, payload:res.heavyMachineryList})
            }
        })
        getWorkContent().then(res=>{
            if(res.result === 0){
                dispatch({type:WORK_CONTENT, payload:res.workContentList})
            }
        })

    }, [])

    const onRouteChange = (index)=>{
        dispatch({type:SITE_TAB, payload:index})
    }



    const uploadMap = (e)=>{
        const files = e.dataTransfer.files;
        let bodyForm = new FormData()
        if(files.length === 1){
            const file_extension = files[0].name.split(".").pop()
            if(file_extension.toLowerCase() === "kml" ){
                bodyForm.append("site_id", site_id)
                let totalSize = 4;
                let fileLength = files.length;
                for (let i = 0; i < fileLength; i++) {
                    bodyForm.append("files[]", files[i]);
                    totalSize += files[i].size;
                }
                axios({
                    method: "post",
                    url:"/home/uploadMapfile",
                    data:bodyForm,
                    headers: { "Content-Type": "multipart/form-data" }

                }).then(res=>{
                    if(res.data.result === 0){
                        // dispatch({type:INDEX, payload:index})
                        getPlacementPointInfo(site_id).then(placement=> {
                                if (placement.result === 0) {

                                    fetch(
                                        "/mapfiles/" + placement.mapfile
                                    )
                                        .then((res) => res.text())
                                        .then((kmlText) => {
                                            const parser = new DOMParser();
                                            const kml = parser.parseFromString(kmlText, "text/xml");
                                            let long = parseFloat(kml.getElementsByTagName("longitude")[0].childNodes[0].data)
                                            let lat = parseFloat(kml.getElementsByTagName("latitude")[0].childNodes[0].data)
                                            dispatch({
                                                type: PLACEMENT_POINT,
                                                payload: {...placement, center: [lat, long], kml: kmlText}
                                            })

                                        }).catch(error => {
                                        dispatch({type: PLACEMENT_POINT, payload: {...placement, error: true}})
                                    });
                                }
                            }
                        )
                    }
                })
            }

        }

    }

    const uploadImageRoute = (e)=>{
        const files = e.dataTransfer.files;
        let bodyForm = new FormData()
        const {routePointId} = roadEdition.data.target
        if(files.length === 1){
            const file_extension = files[0].name.split(".").pop()
            if(["jpg", "png", "giff", "jpeg", "svg", "ico"].includes(file_extension.toLowerCase())){
                let totalSize = files[0].size + 4
                bodyForm.append("files[]", files[0])
                bodyForm.append("site_id", site_id)
                bodyForm.append("id", routePointId)

                axios({
                    method: "post",
                    url:"/home/uploadRoutefile",
                    data:bodyForm,
                    headers: { "Content-Type": "multipart/form-data" }
                }).then(res=>{

                    if( res.data.result ===0){
                        getRoutePointName(site_id).then(res=>{
                            if(res.result === 0)
                                dispatch({type:ROUTE_NAME, payload:res.routePoints})

                        })
                    }
                })

            }
        }
    }

    const renderMenuBtn = ()=>{
        switch (state.site_tab) {
            case 0:
                return(
                    <div className={"row"}>
                        <TableIconButton icon={Trash} title={"クリア"} onClick={()=>setOpenDeleteMap(true)}/>
                    </div>
                )
            case 1:
                return(
                    <div className={"row"}>
                        <TableIconButton icon={addition} title={"注⽂追加"} onClick={
                            ()=>{
                                setAddPoint({...addPoint, state:false})
                                if(openOrder.pid){
                                    setOpenOrder({...openOrder, open: true})
                                }
                            }
                        }

                        />
                        <TableIconButton icon={addition} title={"追加"}
                                         styles={addPoint.state?{backgroundColor: "#F0F1F3"}:{}}
                                         onClick={()=>setAddPoint({...addPoint, state:true})}/>
                        <TableIconButton icon={edition} title={"編集"}
                                         onClick={()=>{
                                             if(editPoint.state){
                                                 setEditPoint({...editPoint, open: true})
                                             }
                                         }}

                        />
                        <TableIconButton icon={deletion} title={"削除"}

                                         onClick={()=>{
                                             if(markerState.planPointId){
                                                 setDeletePoint(true)
                                             }
                                         }}

                        />
                    </div>
                )
            case 2:
                return(
                    <div className={"row"}>
                        <TableIconButton icon={Trash} title={"クリア"} onClick={()=>setOpenRoad(true)}/>
                    </div>
                )
            case 3:
                return(


                <div className={"row"}>
                    <TableIconButton icon={addition} title={"追加"}
                                     styles={roadState?{backgroundColor: "#F0F1F3"}:{}}
                                     onClick={()=>setRoadState(true)}
                    />
                    <TableIconButton icon={edition} title={"編集"}

                        onClick={()=>{
                            if(markerRoad){
                                setRoadEdition({...roadEdition, open: true})
                            }
                        }}
                    />
                    <TableIconButton icon={deletion} title={"削除"}
                    onClick={()=>{
                        if(markerRoad){
                            setRoadDeletion({...roadDeletion, open: true})
                        }
                    }}
                    />
                    <TableIconButton icon={position} title={"⽬印"}
                        onClick={()=>{
                            if(markerRoad){
                                setRoadView({...roadView, open: true})
                            }
                        }}
                    />
                </div>
                )
            case 4:
                return (
                    <div className={"row"}>
                        <TableIconButton icon={addition} title={"追加"}

                            onClick={()=>{
                                setAddMachine(prevState =>
                                    ({...prevState, state: true})
                                )
                            }}
                        />
                        <TableIconButton icon={edition} title={"編集"}
                            onClick={()=>{
                                if(editMachine.state){
                                    setEditMachine(prevState => ({...prevState, open: true}))
                                }
                            }}
                        />
                        <TableIconButton icon={deletion} title={"削除"}

                                         onClick={()=>{
                                             if(machineDeletion.state){
                                                 setMachineDeletion(prevState => ({...prevState, open:true}))
                                             }
                                         }}/>
                    </div>
                )

        }
    }
    return(
        <div>
            <div className={"route-layout"}>

                {route_list.map((res, index)=>{
                    return <RoutButton title={res} key={index}
                                       active={index===site_tab}
                                       onClick={()=>onRouteChange(index)}/>
                })}

            </div>
        <div className={"site-info"}>
            <div className={"wrapper"}>
                <div className={"header row"}>
                    <div className={"row center"}>
                        <div className={"icon"}>
                            <img src={drawing} alt={""} width={22}/>
                        </div>
                        <div className={"title"} style={{fontSize:16}}>{route_list[site_tab]}</div>
                        <div className={"small-text"}>
                            {site_tab===0?"※地図が表⽰されていない場合はKMLマップファイルをドラッグ&ドロップしてください。":""}
                            {addPoint.state?"地図上の追加する搬入位置をクリックしてください":""}
                            {addMachine.state?"地図上の追加する作業重機位置をクリックしてください。":""}
                        </div>
                    </div>
                    {renderMenuBtn()}

                </div>

                <Divider className={"divider"}/>


            </div>
            <div className={"content"} id={"site_container"} onDrop={(e)=>{
                e.preventDefault()
                if(state.site_tab===0){
                    uploadMap(e)
                }
                else if(state.site_tab === 3){
                    if(markerRoad){
                        uploadImageRoute(e)
                    }
                }


            }}
            onDragOver={(e)=>{

                e.preventDefault();
            }}
            >
                <Map id={"site_map"}
                            active={site_tab}
                            onMarkerClick={(ev)=>{
                                setOpenOrder({...openOrder, pid:ev.target.planPointId, open: false})
                                setEditPoint({...editPoint, id:ev.target.planPointId, state:true, open: false})
                                setMarkerState(ev.target)
                            }
                            }

                            onRoadDbClick={(e)=>setRoadView({open: true, data: e})}
                            onRoadClick={(e)=>{
                                setRoadView({...roadView, data:e})
                                setRoadEdition({open: false, data:e})
                                setRoadDeletion({open: false, id: e.target.routePointId})
                                setMarkerRoad(true)
                            }
                            }

                            onMachinePointClick={(ev)=>{
                                setMachineDeletion(prevState => ({...prevState, state:true, data:ev}))
                                setEditMachine(prevState => ({...prevState, state:true, data:ev}))
                            }}

                            onClick={(e)=>
                            {
                                if(addPoint.state)
                                setAddPoint({...addPoint, open:true, data:e.latlng})
                                if(roadState)
                                    setRoadAddition({data: e.latlng, open: true})
                                if(addMachine.state)
                                    setAddMachine(prevState => ({...prevState, open:true, data:e.latlng}))

                            }}
                            container_id={"site_container"}/>







            </div>
        </div>
            <MapDelete open={openDeleteMap} onClose={()=>setOpenDeleteMap(false)}
                       onOK={()=>setOpenDeleteMap(false)}/>
            <PointAddition open={addPoint.open}
                           data={addPoint.data}
                           onClose={()=>setAddPoint({state: false, open: false, data:{}})}
                           onOK={()=>()=>setAddPoint({state: false, open: false, data:{}})}
            />

            <PointEdition open={editPoint.open}
                          id={markerState.planPointId}
                          onClose={()=>setEditPoint({...editPoint, open:false})}
                          onOK={()=>()=>setEditPoint({...editPoint, open:false})}
            />

            <OrderAddition open={openOrder.open}
                           pid={markerState.planPointId}
                           onClose={()=>setOpenOrder({...openOrder, open: false})}
                           onOK={()=>setOpenOrder({...openOrder, open: false})}
            />

            <PointDeletion open={deletePoint}
                           id={markerState.planPointId}
                           onClose={()=>setDeletePoint(false)}
                           onOK={()=>setDeletePoint(false)}
            />
            <RoadFileDeletion open={openRoad}
                              onClose={()=>setOpenRoad(false)}
                              onOK={()=>setOpenRoad(false)}

            />

            <RoadAddition open={roadAddition.open}
                          data={roadAddition.data}
                          onClose={()=>{
                              setRoadAddition({open: false, data: {}})
                              setRoadState(false)
                          }}

            />
            <RoadDialog open={roadView.open}
            onClose={()=>{
                setRoadView({open: false})
                setMarkerRoad(false)
            }}
                        data={roadView.data}
            />
            <RoadEdition
                open={roadEdition.open}
                data={roadEdition.data}
                onClose={()=>{
                    setRoadEdition({data:undefined, open: false})
                    setMarkerRoad(false)
                }}

            />
            <RoadDeletion
                {...roadDeletion}
                onClose={()=>setRoadDeletion({open:false, id: undefined})}
            />

            <MachineRegistration
                {...addMachine}
                onOK={()=>setAddMachine(initState)}
                onClose={()=>setAddMachine(prevState => ({...prevState, open: false}))}
            />

            <MachineEdition
                {...editMachine}
                onOK={()=>setEditMachine(initState)}
                onClose={()=>setEditMachine(prevState => ({...prevState, open: false}))}
            />
            <MachineDelete
                {...machineDeletion}
                onOK={()=>setMachineDeletion(initState)}
                onClose={()=>setMachineDeletion(prevState => ({...prevState, open: false}))}
            />
        </div>
    )

}


