import React, {useContext, useEffect, useState} from 'react'
import {Button, DatePicker, Divider, Input, Select} from "antd";
import OutputIcon from '../asset/output.png'
import {Option} from "antd/es/mentions";
import {Store} from "../state_store/provider";
import {getUsers} from "../api";
import {USERS} from "../state_store/actionType";
import {ajaxGetLog} from "../utils/csv";
import moment from "moment";


export default function Work(){
    const {state, dispatch} = useContext(Store)
    const [data, setData] = useState({})

    useEffect(()=>{
        if(!state.users.length){
            getUsers().then(res=> {
                if(res.result === 0)
                    dispatch({type: USERS, payload: res.userList})
            })
        }
    },[])

    const onChange = (name, value)=>{
            setData({...data, [name]:value})
    }

    const onSubmit = ()=>{
        let {timeFrom, timeTo} = data
        if(timeFrom){
            timeFrom = moment(timeFrom).format("YYYY-MM-DDTHH:mm")
        }
        if(timeTo){
            timeTo = moment(timeTo).format("YYYY-MM-DDTHH:mm")
        }
        ajaxGetLog({...data, timeTo, timeFrom})
    }


    return(
        <div className={"work system-setting"}>

            <div className={"wrapper column"}>
                <div>


                <div className={"header row"}>
                    <div className={"row center"}>
                        <div className={"icon"}><img src={OutputIcon} width={13} /></div>
                        <div className={"title"}>計測ログ出⼒画⾯</div>
                    </div>
                    <div/>
                </div>
                <div className={"small-text"}>
                    この画⾯では計測したログをCSV出⼒します。
                </div>
                <Divider className={"divider"}/>

                <div className={"input-title"}>計測ログ出⼒条件</div>

                <div className={"input-wrapper"}>
                    <div className={"input-label"}>ユーザー</div>
                    <Select value={data.userId} className={"select-field"}
                            onChange={(id)=>onChange("userId", id)}
                    >
                        {state.users.map((item, index)=>(
                            <Option value={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                </div>
                <div className={"row center datetime-input"}>
                    <div className={"input-wrapper"}>
                        <div className={"input-label"}>計測時刻</div>
                        <DatePicker showTime
                                    format="YYYY-MM-DD HH:mm"
                                    onChange={(date, dateString)=>onChange("timeFrom", dateString)} onOk={(e)=>console.log(e)}
                            placeholder={"計測時刻"}
                        />
                    </div>
                    <div className={"connect"}>~</div>
                    <div className={"input-wrapper"}>
                        <DatePicker showTime
                                    format="YYYY-MM-DD HH:mm"
                                    onChange={(date, dateString)=>onChange("timeTo", dateString )} onOk={(e)=>console.log(e)}
                                    placeholder={"計測時刻"}
                        />
                    </div>
                </div>
                <div className={"row center datetime-input"}>
                    <div className={"input-wrapper"}>
                        <div className={"input-label"}>緯度</div>
                        <Input defualtValue={"すべて"} placeholder={"-90 ~ 90"} type={"number"}
                               onChange={({target})=>onChange("latFrom", target.value)}
                        />
                    </div>
                    <div className={"connect"}>~</div>
                    <div className={"input-wrapper"}>
                        <Input defualtValue={"すべて"} placeholder={"-90 ~ 90"} type={"number"}
                                   onChange={({target})=>onChange("latTo", target.value)}
                                   />
                    </div>
                </div>
                <div className={"row center datetime-input"}>
                    <div className={"input-wrapper"}>
                        <div className={"input-label"}>経度</div>
                        <Input defualtValue={"すべて"} placeholder={"-180 ~ 180"} type={"number"}
                               onChange={({target})=>onChange("lonFrom", target.value)}
                        />
                    </div>
                    <div className={"connect"}>~</div>
                    <div className={"input-wrapper"}>
                        <Input defualtValue={"すべて"} placeholder={"-180 ~ 180"} type={"number"}

                        onChange={({target})=>onChange("lonTo", target.value)}
                        />
                    </div>
                </div>
                </div>
                <div className={"row btn"}>
                    <Button type={"primary"} onClick={onSubmit}> CSV出⼒ </Button>
                </div>

            </div>

        </div>

    )
}
