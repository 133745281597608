import React, {useContext, useEffect, useState} from 'react';
import {Modal, Button, Divider, Input, DatePicker, Select} from 'antd';
import {addLicense, getLicense, getWorkContent, inputTruck} from "../api";
import {Store} from "../state_store/provider";
import {Option} from "antd/es/mentions";
import {FormFormat, Request} from "../utils";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yub from "yup";
import {LICENSE, WORK_CONTENT} from "../state_store/actionType";


const schema = yub.object().shape({
    name:yub.string().required(),
    work_content: yub.string().required()
}).required()

export default function QualificationRegistration(props) {
    const {open, onClose, onOK} = props
    const {state, dispatch} = useContext(Store)
    const {register, handleSubmit, formState: {errors}, reset, control} = useForm({
        resolver: yupResolver(schema)
    });


    const onCreateQualification = (value)=>{

        const data_submit = {
            id: "自動採番",
            ...value
        }
            Request(dispatch, addLicense(FormFormat(data_submit)), onOK).then(error=>{
                if(!error){
                    getLicense().then(res=> {
                        if(res.result === 0)
                            dispatch({type: LICENSE, payload: res.licenseList})
                    })
                    reset()
                }
            })

    }

    useEffect(()=>{
        getWorkContent().then(res=>{
            if(res.result === 0){
                dispatch({type:WORK_CONTENT, payload:res.workContentList})
            }
        })
    },[])


    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >


            <div className={"dialog"}>
                <div className={"title"}>資格追加</div>
                <div className={"subtitle"}>追加する資格情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"qualification-registration"} onSubmit={handleSubmit(onCreateQualification)}>
                    <div className={"input-title"}>資格名</div>
                    <input type="text"
                           className={`ant-input ${errors.name ? "error" : ""}`}
                           {...register("name")}/>
                    <div className={"helper-text-error"}>
                        {errors.name?.message}</div>

                    <div className={"input-title"}>対象作業内容</div>
                    <Controller
                        control={control}
                        defaultValue={''}
                        name={"work_content"}
                        render={({field})=>(
                            <Select className={`select-field `}
                                    {...field}
                                    status={errors.work_content?"error":""}
                            >
                                {state.work_content.map((item, index) => (
                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        )}
                    />
                    <div className={"helper-text-error"}>
                        {errors.work_content?.message}</div>
                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        htmlType={"submit"}
                        form={"qualification-registration"}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>

            </div>
        </Modal>
    );
};
