import React, {useContext, useEffect, useState} from 'react'
import addition from '../../asset/addition.png'
import edition from '../../asset/edit.png'
import deletion from '../../asset/deletion.png'
import {Divider} from "antd";
import {Store} from "../../state_store/provider";
import {getUsers} from "../../api";
import AccountIcon from '../../asset/account.png'
import {USERS} from "../../state_store/actionType";
import UserRegistration from "../../component/user_registration";
import {Role} from "../../constant";
import UserEdition from "../../component/user_edition";
import UserDelete from "../../component/user_delete";
import TableIconButton from "../../component/table_icon_button";

export default function UserList(){

    const {state, dispatch} = useContext(Store)
    const [openAddition, setOpenAddition] = useState(false)
    const [openEdition, setOpenEdition] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [selected, setSelected] = useState({})
    useEffect(()=>{
        getUsers().then(res=> {
            if(res.result === 0)
                dispatch({type: USERS, payload: res.userList})


        })
    },[])

    return(
        <div className={"user-list"}>
            <div className={"wrapper"}>
                <div className={"header row"}>
                    <div className={"row center"}>
                        <div className={"icon"}><img src={AccountIcon} width={12} /></div>
                        <div className={"title"}>ユーザー登録</div>
                    </div>
                    <div className={"row center"}>
                        <TableIconButton icon={addition} title={"追加"}
                                         selected={openAddition}
                                         onClick={()=>setOpenAddition(true)}/>
                        <TableIconButton icon={edition} title={"編集"}
                                         selected={openEdition}
                        onClick={()=>setOpenEdition(!!selected.id)}
                        />

                        <TableIconButton icon={deletion} title={"削除"}
                                         selected={openDelete}
                                         onClick={()=>setOpenDelete(!!selected.id)}
                        />
                    </div>
                </div>

                <Divider className={"divider"}/>
            </div>

            <div className={"column table-wrapper"}>
                <div className={"row g-user tb-h"}>
                    <div>ログインID</div>
                    <div>ユーザー名</div>
                    <div>パスワード</div>
                    <div>メールアドレス</div>
                    <div>グループ名</div>
                    <div>権限</div>
                </div>
                {state.users.map(res=>(
                    <div className={`row pointer sbd g-user tb-li ${res.id===selected.id?"row-active":""}`} key={res.id}

                         onClick={()=>setSelected(res)}
                    >
                        <div className={"text-wrapper"}>{res.id}</div>
                        <div className={"text-wrapper"}>{res.name}</div>
                        <div className={"text-wrapper"}>{res.password}</div>
                        <div className={"text-wrapper"}>{res.mail}</div>
                        <div className={"text-wrapper"}>{res.group_name}</div>
                        <div className={"text-wrapper"}>{Role[res.admin_flg]}</div>
                    </div>
                ))}



            </div>

            <UserRegistration open={openAddition}
                onOK={()=>setOpenAddition(false)}
                              onClose={()=>setOpenAddition(false)}
            />
            <UserEdition  open={openEdition}
                          onOK={()=>setOpenEdition(false)}
                          onClose={()=>setOpenEdition(false)}
                          data={selected}
            />

            <UserDelete  open={openDelete}
                         onOK={()=>setOpenDelete(false)}
                         onClose={()=>setOpenDelete(false)}
                         data={selected}
            />

        </div>
    )

}



