import React, {useContext, useState} from 'react';
import {Modal, Button, Divider, Input, DatePicker, Select} from 'antd';
import {addHeavyMachine, addWorker, getHeavyMachinery, getWorkers, inputTruck} from "../api";
import {Store} from "../state_store/provider";
import machine01 from '../asset/machine01.png'
import machine02 from '../asset/machine02.png'
import machine03 from '../asset/machine03.png'
import guide from '../asset/guide.png'
import barricade from '../asset/barricade.png'
import flag from '../asset/flag.png'
import {Option} from "antd/es/mentions";
import Checkbox from "antd/es/checkbox/Checkbox";
import {FormFormat, Request} from "../utils";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yub from "yup";
import {HEAVY_MACHINE, WORKER} from "../state_store/actionType";


const schema = yub.object().shape({
    // id:yub.string().required(),
    name:yub.string().required(),
    image:yub.string().required("vehicle is required")
    // password: yub.string().min(6).required(),
    // email: yub.string().email().required(),
    // admin_flg:yub.string().required("Role is a required field"),
    // carrier_id: yub.string().required("Company is a required field")
}).required()


const machine_name_list = [
    {id: "", name: ""},
    {id: "0", name: "バケット"},
    {id: "1", name: "排土板"},
    {id: "2", name: "その他(ブレーカー)"}
]

const weight_label_list = [
    {id: "", name:""},
    {id: "0", name: "3t以上"},
    {id: "1", name: "3t未満"},
]

export default function HeavyRegistration(props) {
    const {open, onClose, onOK, driver, data} = props
    const [order_data, setOrder_data] = useState({on_the_truck: 0})
    const [error, setError] = useState({submit: false, message:"vehicle is required"})
    const [image, setImage] = useState("")
    const {register, handleSubmit, formState: {errors}, reset, control} = useForm({
        resolver: yupResolver(schema)
    });
    const {state, dispatch} = useContext(Store)
    const {site, index} = state



    const onCreateHeavy = (value)=>{
        const data_submit = {
            id: "自動採番",
            ...value
        }
        Request(dispatch, addHeavyMachine(FormFormat(data_submit)), onOK).then(error=>{
            if(!error){
                getHeavyMachinery().then(res=>{
                    if(res.result === 0){
                        dispatch({type:HEAVY_MACHINE, payload:res.heavyMachineryList})
                    }
                })
                reset()
            }
        })

    }



    return (
        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}>
            <div className={"dialog"}>
                <div className={"title"}>人・資・機材追加</div>
                <div className={"subtitle"}>追加する重機情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"heavy-registration"} onSubmit={handleSubmit(onCreateHeavy)}>
                    <div className={"input-title"}>重機名</div>
                    <input type="text"
                           className={`ant-input ${errors.name?"error":""}`}
                           {...register("name")}/>
                    <div className={"helper-text-error"}>
                        {errors.name?.message}</div>

                    <div className={"input-title"}>作業装置</div>
                    <input type="text"
                           className={`ant-input ${errors.device?"error":""}`}
                           {...register("device")}/>
                    <div className={"helper-text-error"}>
                        {errors.device?.message}</div>



                    <div className={"input-title"}>⾞両系建設機械名</div>
                    <Controller
                        control={control}
                        name={"construction_machine_name"}
                        render={({field})=>(
                            <Select className={`select-field`}
                                    status={errors.construction_machine_name?"error":""}
                                    {...field}
                                // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                            >
                                {machine_name_list.map(item => (
                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        )}
                    />
                    <div className={"helper-text-error"}>
                        {errors.construction_machine_name?.message}</div>


                    <div className={"input-title"}>型式</div>
                    <input type="text"
                           className={`ant-input ${errors.model?"error":""}`}
                           {...register("model")}/>
                    <div className={"helper-text-error"}>
                        {errors.model?.message}</div>



                    <div className={"input-title"}>機体重量</div>
                    <Controller
                        control={control}
                        name={"weight"}
                        render={({field})=>(
                            <Select className={`select-field`}
                                    status={errors.weight?"error":""}
                                    {...field}
                            >
                                {weight_label_list.map(item => (
                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        )}
                    />
                    <div className={"helper-text-error"}>
                        {errors.weight?.message}</div>

                    <div className={"input-title"}>機体能⼒</div>
                    <input type="text"
                           className={`ant-input ${errors.ability?"error":""}`}
                           {...register("ability")}/>
                    <div className={"helper-text-error"}>
                        {errors.ability?.message}</div>


                    <div className={"input-title"}>アイコン</div>
                        <Controller
                            control={control}
                            render={({field})=>{
                            return (
                                <div className={"column"}>

                                    <div className={"row machine-layout"}>
                                        <div><img
                                            onClick={()=>field.onChange("machine01.png")}
                                            className={`${field.value === "machine01.png"? "active": ""}`}
                                            src={`/images/machine01.png`} width={28} height={28} alt={""}/></div>
                                        <div><img
                                            onClick={()=>field.onChange("machine02.png")}
                                            className={`${field.value === "machine02.png"? "active": ""}`}
                                            src={`/images/machine02.png`} width={28} height={28} alt={""}/></div>
                                        <div><img
                                            onClick={()=>field.onChange("machine03.png")}
                                            className={`${field.value === "machine03.png"? "active": ""}`}
                                            src={`/images/machine03.png`} width={28} height={28} alt={""}/></div>
                                    </div>
                                    <div className={"row machine-layout"}>
                                        <div><img
                                            onClick={()=>field.onChange("barricade.png")}
                                            className={`${field.value === "barricade.png"? "active": ""}`}
                                            src={`/images/barricade.png`} width={28} height={28} alt={""}/></div>
                                        <div><img
                                            onClick={()=>field.onChange("guide.png")}
                                            className={`${field.value === "guide.png"? "active": ""}`}
                                            src={`/images/guide.png`} width={28} height={28} alt={""}/></div>
                                        <div><img
                                            onClick={()=>field.onChange("flag.png")}
                                            className={`${field.value === "flag.png"? "active": ""}`}
                                            src={`/images/flag.png`} width={28} height={28} alt={""}/></div>
                                    </div>
                                    <div className={"helper-text-error"}>
                                        {errors.image?.message}</div>

                                </div>
                            )

                        }} name={"image"}
                    />

                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        htmlType={"submit"}
                        form={"heavy-registration"}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>

            </div>
        </Modal>
    );
};
