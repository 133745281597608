import React from 'react'
import {PlusCircleOutlined} from "@ant-design/icons";


export default function SiteAddition(props){
    const {icon,title, onClick} = props
    return(
        <div style={{
            backgroundColor: "#F0F1F3",
            width: "100%", marginTop: 12, borderRadius: 6
        }}>

            <div className={"column click"} style={{padding: 12}}
            onClick={onClick}
            >
                <div
                    className={"row"} style={{alignItems: "center", justifyContent:"space-between"}}>
                    <div className={"row"} style={{alignItems:"center"}}>
                        <img src={icon} height={16} alt={"no"}/>

                        <div
                            className={"site-menu-item"}
                            style={{
                                marginLeft: 6
                            }}>{title}
                        </div>
                    </div>

                    <PlusCircleOutlined/>
                </div>
            </div>
        </div>


    )
}