import React, {useContext, useEffect} from 'react';
import {Modal, Button, Divider, Input} from 'antd';
import {editGroup, getGroup} from "../api";
import {Store} from "../state_store/provider";
import {GROUP} from "../state_store/actionType";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import * as yub from 'yup'
import {Request} from "../utils";
import {EDIT, EDITING} from "../constant/variables";


const schema = yub.object().shape({
    company: yub.string().required().min(2),
    email: yub.string().email().required()
}).required()

export default function CompanyEdition(props) {
    const {open, onClose, onOK, data} = props
    const {dispatch} = useContext(Store)
    const {register, handleSubmit, formState: {errors},clearErrors, setValue, reset} = useForm({
        resolver: yupResolver(schema)
    });
    const onUpdateGroup = (values) => {
        const {company, email} = values

        Request(dispatch, editGroup({...data, company, email}), onOK).then(error=>{
            if(!error){
                getGroup().then(res => dispatch({type: GROUP, payload: res.groupList}))
            }
        })
        // editGroup({...data, company, email}).then(res => {
        //     if (res.result === 0) {
        //         getGroup().then(res => dispatch({type: GROUP, payload: res.groupList}))
        //         reset()
        //         onOK()
        //     }
        // })
    }



    useEffect(() => {
        // setGroup_data(data)
        clearErrors()
        setValue("email", data.mail)
        setValue("company", data.company)
    }, [data])

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >

            <div className={"dialog"}>
                <div className={"title"}>グループ{EDITING}</div>
                <div className={"subtitle"}>作成するグループ情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"company-edition"} onSubmit={handleSubmit(onUpdateGroup)}>
                        <div className={"input-title"}>ID</div>
                        <Input type="text"
                               value={data.id}
                            disabled={true}
                               />
                        <div className={"input-title"}>グループ名</div>
                        <input type="text"
                               {...register("company")}
                               className={`ant-input ${errors.company?"error":""}`}
                               name={"company"}
                               // value={group_data.company}
                               />
                        <div className={"helper-text-error"}>
                            {errors.company?.message}</div>

                        <div className={"input-title"}>メールアドレス</div>
                        <input type="text"
                               name={"email"}
                               {...register("email")}
                               // value={group_data.email}
                               className={`ant-input ${errors.email?"error":""}`}
                               />
                        <div className={"helper-text-error"}>
                            {errors.email?.message}</div>
                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor: "#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        htmlType={"submit"}
                        form={"company-edition"}
                        type="primary" style={{backgroundColor: "#377EF3"}}>{EDIT}</Button>
                </div>
            </div>
        </Modal>
    );
};
