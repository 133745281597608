import React, {useContext} from 'react';
import {Modal, Button, Divider} from 'antd';
import {addWorkContent, addWorker, getWorkContent} from "../api";
import {Store} from "../state_store/provider";
import {FormFormat, Request} from "../utils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yub from "yup";
import {WORK_CONTENT} from "../state_store/actionType";


const schema = yub.object().shape({
    name: yub.string().required(),
}).required()

export default function WorkContentRegistration(props) {
    const {open, onClose, onOK} = props
    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(schema)
    });
    const {state, dispatch} = useContext(Store)

    const onCreateContent = (value)=>{

        const submit_data = {
            id: "自動採番",
            ...value
        }

        Request(dispatch, addWorkContent(FormFormat(submit_data)), onOK).then(error=>{
            if(!error){
                getWorkContent().then(res=>{
                    if(res.result === 0){
                        dispatch({type:WORK_CONTENT, payload:res.workContentList})
                    }
                })
                reset()
            }
        })
    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>作業内容を追加</div>
                <div className={"subtitle"}>追加する作業情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"content-registration"} onSubmit={handleSubmit(onCreateContent)}>
                        <div className={"input-title"}>作業名</div>
                        <input type="text"
                               className={`ant-input ${errors.name ? "error" : ""}`}
                               {...register("name")}/>
                        <div className={"helper-text-error"}>
                            {errors.name?.message}</div>
                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor: "#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        htmlType={"submit"}
                        form={"content-registration"}
                        type="primary" style={{backgroundColor: "#377EF3"}}>追加する</Button>
                </div>

            </div>
        </Modal>
    );
};
