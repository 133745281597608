import React, {useContext, useEffect, useState} from 'react'
import addition from '../../asset/addition.png'
import edition from '../../asset/edit.png'
import deletion from '../../asset/deletion.png'
import operation from '../../asset/operation.png'
import {Divider} from "antd";
import {Store} from "../../state_store/provider";
import {getUsers, getWorkContent} from "../../api";
import {USERS, WORK_CONTENT} from "../../state_store/actionType";
import TableIconButton from "../../component/table_icon_button";
import WorkContentRegistration from "../../component/work_content_registration";
import WorkContentEdition from "../../component/work_content_edition";
import WorkContentDelete from "../../component/work_content_delete";




export default function WorkContent(){

    const {state, dispatch} = useContext(Store)
    const [openAddition, setOpenAddition] = useState(false)
    const [openEdition, setOpenEdition] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [selected, setSelected] = useState({})
    useEffect(()=>{
        getWorkContent().then(res=>{
            if(res.result === 0){
                dispatch({type:WORK_CONTENT, payload:res.workContentList})
            }
        })
    },[])


    const onOK =()=>{
        setSelected({})
        setOpenDelete(false)
        setOpenAddition(false)
        setOpenEdition(false)
    }
    return(
        <div className={"user-list"}>
            <div className={"wrapper"}>
                <div className={"header row"}>
                    <div className={"row center"}>
                        <div className={"icon"}><img src={operation} width={16}  alt={""}/></div>
                        <div className={"title"}>作業内容登録</div>
                    </div>
                    <div className={"row center"}>
                        <TableIconButton icon={addition} title={"追加"}
                                         selected={openAddition}
                                         onClick={()=>setOpenAddition(true)}/>
                        <TableIconButton icon={edition} title={"編集"}
                                         selected={openEdition}
                                         onClick={()=>setOpenEdition(!!selected.id)}
                        />

                        <TableIconButton icon={deletion} title={"削除"}
                                         selected={openDelete}
                                         onClick={()=>setOpenDelete(!!selected.id)}
                        />
                    </div>
                </div>

                <Divider className={"divider"}/>
            </div>

            <div className={"column table-wrapper"}>
                <div className={"row g-work-content tb-h"}>
                    <div>作業名</div>


                </div>
                {state.work_content.map((res, index)=>(
                    <div className={`row tb-li sbd pointer g-work-content ${res.id===selected.id?"row-active":""}`} key={res.id}

                         onClick={()=>setSelected(res)}
                    >
                        <div className={"text-wrapper"}>{res.name}</div>
                    </div>
                ))}

            </div>

            <WorkContentRegistration open={openAddition}
                               onOK={onOK}
                               onClose={()=>setOpenAddition(false)}
            />


            <WorkContentEdition open={openEdition}
                           onOK={onOK}
                           onClose={()=>setOpenEdition(false)}
                           data={selected}
            />

            <WorkContentDelete open={openDelete}
                         onOK={onOK}
                         onClose={()=>setOpenDelete(false)}
                         data={selected}
            />



        </div>
    )

}



