import "./safety.css"
import {Button, DatePicker, Select} from "antd";
import React, {useEffect, useState} from "react";
import {CaretDownOutlined} from "@ant-design/icons";
import {Option} from "antd/es/mentions";
import TextArea from "antd/es/input/TextArea";
import {addKySafeWorkInstruction, getSelectOptionsSafety} from "../../api";
import {changeFrequencyOrImportance, convertRomanLabel, FormFormat, printPDF} from "../../utils";
import Warning from "../../component/warning";
import Success from "../../component/success_dialog";
import ErrorSafety from "../../component/error_safety";

const initialState = {
    point_id: "",
    work_date: "",
    site_name: "",
    carrier: "",
    safety_health_responsible_person1: "",
    work_content1: "",
    schedule1: "",
    execution1: "",
    meeting1: "",
    work_content2: "",
    schedule2: "",
    execution2: "",
    meeting2: "",
    work_content3: "",
    schedule3: "",
    execution3: "",
    meeting3: "",
    tool_machine: "",
    instruction_date: "",
    instruction: "",
    safety_health_responsible_person2: "",
    safety_instruction: "",
    info: "",
    attendee1: "",
    attendee2: "",
    attendee3: "",
    attendee4: "",
    attendee5: "",
    attendee6: "",
    attendee7: "",
    attendee8: "",
    attendee9: "",
    attendee10: "",
    attendee11: "",
    attendee12: "",
    attendee13: "",
    attendee14: "",
    attendee15: "",
    attendee16: "",
    worker: "",
    risk1: "",
    frequency1: "",
    importance1: "",
    evaluation1: "",
    dangerous_degree1: "",
    risk2: "",
    frequency2: "",
    importance2: "",
    evaluation2: "",
    dangerous_degree2: "",
    risk3: "",
    frequency3: "",
    importance3: "",
    evaluation3: "",
    dangerous_degree3: "",
    counter_plan1: "",
    counter_plan1_frequency: "",
    counter_plan1_importance: "",
    counter_plan1_evaluation: "",
    counter_plan1_dangerous_degree: "",
    counter_plan2: "",
    counter_plan2_frequency: "",
    counter_plan2_importance: "",
    counter_plan2_evaluation: "",
    counter_plan2_dangerous_degree: "",
    counter_plan3: "",
    counter_plan3_frequency: "",
    counter_plan3_importance: "",
    counter_plan3_evaluation: "",
    counter_plan3_dangerous_degree: "",
    action_goal: "",
    one_point: ""
}



export default function Safety(){

    const [option, setOption] = useState({})
    const [value, setValue] = useState(initialState)
    const [isPrint, setIsPrint] = useState(false)
    const [warning, setWarning] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState({})

    useEffect(()=>{
        window.onafterprint = ()=>setIsPrint(false)
        const site_id = localStorage.getItem("site_id")
        getSelectOptionsSafety(FormFormat({site_id})).then(res=>{
            if(res.result === 0){
                setOption(res)
            }
        })
    },[])

    useEffect(()=>{
        const evaluation = changeFrequencyOrImportance(value.frequency1, value.importance1)
        if(evaluation){
            setValue(prevState => ({...prevState,
                evaluation1: evaluation[0], dangerous_degree1: evaluation[1]}))
        }
    },[value.frequency1, value.importance1])

    useEffect(()=>{
        const evaluation = changeFrequencyOrImportance(value.frequency2, value.importance2)
        if(evaluation){
            setValue(prevState => ({...prevState,
                evaluation2: evaluation[0], dangerous_degree2: evaluation[1]}))
        }
    },[value.frequency2, value.importance2])

    useEffect(()=>{
        const evaluation = changeFrequencyOrImportance(value.frequency3, value.importance3)
        if(evaluation){
            setValue(prevState => ({...prevState,
                evaluation3: evaluation[0], dangerous_degree3: evaluation[1]}))
        }
    },[value.frequency3, value.importance3])

    useEffect(()=>{
        const evaluation = changeFrequencyOrImportance(value.counter_plan1_frequency, value.counter_plan1_importance)
        if(evaluation){
            setValue(prevState => ({...prevState,
                counter_plan1_evaluation: evaluation[0], counter_plan1_dangerous_degree: evaluation[1]}))
        }
    },[value.counter_plan1_frequency, value.counter_plan1_importance])

    useEffect(()=>{
        const evaluation = changeFrequencyOrImportance(value.counter_plan2_frequency, value.counter_plan2_importance)
        if(evaluation){
            setValue(prevState => ({...prevState,
                counter_plan2_evaluation: evaluation[0], counter_plan2_dangerous_degree: evaluation[1]}))
        }
    },[value.counter_plan2_frequency, value.counter_plan2_importance])


    useEffect(()=>{
        const evaluation = changeFrequencyOrImportance(value.counter_plan3_frequency, value.counter_plan3_importance)
        if(evaluation){
            setValue(prevState => ({...prevState,
                counter_plan3_evaluation: evaluation[0], counter_plan3_dangerous_degree: evaluation[1]}))
        }
    },[value.counter_plan3_frequency, value.counter_plan3_importance])

    const onInput = ({target})=>{
        const {name, value} = target
        setValue(prevState => ({...prevState, [name]:value}))
    }
    const onSelect = (name, value)=>{
        setValue(prevState => ({...prevState, [name]:value}))
    }

    const onSubmit = ()=>{
        const point_id = sessionStorage.getItem("point_id")
        const site_id = localStorage.getItem("site_id")
        const user = localStorage.getItem("user")
        if(!value.work_date){
            setWarning(true)
            return
        }
        const submit_value = {
            ...value, point_id,
            work_date: value.work_date.format("YYYY-MM-DD"),
            instruction_date:value.instruction_date.format("YYYY-MM-DD"),
            dangerous_degree1: convertRomanLabel(value.dangerous_degree1),
            dangerous_degree2: convertRomanLabel(value.dangerous_degree2),
            dangerous_degree3: convertRomanLabel(value.dangerous_degree2),
            counter_plan1_dangerous_degree: convertRomanLabel(value.counter_plan1_dangerous_degree),
            counter_plan2_dangerous_degree: convertRomanLabel(value.counter_plan2_dangerous_degree),
            counter_plan3_dangerous_degree: convertRomanLabel(value.counter_plan3_dangerous_degree)
        }
        addKySafeWorkInstruction(FormFormat({ky_safe_work_instruction:JSON.stringify(submit_value), site_id,
            worker_id:user})).then(res=>{
            if(res.result === 0){
                setSuccess(true)
            }else{
                setError({open:true, message:res.msg})
            }
        })

    }

    useEffect(()=>{
        if(isPrint)
            printPDF()
    }, [isPrint])
    return (
        <div className={"full-w"} style={{height:"100vh", overflowX:"auto", display:"flex", justifyContent:"center"}}>
        <div className={"sf"} >
            <div className={"column"}>
                <div className={"column"}>
                    <div className={"row full-w"}>
                        <div className={"column full-w"}>
                            <div className={"title"}>KY・安全作業指示書</div>
                            <div className={"row bd col-height"} style={{width:"60%"}}>
                                <div className={"st-9 bd-r t-full-h label"}>現場名</div>
                                <div className={"full-h"} style={{width:"calc(100% - 200px)"}}>
                                    <input className={"wp-input"}
                                           name={"site_name"}
                                           onChange={onInput}
                                    />
                                </div>
                            </div>
                        </div>

                        <div>

                        </div>
                    </div>
                    <div style={{height:8}}></div>
                    <div className={"row bd full-w col-height"}>
                        <div className={"st-9 bd-r t-full-h label"}>現場名</div>
                        <div className={"bd-r"} style={{width:"calc(50% - 200px)"}}>
                            <Select
                                onChange={(value)=>onSelect("carrier", value)}
                                className={"st-9 full-w"}
                                name={"test"}
                                suffixIcon={<CaretDownOutlined />}
                                 >
                                <Option value={""}></Option>
                                {
                                    option.carriers?.map(res=>{
                                        return <Option value={res.value} key={res.value}>{res.text}</Option>
                                    })
                                }

                            </Select>
                        </div>
                        <div className={"st-9 bd-r t-full-h label"}>現場名</div>
                        <div style={{width:"calc(50% - 200px)"}}>
                            <Select
                                onChange={(value)=>onSelect("safety_health_responsible_person1", value)}
                                className={"st-9 full-w"}
                                suffixIcon={<CaretDownOutlined />}
                            >
                                <Option value={""}></Option>
                                {
                                    option.workers?.map(res=>{
                                        return <Option value={res.value} key={res.value}>{res.text}</Option>
                                    })
                                }

                            </Select>
                        </div>
                    </div>
                </div>

                <div style={{height:8}}></div>

                <div className={"column"}>
                    <div className={"row"} style={{justifyContent:"space-between"}}>
                        <div className={"st-11 b text-center"}>1.作業内容・出面予定・重点安全事項</div>
                        <div className={"row"} style={{alignItems:"center"}}>
                            <div className={"st-9"}>作業日: </div>
                            <div style={{width:20}}></div>
                            <div><DatePicker
                                onChange={(value)=>onSelect("work_date", value)}
                                name={"work_date"}
                                placeholder={"年-月-日"}
                                className={"wp-date"}
                                style={{height:24, fontsize:9, marginBottom:4, width:160}}
                                 /></div>
                        </div>
                    </div>

                    <div className={"column bd full-w"}>
                        <div className={"row"}>
                            <div className={"bd-r st-9 b txt-f-center"} style={{width:"calc(50% - 100px)", height:36}}>
                                現場名
                            </div>

                            <div className={"column bd-r st-9"} style={{width:200}}>
                                <div className={"bd-b full-w full-h txt-f-center"}><b>出面</b></div>
                                <div className={"row full-w full-h"}>
                                    <div className={"full-w full-h txt-f-center bd-r"}>予定</div>
                                    <div className={"full-w full-h txt-f-center"}>実施</div>
                                </div>
                            </div>

                            <div className={"st-9 b txt-f-center"} style={{width:"calc(50% - 100px)", height:36}}>
                                現場名
                            </div>
                        </div>
                        <div className={"row bd-t col-height"}>
                            <div className={"bd-r st-9 full-h txt-f-center"} style={{width:"calc(50% - 100px)"}}>
                                <input className={"wp-input"}
                                        name={"work_content1"}
                                       onChange={onInput}
                                />
                            </div>

                            <div className={"row bd-r st-9 full-h"} style={{width:200}}>
                                <div className={"bd-r full-w full-h"}>
                                    <input className={"wp-input"}
                                           name={"schedule1"}
                                           onChange={onInput}
                                    />
                                </div>
                                <div className={"full-w full-h"}>
                                    <input className={"wp-input"}
                                           name={"execution1"}
                                           onChange={onInput}
                                    />
                                </div>
                            </div>

                            <div className={"st-9 full-h txt-f-center"} style={{width:"calc(50% - 100px)"}}>
                                <input className={"wp-input"}
                                    name={"meeting1"}
                                       onChange={onInput}
                                />
                            </div>
                        </div>

                        <div className={"row bd-t col-height"}>
                            <div className={"bd-r st-9 full-h txt-f-center"} style={{width:"calc(50% - 100px)"}}>
                                <input className={"wp-input"}
                                    name={"work_content2"}
                                       onChange={onInput}
                                />
                            </div>

                            <div className={"row bd-r st-9 full-h"} style={{width:200}}>
                                <div className={"bd-r full-w full-h"}>
                                    <input className={"wp-input"}
                                           name={"schedule2"}
                                           onChange={onInput}
                                    />
                                </div>
                                <div className={"full-w full-h"}>
                                    <input className={"wp-input"}
                                           name={"execution2"}
                                           onChange={onInput}
                                    />
                                </div>
                            </div>

                            <div className={"st-9 full-h txt-f-center"} style={{width:"calc(50% - 100px)"}}>
                                <input className={"wp-input"}
                                    name={"meeting2"}
                                       onChange={onInput}
                                />
                            </div>
                        </div>

                        <div className={"row bd-t col-height"}>
                            <div className={"bd-r st-9 full-h txt-f-center"} style={{width:"calc(50% - 100px)"}}>
                                <input className={"wp-input"}
                                    name={"work_content3"}
                                       onChange={onInput}
                                />
                            </div>

                            <div className={"row bd-r st-9 full-h"} style={{width:200}}>
                                <div className={"bd-r full-w full-h"}>
                                    <input className={"wp-input"}
                                           name={"schedule3"}
                                           onChange={onInput}
                                    />
                                </div>
                                <div className={"full-w full-h"}>
                                    <input className={"wp-input"}
                                           name={"execution3"}
                                           onChange={onInput}
                                    />
                                </div>
                            </div>

                            <div className={"st-9 full-h txt-f-center"} style={{width:"calc(50% - 100px)"}}>
                                <input className={"wp-input"}
                                       name={"meeting3"}
                                       onChange={onInput}
                                />
                            </div>
                        </div>
                    </div>


                </div>

                <div style={{height:8}}></div>

                <div className={"column"}>
                    <div className={"row"} style={{justifyContent:"space-between"}}>
                        <div className={"st-11 b text-center"}>2. 使用建設工具・機械名</div>
                    </div>


                        <div className={"row bd col-height full-w"}>
                            <div className={"full-h st-9 txt-f-center"} style={{width:"calc(50% - 200px)"}}>
                                <input className={"wp-input"}
                                    name={"tool_machine"}
                                       onChange={onInput}
                                />
                            </div>


                    </div>


                </div>

                <div style={{height:8}}></div>

                <div className={"column"}>
                    <div className={"row"} style={{justifyContent:"space-between"}}>
                        <div className={"st-11 b text-center"}>3. 元方事業者担当安全指示・注意事項</div>
                        <div className={"row"} style={{alignItems:"center"}}>
                            <div className={"st-9"}>指示日：</div>
                            <div style={{width:20}}></div>
                            <div><DatePicker
                                onChange={(value)=>onSelect("instruction_date", value)}
                                name={"instruction_date"}
                                placeholder={"年-月-日"}
                                className={"wp-date"}
                                style={{height:24, fontsize:11, marginBottom:4, width:160}}
                            /></div>
                            <div style={{width:16}}></div>
                            <div className={"row bd-t bd-r bd-l"} style={{height:"100%", alignItems:"center"}}>
                                <div className={"st-7 bd-r txt-f-center"} style={{width:50, height:"100%"}}>指示者</div>
                                <Select
                                        onChange={(value)=>onSelect("instruction", value)}
                                        className={"bd-r st-9"}
                                        suffixIcon={<CaretDownOutlined />}
                                        style={{ width: 120}} >
                                    <Option value={""}></Option>
                                    {
                                        option.workers?.map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }

                                </Select>

                                <div className={"column st-7 bd-r txt-f-center"} style={{width:70, height:"100%"}}>
                                    <div>安全衛生責任者</div>
                                    <div>（職長）</div>
                                </div>


                                <Select
                                    onChange={(value)=>onSelect("safety_health_responsible_person2", value)}
                                    className={"st-9"}
                                    suffixIcon={<CaretDownOutlined />}
                                    style={{ width: 120}} >
                                    <Option value={""}></Option>
                                    {
                                        option.workers?.map(res=>{
                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                        })
                                    }
                                </Select>
                            </div>

                        </div>
                    </div>

                    <div className={"st-9 txt-f-center full-w"} >
                        <TextArea className={"bd wp-text-area"}
                                  onChange={onInput}
                                  style={{borderRadius:0, borderColor:"#1A1311"}}
                                  name={"safety_instruction"} autoSize/>
                    </div>

                    <div className={"row bd-b bd-r bd-l col-height"}>
                        <div className={"st-9 bd-r t-full-height label"}>連絡事項</div>
                        <div className={"full-h"} style={{width:"calc(50% - 200px)"}}>
                            <input className={"wp-input"}
                            name={"info"}
                                   onChange={onInput}
                            />
                        </div>
                    </div>


                </div>

                <div style={{height:8}}></div>

                <div className={"column"}>
                    <div className={"row"} style={{justifyContent:"space-between"}}>
                        <div className={"st-11 b text-center"}>4. リスクアセスメント危険予知（KY）活動報告</div>
                    </div>

                    <div className={"column bd full-w"}>
                        <div className={"row full-w"}>
                            <div className={"column bd-r st-9 label txt-f-center"} style={{height:64, alignItems:"flex-start"}}>
                                <div>&nbsp;朝 礼・K Y 出 席 者</div>
                                <div>（元請・下請全員　各自）</div>
                            </div>
                            <div className={"column"} style={{width:"calc(100% - 200px)"}}>
                                <div className={"row full-w full-h"}>

                                    {
                                        [1,2,3,4,5,6,7].map(res=>{
                                            return <div className={"m-column bd-r"}>
                                                <Select
                                                    onChange={(value)=>onSelect(`attendee${res}`, value)}
                                                    className={"st-9 full-w"}
                                                    suffixIcon={<CaretDownOutlined />}
                                                >
                                                    <Option value={""}></Option>
                                                    {
                                                        option.workers?.map(res=>{
                                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                                        })
                                                    }

                                                </Select>
                                            </div>
                                        })
                                    }

                                    <div className={"m-column"}>
                                        <Select
                                            onChange={(value)=>onSelect("attendee8", value)}
                                            className={"st-9 full-w"}
                                            suffixIcon={<CaretDownOutlined />}
                                        >
                                            <Option value={""}></Option>
                                            {
                                                option.workers?.map(res=>{
                                                    return <Option value={res.value} key={res.value}>{res.text}</Option>
                                                })
                                            }

                                        </Select>
                                    </div>
                                </div>
                                <div className={"row full-w full-h bd-t"}>
                                    {
                                        [9,10,11,12,13,14,15].map(res=>{
                                            return <div className={"m-column bd-r"}>
                                                <Select
                                                    onChange={(value)=>onSelect(`attendee${res}`, value)}
                                                    className={"st-9 full-w"}
                                                    suffixIcon={<CaretDownOutlined />}
                                                >
                                                    <Option value={""}></Option>
                                                    {
                                                        option.workers?.map(res=>{
                                                            return <Option value={res.value} key={res.value}>{res.text}</Option>
                                                        })
                                                    }

                                                </Select>
                                            </div>
                                        })
                                    }
                                    <div className={"m-column"}>
                                        <Select
                                            onChange={(value)=>onSelect("attendee16", value)}
                                            className={"st-9 full-w"}
                                            suffixIcon={<CaretDownOutlined />}
                                        >
                                            <Option value={""}></Option>
                                            {
                                                option.workers?.map(res=>{
                                                    return <Option value={res.value} key={res.value}>{res.text}</Option>
                                                })
                                            }

                                        </Select>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={"row full-w bd-t"}>
                            <div className={"st-9 label bd-r"}>対象作業者名</div>
                            <Select
                                onChange={(value)=>onSelect("worker", value)}
                                className={"st-9"}
                                suffixIcon={<CaretDownOutlined />}
                                style={{width:"calc(50% - 212px)"}} >
                                <Option value={""}></Option>
                                {
                                    option.workers?.map(res=>{
                                        return <Option value={res.value} key={res.value}>{res.text}</Option>
                                    })
                                }
                            </Select>
                        </div>

                        <div className={"row bd-t"} style={{backgroundColor:"#D4D5D5"}}>
                            <div className={"no st-9 txt-f-center bd-r"}>No.</div>
                            <div className={"row full-w"} style={{width:"calc(100% - 40px)"}}>
                                <div className={"row full-w"}>
                                    <div className={"st-9 text-center bd-r"} style={{width:"calc(50% + 188px)"}}>
                                        <span style={{width:8}}></span><b>予想される危険性・有害性</b>
                                        <span className={"st-7"}>（〜して〜なる）（〜なので〜になる）</span></div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>頻　度</div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>重　大　性</div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>評　価</div>
                                    <div className={"m-column st-9 txt-f-center"}>危　険　度</div>
                                </div>

                            </div>

                        </div>
                        <div className={"row bd-t"}>
                            <div className={"no st-11 txt-f-center bd-r"}><b>1</b></div>
                            <div className={"row full-w"} style={{width:"calc(100% - 40px)"}}>
                                <div className={"row full-w"}>
                                    <div className={"st-9 full-h text-center bd-r"} style={{width:"calc(50% + 188px)"}}>
                                        <input className={"wp-input"}
                                            name={"risk1"}
                                               onChange={onInput}
                                        /></div>
                                    <div className={"m-column st-9 bd-r"}>
                                        <Select
                                            onChange={(value)=>onSelect("frequency1", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                         >
                                        <Option value={""}></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 bd-r"}>
                                        <Select
                                            onChange={(value)=>onSelect("importance1", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                        <Option></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>
                                        {value.evaluation1}
                                    </div>
                                    <div className={"m-column st-9 txt-f-center"}>
                                        {value.dangerous_degree1}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className={"row bd-t"}>
                            <div className={"no st-11 txt-f-center bd-r"}><b>2</b></div>
                            <div className={"row full-w"} style={{width:"calc(100% - 40px)"}}>
                                <div className={"row full-w"}>
                                    <div className={"st-9 full-h text-center bd-r"} style={{width:"calc(50% + 188px)"}}>
                                        <input className={"wp-input"}
                                            name={"risk2"}
                                               onChange={onInput}
                                        /></div>
                                    <div className={"m-column st-9 bd-r"}>
                                        <Select
                                            onChange={(value)=>onSelect("frequency2", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                        <Option value={""}></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 bd-r"}>
                                        <Select
                                        onChange={(value)=>onSelect("importance2", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                        <Option value={""}></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>
                                        {value.evaluation2}
                                    </div>
                                    <div className={"m-column st-9 txt-f-center"}>
                                        {value.dangerous_degree2}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className={"row bd-t"}>
                            <div className={"no st-11 txt-f-center bd-r"}><b>3</b></div>
                            <div className={"row full-w"} style={{width:"calc(100% - 40px)"}}>
                                <div className={"row full-w"}>
                                    <div className={"st-9 full-h text-center bd-r"} style={{width:"calc(50% + 188px)"}}>
                                        <input className={"wp-input"}
                                            name={"risk3"}
                                               onChange={onInput}
                                        /></div>
                                    <div className={"m-column st-9 bd-r"}>
                                        <Select
                                            onChange={(value)=>onSelect("frequency3", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                        <Option value={""}></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 bd-r"}>
                                        <Select
                                            onChange={(value)=>onSelect("importance3", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                        <Option value={""}></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>
                                        {value.evaluation3}
                                    </div>
                                    <div className={"m-column st-9 txt-f-center"}>
                                        {value.dangerous_degree3}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className={"row bd-t"} style={{backgroundColor:"#D4D5D5"}}>
                            <div className={"no st-9 txt-f-center bd-r"} >No.</div>
                            <div className={"row full-w"} style={{width:"calc(100% - 40px)"}}>
                                <div className={"row full-w"}>
                                    <div className={"st-9 text-center bd-r"} style={{width:"calc(50% + 188px)"}}>
                                        <span style={{width:8}}></span><b>対策</b>
                                        <span className={"st-7"}>（〜を　〜して　〜する）</span></div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>頻　度</div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>重　大　性</div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>評　価</div>
                                    <div className={"m-column st-9 txt-f-center"}>危　険　度</div>
                                </div>

                            </div>

                        </div>
                        <div className={"row bd-t"}>
                            <div className={"no st-11 txt-f-center bd-r"}><b>1</b></div>
                            <div className={"row full-w"} style={{width:"calc(100% - 40px)"}}>
                                <div className={"row full-w"}>
                                    <div className={"st-9 full-h text-center bd-r"} style={{width:"calc(50% + 188px)"}}>
                                        <input className={"wp-input"}
                                            name={"counter_plan1"}
                                               onChange={onInput}
                                        /></div>
                                    <div className={"m-column st-9 bd-r"}><Select
                                        onChange={(value)=>onSelect("counter_plan1_frequency", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                        <Option value={""}></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 bd-r"}><Select
                                        onChange={(value)=>onSelect("counter_plan1_importance", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                        <Option value={""}></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>
                                        {value.counter_plan1_evaluation}
                                    </div>
                                    <div className={"m-column st-9 txt-f-center"}>
                                        {value.counter_plan1_dangerous_degree}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className={"row bd-t"}>
                            <div className={"no st-11 txt-f-center bd-r"}><b>2</b></div>
                            <div className={"row full-w"} style={{width:"calc(100% - 40px)"}}>
                                <div className={"row full-w"}>
                                    <div className={"st-9 full-h text-center bd-r"} style={{width:"calc(50% + 188px)"}}>
                                        <input className={"wp-input"}
                                               name={"counter_plan2"}
                                               onChange={onInput}
                                        /></div>
                                    <div className={"m-column st-9 bd-r"}>
                                        <Select
                                            onChange={(value)=>onSelect("counter_plan2_frequency", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                        <Option value={""}></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 bd-r"}>
                                        <Select
                                            onChange={(value)=>onSelect("counter_plan2_importance", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                        <Option value={""}></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>
                                        {value.counter_plan2_evaluation}
                                    </div>
                                    <div className={"m-column st-9 txt-f-center"}>
                                        {value.counter_plan2_dangerous_degree}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className={"row bd-t"}>
                            <div className={"no st-11 txt-f-center bd-r"}><b>3</b></div>
                            <div className={"row full-w"} style={{width:"calc(100% - 40px)"}}>
                                <div className={"row full-w"}>
                                    <div className={"st-9 full-h text-center bd-r"} style={{width:"calc(50% + 188px)"}}>
                                        <input className={"wp-input"}
                                               name={"counter_plan3"}
                                               onChange={onInput}
                                        /></div>
                                    <div className={"m-column st-9 bd-r"}><Select
                                        onChange={(value)=>onSelect("counter_plan3_frequency", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                        <Option value={""}></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 bd-r"}>
                                        <Select
                                            onChange={(value)=>onSelect("counter_plan3_importance", value)}
                                        className={"st-9 full-w"}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                        <Option value={""}></Option>
                                        <Option value={"5"}>5</Option>
                                        <Option value={"3"}>3</Option>
                                        <Option value={"1"}>1</Option>
                                    </Select></div>
                                    <div className={"m-column st-9 txt-f-center bd-r"}>
                                        {value.counter_plan3_evaluation}
                                    </div>
                                    <div className={"m-column st-9 txt-f-center"}>
                                        {value.counter_plan3_dangerous_degree}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className={"row bd-t col-height"}>
                            <div className={"column label txt-f-center bd-r"}>
                                <div className={"st-9 b"}>チーム行動目標</div>
                                <div className={"st-7"}>（〜を 〜して 〜しよう）</div>
                            </div>
                            <div className={"full-h"} style={{width:"calc(100% - 200px)"}}>
                                <input className={"wp-input"}
                                    name={"action_goal"}
                                       onChange={onInput}
                                />
                            </div>


                        </div>

                        <div className={"row bd-t col-height"}>
                            <div className={"column label txt-f-center bd-r"}>
                                <div className={"st-9 b"}>ワンポイント</div>
                            </div>
                            <div className={"full-h"} style={{width:"calc(100% - 200px)"}}>
                                <input className={"wp-input"}
                                    name={"one_point"}
                                       onChange={onInput}
                                />
                            </div>


                        </div>
                    </div>



                </div>
            </div>

            <div className={"row"} style={{marginTop:26, justifyContent:"end", display:`${isPrint?"none":"flex"}`}}>
                <Button type="primary" style={{backgroundColor:"#387FF7"}}
                        className={"wp-btn"}
                        onClick={()=>setIsPrint(true)}
                >印刷する</Button>
                <Button type="primary" style={{marginLeft:44, backgroundColor:"#606060"}}
                        className={"wp-btn"}
                        onClick={()=>window.close()}
                >閉じる</Button>
                <Button type="primary" style={{marginLeft:14, backgroundColor:"#387FF7"}}
                        className={"wp-btn"}
                        onClick={onSubmit}
                >保存</Button>
            </div>
        </div>

            <Warning
                open={warning}
                title={"作業日項目は必須です。"}
                onClose={()=>setWarning(false)}
            />

            <Success
                open={success}
                title={"重機作業計画書を登録しました"}
                onClose={()=>{
                    setSuccess(false)
                    window.close()
                }
                }
            />
            <ErrorSafety
                {...error}
                onClose={()=>setError({})}
            />
        </div>
    )

}