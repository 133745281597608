import React, {useContext} from 'react';
import {Modal, Button} from 'antd';
import {Store} from "../state_store/provider";
import {ERROR} from "../state_store/actionType";
import {CloseCircleOutlined} from "@ant-design/icons";


export default function ErrorDialog() {

    const {state, dispatch} = useContext(Store)
    const {open, message} = state.error
    return (

        <Modal visible={open}
               footer={null}
               closable={true}
               width={400}
               onCancel={()=>dispatch({type:ERROR, payload:{}})}
        >
            <div className={"dialog"}>

                <div className={"delete-dialog-body column center"}>
                    <div className={"icon"}>
                        <CloseCircleOutlined />
                    </div>

                    <div className={"error-message"}>{message}</div>

                </div>

            </div>
        </Modal>
    );
};
