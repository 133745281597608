import React, {useContext, useEffect} from 'react';
import {Modal, Button, Divider} from 'antd';
import { getWorkContent,  updateWorkContent} from "../api";
import {Store} from "../state_store/provider";
import {FormFormat, Request} from "../utils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yub from "yup";
import {WORK_CONTENT} from "../state_store/actionType";


const schema = yub.object().shape({
    name: yub.string().required(),
}).required()
export default function WorkContentEdition(props) {
    const {open, onClose, onOK, data} = props
    const {register, handleSubmit, formState: {errors}, clearErrors, setValue, reset} = useForm({
        resolver: yupResolver(schema)
    });
    const {dispatch} = useContext(Store)

    const onUpdateContent = (value)=>{

        const submit_data = {
            id: data.id,
            ...value
        }

        Request(dispatch, updateWorkContent(FormFormat(submit_data)), onOK).then(error=>{
            if(!error){
                getWorkContent().then(res=>{
                    if(res.result === 0){
                        dispatch({type:WORK_CONTENT, payload:res.workContentList})
                    }
                })
                reset()
            }
        })
    }


    useEffect(()=>{
        clearErrors()
        reset()
        for(const key in data){
           setValue(key, data[key])
        }
    },[data])

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >


            <div className={"dialog"}>
                <div className={"title"}>作業内容編集</div>
                <div className={"subtitle"}>編集する作業内容情報を入力してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"content-edition"} onSubmit={handleSubmit(onUpdateContent)}>
                        <div className={"input-title"}>作業名</div>
                        <input type="text"
                               className={`ant-input ${errors.name ? "error" : ""}`}
                               {...register("name")}/>
                        <div className={"helper-text-error"}>
                            {errors.name?.message}</div>
                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        htmlType={"submit"}
                        form={"content-edition"}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>

            </div>
        </Modal>
    );
};
