import React, {useContext, useEffect} from 'react';
import {Modal, Button, Divider, Select} from 'antd';
import {editUser, getUsers} from "../api";
import {Store} from "../state_store/provider";
import {ERROR, USERS} from "../state_store/actionType";
import {Option} from "antd/es/mentions";
import {Role} from "../constant";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yub from "yup";
import {Error, Loading} from "../utils";
import {EDIT, EDITING} from "../constant/variables";




const schema = yub.object().shape({
    id:yub.string().required(),
    name:yub.string().min(2).required(),
    password: yub.string().min(6).required(),
    email: yub.string().email().required(),
    admin_flg:yub.string().required("Role is a required field"),
    carrier_id: yub.string().required("Company is a required field")
}).required()
export default function UserEdition(props) {

    const {open, onClose, onOK, data} = props
    const {state, dispatch} = useContext(Store)
    const {register, handleSubmit, control, formState: {errors}, clearErrors, setValue, reset} = useForm({
        resolver: yupResolver(schema)
    });
    const onUpdateUser = (stateData)=>{
        Loading(dispatch, true, onOK)
        editUser({...stateData, orgId:data.id}).then(res=>{
            Error(dispatch, res, onOK).then(error=>{
                if(!error){
                    getUsers().then(res=>{
                        if(res.result === 0)
                            dispatch({type: USERS, payload: res.userList})
                    })
                }
            })

        })
    }

    useEffect(()=>{
        clearErrors()
        reset()
        for(const key in data){
            if(key === "mail")
            setValue("email", data[key])
            else {
                setValue(key, data[key])
            }

        }

    }, [data])

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>ユーザー{EDITING}</div>
                <div className={"subtitle"}>作成するユーザー情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>


                <div className={"add-dialog-body"}>
                    <form id={"user-update"} onSubmit={handleSubmit(onUpdateUser)}>
                        <div className={"input-title"}>ログインID</div>
                        <input type="text"
                               className={`ant-input ${errors.id?"error":""}`}
                               {...register("id")}/>
                        <div className={"helper-text-error"}>
                            {errors.id?.message}</div>

                        <div className={"input-title"}>ユーザー名</div>
                        <input type="text"
                               className={`ant-input ${errors.name?"error":""}`}
                               {...register("name")}/>
                        <div className={"helper-text-error"}>
                            {errors.name?.message}</div>

                        <div className={"input-title"}>パスワード</div>
                        <input type="text"
                               className={`ant-input ${errors.password?"error":""}`}
                               {...register("password")}/>
                        <div className={"helper-text-error"}>
                            {errors.password?.message}</div>

                        <div className={"input-title"}>メールアドレス</div>
                        <input type="text"
                               className={`ant-input ${errors.email?"error":""}`}
                               {...register("email")}/>
                        <div className={"helper-text-error"}>
                            {errors.email?.message}</div>


                        <div className={"input-title"}>グループ名</div>
                        <Controller
                            control={control}
                            name={"carrier_id"}
                            render={({field})=>(
                                <Select className={`select-field`}
                                        status={errors.carrier_id?"error":""}

                                        {...field}
                                    // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                >
                                    {state.group.map(item => (
                                        <Option value={item.id}>{item.company}</Option>
                                    ))}
                                </Select>
                            )}
                        />
                        <div className={"helper-text-error"}>
                            {errors.carrier_id?.message}</div>


                        <div className={"input-title"}>権限</div>

                        <Controller
                            control={control}
                            name={"admin_flg"}
                            render={({field})=>(
                                <Select className={`select-field ${errors.admin_flg?"error":""}`}
                                        {...field}
                                        status={errors.admin_flg?"error":""}
                                    // onChange={(value) => onChange({target: {name: "admin_flg", value}})}
                                >
                                    {Role.map((item, index) => (
                                        <Option value={index}>{item}</Option>
                                    ))}
                                </Select>
                            )}
                        />
                        <div className={"helper-text-error"}>
                            {errors.admin_flg?.message}</div>

                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor: "#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        // onClick={onCreateUser}
                        htmlType={"submit"}
                        form={"user-update"}
                        type="primary" style={{backgroundColor: "#377EF3"}}>{EDIT}</Button>
                </div>
            </div>
        </Modal>
    )

};
