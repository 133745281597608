import React, {useContext} from 'react'
import {Divider, Button} from 'antd'
import {RightOutlined, CaretDownOutlined} from '@ant-design/icons'
import {useNavigate} from "react-router-dom";
import {Icon} from "../../component/icon";
import FieldManagement from '../../asset/field_management.png'
import BusinessOperator from '../../asset/business_operator.png'
import DeliveryCompany from '../../asset/delivery_company.png'
import {Store} from "../../state_store/provider";
import {TAB} from "../../state_store/actionType";

export default function MenuCard(){
    const {dispatch} = useContext(Store)
    const navigate = useNavigate()
    function CardButton(props){
        const {title, number, tab, ...res} = props
        return (<Button {...res} type="primary"
                        onClick={()=>{
                            dispatch({type:TAB, payload: tab.toString()})
                            navigate("/ordering")
                        }}
                        className={"row"} style={{backgroundColor:"#377EF3"}} >
            <div>

                <span>{number}</span>
                <span style={{marginLeft:12, fontSize:13}}>{title}</span>
            </div>
            <RightOutlined style={{fontSize:13}} />
        </Button>)
    }

    return(
        <div className={"row md"} style={{margin:"26px 18px"}}>


                <div className={"column card-layout"}>
                        <div className={"row"} style={{alignItems:"center"}}>
                            <Icon src={FieldManagement} size={16}/>
                            <div className={"card-title"}>現場管理 </div>
                        </div>
                    <Divider className={"divider"}/>
                        <div className={"column card-btn"}>
                            <CardButton title={"作業者を確認する"} number={1} tab={2} />
                            <CaretDownOutlined style={{color:"gray"}}/>
                            <CardButton title={"機械稼働を確認する"} number={2} tab={2}/>
                            <CaretDownOutlined style={{color:"gray"}}/>
                            <CardButton title={"資材配置を確認する"} number={3} tab={2}/>
                            <CaretDownOutlined style={{color:"gray"}}/>
                            <div style={{textAlign:"center", fontSize:12, fontWeight:600}}>完了</div>
                            <div style={{height:20}}></div>
                            <CardButton title={"作業現場管理メイン"} number={1} tab={3}/>
                            <CaretDownOutlined style={{color:"gray"}}/>
                            <CardButton title={"現場管理履歴表示"} number={2} tab={3}/>
                            <CaretDownOutlined style={{color:"gray"}}/>
                            <div style={{textAlign:"center", fontSize:12, fontWeight:600}}>完了</div>
                        </div>
                </div>


            <div className={"column card-layout"}>
                <div className={"row"} style={{alignItems:"center"}}>
                    <Icon src={BusinessOperator} size={16}/>
                    <div className={"card-title"}>事業者 </div>
                </div>
                <Divider className={"divider"}/>
                <div className={"column card-btn"}>
                    <CardButton title={"注文情報入力"} number={1} tab={1}/>
                    <CaretDownOutlined style={{color:"gray"}}/>
                    <CardButton title={"搬入希望位置入力"} number={2} tab={1}/>
                    <CaretDownOutlined style={{color:"gray"}}/>
                    <div style={{textAlign:"center", fontSize:12, fontWeight:600}}>完了</div>
                </div>
            </div>


            <div className={"column card-layout"}>
                <div className={"row"} style={{alignItems:"center"}}>
                    <Icon src={DeliveryCompany} size={16}/>
                    <div className={"card-title"}>現場管理 </div>
                </div>
                <Divider className={"divider"}/>
                <div className={"column card-btn"}>
                    <CardButton title={"配車情報入力"} number={1} tab={1}/>
                    <CaretDownOutlined style={{color:"gray"}}/>
                    <CardButton title={"積載品目入力"} number={2} tab={1}/>
                    <CaretDownOutlined style={{color:"gray"}}/>
                    <div style={{textAlign:"center", fontSize:12, fontWeight:600}}>完了</div>
                </div>
            </div>

        </div>
    )
}
