import React, {useContext, useEffect, useState} from 'react';
import {Modal, Button, Divider, Select, DatePicker, Checkbox} from 'antd';
import {addUser, getUsers, getWorkers, updateWorker} from "../api";
import {Store} from "../state_store/provider";
import {USERS, WORKER} from "../state_store/actionType";
import {Option} from "antd/es/mentions";
import {Role} from "../constant";
import * as yub from "yup";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {FormFormat, Request} from "../utils";
import moment from "moment";


const schema = yub.object().shape({
    id:yub.string().required(),
    name:yub.string().min(2).required(),
    password: yub.string().min(6).required(),
    mail: yub.string().email().required(),
    birthday: yub.string().required(),
    carrier_id: yub.string().required(),
    sites: yub.array().min(1, "sites is a required field").required()
    // admin_flg:yub.string().required("Role is a required field"),
    // carrier_id: yub.string().required("Company is a required field")
}).required()
export default function WorkerEdition(props) {
    const {open, onClose, onOK, data} = props
    const [workerData, setWorkerData] = useState({})
    const {state, dispatch} = useContext(Store)
    const {register, handleSubmit, control, formState: {errors}, clearErrors, setValue, reset} = useForm({
        resolver: yupResolver(schema)
    });

    const update = (value) => {
        let sites = {}
        let licenses = {}
        for(let index=0; index<6; index++){


            if(value[`q${index+1}`]){
                licenses[`licenses[${index}][id]`] = value[`q${index+1}`]
            }
            if(value[`question_${index+1}`]){
                licenses[`licenses[${index}][no]`] = value[`question_${index+1}`]
            }
        }

        value.sites?.forEach((res, index)=>{
            sites[`sites[${index}][id]`] = res
        })


        const submit_data = {
            ...value,
            old_id:data.id,
            driver:value.driver??true,
            signaler: value.signaler??true,
            work_conductor: value.work_conductor??true,
            guide_manager: value.guide_manager??true,
            birthday: moment(value.birthday).format("YYYY-MM-DD"),
            ...licenses,
            ...sites

        }
        Request(dispatch, updateWorker(FormFormat(submit_data)), onOK).then(error=>{
            if(!error){
                getWorkers().then(res=>{
                    if(res.result ===0){
                        dispatch({type:WORKER, payload:res.workerList})
                    }
                })
                reset()
            }
        })

    }

    const onChange = (e) => {
        const {name, value} = e.target

        setWorkerData(prevState => ({...prevState, [name]: value}))
    }

    useEffect(()=>{
        clearErrors()
        reset()
        for(const key in data){
            if(key === "mail")
                setValue("mail", data[key])
            else if(key === "birthday")
                setValue("birthday", moment(data["birthday"]))
            else if(key === "sites")
                setValue("sites", data.sites.map(res=>res.site_id))
            else if(key === "licenses"){
                data[key].forEach((res, index)=>{
                    setValue(`q${index+1}`, res.license_id)
                    setValue(`question_${index+1}`, res.license_no)
                })
            }
            else {
                setValue(key, data[key])
            }

        }
    },[data])


    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>作業者編集</div>
                <div className={"subtitle"}>編集する作業者情報を入力してください。</div>
                <Divider className={"divider"}/>


                <div className={"add-dialog-body"}>
                    <form id={"user-registration"} onSubmit={handleSubmit(update)}>
                        <div className={"input-title"}>ログインID</div>
                        <input type="text"
                               className={`ant-input ${errors.id?"error":""}`}
                               {...register("id")}/>
                        <div className={"helper-text-error"}>
                            {errors.id?.message}</div>

                        <div className={"input-title"}>ユーザー名</div>
                        <input type="text"
                               className={`ant-input ${errors.name?"error":""}`}
                               {...register("name")}/>
                        <div className={"helper-text-error"}>
                            {errors.name?.message}</div>

                        <div className={"input-title"}>パスワード</div>
                        <input type="password"
                               className={`ant-input ${errors.password?"error":""}`}
                               {...register("password")}/>
                        <div className={"helper-text-error"}>
                            {errors.password?.message}</div>

                        <div className={"input-title"}>メールアドレス</div>
                        <input type="text"
                               className={`ant-input ${errors.mail?"error":""}`}
                               {...register("mail")}/>
                        <div className={"helper-text-error"}>
                            {errors.mail?.message}</div>


                        <div className={"input-title"}>⽣年⽉⽇</div>

                        <Controller
                            render={({field})=>
                                <DatePicker
                                    {...field}
                                    // onChange={(date, dateString)=>onChange({target:{name:"birthday",
                                    //         value:dateString}})}
                                    placeholder={""}/>
                            }
                            control={control}
                            name={"birthday"}/>

                        <div className={"input-title"}>グループ名</div>
                        <Controller
                            control={control}
                            name={"carrier_id"}
                            render={({field})=>(
                                <Select className={`select-field`}
                                        status={errors.carrier_id?"error":""}
                                        {...field}
                                    // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                >
                                    {state.group.map(item => (
                                        <Option value={item.id}>{item.company}</Option>
                                    ))}
                                </Select>
                            )}
                        />
                        <div className={"helper-text-error"}>
                            {errors.carrier_id?.message}</div>


                        <div className={"input-title"}>現場</div>
                        <div className={"quotation"}>
                            <Controller
                                control={control}
                                render={({field})=>{
                                    return(
                                        <Checkbox.Group
                                            // disabled={company!=="000"}
                                            {...field}
                                            // onChange={(value) => onChange({target: {name: "quotation_list", value}})}
                                        >
                                            {
                                                state.site.map(res => (

                                                        <Checkbox key={res.id} value={res.id}>{res.name}</Checkbox>
                                                    )
                                                )
                                            }
                                        </Checkbox.Group>
                                    )
                                }}
                                name={"sites"}/>
                        </div>
                        <div className={"input-title"}>作業分類</div>
                        <input type="text"
                               className={`ant-input ${errors.work_class?"error":""}`}
                               {...register("work_class")}/>
                        <div className={"helper-text-error"}>
                            {errors.work_class?.message}</div>

                        {/*<Controller*/}
                        {/*    control={control}*/}
                        {/*    name={"work_class"}*/}
                        {/*    render={({field})=>(*/}
                        {/*        <Select className={`select-field `}*/}
                        {/*                {...field}*/}
                        {/*                status={errors.work_class?"error":""}*/}
                        {/*            // onChange={(value) => onChange({target: {name: "admin_flg", value}})}*/}
                        {/*        >*/}
                        {/*            {state.work_content.map((item, index) => (*/}
                        {/*                <Option value={item.id}>{item.name}</Option>*/}
                        {/*            ))}*/}
                        {/*        </Select>*/}
                        {/*    )}*/}
                        {/*/>*/}
                        {/*<div className={"helper-text-error"}>*/}
                        {/*    {errors.work_class?.message}</div>*/}

                        <div>
                            <div className={"row"}>
                                <div className={"column checkbox-role-layout"}>
                                    <div className={"input-title"}>運転者</div>
                                    <Controller
                                        control={control}
                                        render={({field})=> {

                                            return  <Checkbox

                                                {...field}
                                                checked={field.value}
                                            />
                                        }

                                        }
                                        name={"driver"}

                                    />
                                </div>
                                <div className={"column checkbox-role-layout"}>
                                    <div className={"input-title"}>作業指揮者</div>
                                    <Controller
                                        control={control}
                                        render={({field})=> <Checkbox
                                            {...field}
                                            checked={field.value}
                                        />}
                                        name={"work_conductor"}

                                    />

                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"column checkbox-role-layout"}>
                                    <div className={"input-title"}>合図者</div>
                                    <Controller
                                        control={control}
                                        render={({field})=>
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                            />}
                                        name={"signaler"}

                                    />
                                </div>
                                <div className={"column checkbox-role-layout"}>
                                    <div className={"input-title"}>誘導管理者</div>
                                    <Controller
                                        control={control}
                                        render={({field})=> <Checkbox
                                            {...field}
                                            checked={field.value}
                                        />}
                                        name={"guide_manager"}

                                    />
                                </div>
                            </div>
                        </div>


                        <div className={"input-title"}>資格1</div>
                        <Controller
                            control={control}
                            name={"q1"}
                            render={({field})=>(
                                <Select className={`select-field `}
                                        {...field}
                                        status={errors.q1?"error":""}
                                    // onChange={(value) => onChange({target: {name: "admin_flg", value}})}
                                >
                                    {state.work_content.map((item, index) => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        />
                        <div className={"helper-text-error"}>
                            {errors.q1?.message}</div>


                        <div className={"input-title"}>資格証番号１</div>
                        <input type="text"
                               className={`ant-input ${errors.question_1?"error":""}`}
                               {...register("question_1")}/>
                        <div className={"helper-text-error"}>
                            {errors.qeustion_1?.message}</div>

                        <div className={"input-title"}>資格２</div>
                        <Controller
                            control={control}
                            name={"q2"}
                            render={({field})=>(
                                <Select className={`select-field`}
                                        {...field}
                                        status={errors.q2?"error":""}
                                    // onChange={(value) => onChange({target: {name: "admin_flg", value}})}
                                >
                                    {state.work_content.map((item, index) => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        />

                        <div className={"input-title"}>資格証番号２</div>
                        <input type="text"
                               className={`ant-input ${errors.question_2?"error":""}`}
                               {...register("question_2")}/>
                        <div className={"helper-text-error"}>
                            {errors.question_2?.message}</div>


                        <div className={"input-title"}>資格３</div>
                        <Controller
                            control={control}
                            name={"q3"}
                            render={({field})=>(
                                <Select className={`select-field `}
                                        {...field}
                                        status={errors.q3?"error":""}
                                    // onChange={(value) => onChange({target: {name: "admin_flg", value}})}
                                >
                                    {state.work_content.map((item, index) => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        />

                        <div className={"input-title"}>資格証番号３</div>
                        <input type="text"
                               className={`ant-input ${errors.question_3?"error":""}`}
                               {...register("question_3")}/>
                        <div className={"helper-text-error"}>
                            {errors.question_3?.message}</div>


                        <div className={"input-title"}>資格４</div>
                        <Controller
                            control={control}
                            name={"q4"}
                            render={({field})=>(
                                <Select className={`select-field `}
                                        {...field}
                                        status={errors.q4?"error":""}
                                    // onChange={(value) => onChange({target: {name: "admin_flg", value}})}
                                >
                                    {state.work_content.map((item, index) => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        />

                        <div className={"input-title"}>資格証番号４</div>
                        <input type="text"
                               className={`ant-input ${errors.question_4?"error":""}`}
                               {...register("question_4")}/>
                        <div className={"helper-text-error"}>
                            {errors.question_4?.message}</div>


                        <div className={"input-title"}>資格５</div>
                        <Controller
                            control={control}
                            name={"q5"}
                            render={({field})=>(
                                <Select className={`select-field `}
                                        {...field}
                                        status={errors.q5?"error":""}
                                    // onChange={(value) => onChange({target: {name: "admin_flg", value}})}
                                >
                                    {state.work_content.map((item, index) => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        />

                        <div className={"input-title"}>資格証番号５</div>
                        <input type="text"
                               className={`ant-input ${errors.question_5?"error":""}`}
                               {...register("question_5")}/>
                        <div className={"helper-text-error"}>
                            {errors.question_5?.message}</div>


                        <div className={"input-title"}>資格６</div>
                        <Controller
                            control={control}
                            name={"q6"}
                            render={({field})=>(
                                <Select className={`select-field `}
                                        {...field}
                                        status={errors.q6?"error":""}
                                    // onChange={(value) => onChange({target: {name: "admin_flg", value}})}
                                >
                                    {state.work_content.map((item, index) => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        />

                        <div className={"input-title"}>資格証番号６</div>
                        <input type="text"
                               className={`ant-input ${errors.question_6?"error":""}`}
                               {...register("question_6")}/>
                        <div className={"helper-text-error"}>
                            {errors.question_6?.message}</div>

                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor: "#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        // onClick={onCreateUser}
                        htmlType={"submit"}
                        form={"user-registration"}
                        type="primary" style={{backgroundColor: "#377EF3"}}>追加する</Button>
                </div>
            </div>
        </Modal>

    )
};
