import React, {useContext} from 'react';
import {Modal, Button} from 'antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {getRoutePointName, removeRoutePointById} from "../api";
import {Store} from "../state_store/provider";
import {ROUTE_NAME} from "../state_store/actionType";
import {Request} from "../utils";

export default function RoadDeletion(props) {
    const {open, onClose, id} = props
    const {state, dispatch} = useContext(Store)
    const onDelete = ()=>{
        const siteId = state.site[state.index].id
        Request(dispatch, removeRoutePointById({siteId, id}), onClose).then(error=>{
            if(!error){
                getRoutePointName(siteId).then(item=>{
                    if(item.result===0){
                        dispatch({type:ROUTE_NAME, payload:item.routePoints})
                        onClose()
                    }
                })
            }
        })
        // removeRoutePointById({siteId, id}).then(res=>{
        //     if (res.result === 0){
        //         getRoutePointName(siteId).then(item=>{
        //             if(item.result===0){
        //                 dispatch({type:ROUTE_NAME, payload:item.routePoints})
        //                 onClose()
        //             }
        //         })
        //
        //     }
        // })
    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={450}
        >
            <div className={"dialog"}>

                <div className={"delete-dialog-body column center"}>
                    <div className={"icon"}>
                        <ExclamationCircleOutlined/>
                    </div>
                    <div className={"title"}>削除</div>
                    <div className={"subtitle"}>
                        選択している搬入経路位置を削除します。よろしいですか？

                    </div>

                </div>
                <div className={"delete-dialog-footer row center"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={onDelete}
                        type="primary" style={{backgroundColor:"#377EF3"}}>削除する</Button>
                </div>
            </div>
        </Modal>
    );
};
