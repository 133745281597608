import React, {useContext, useState} from 'react';
import {Modal, Button, Divider, Input, DatePicker, Select, Radio} from 'antd';
import {inputTruck} from "../api";
import {Store} from "../state_store/provider";
import {Request} from "../utils";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yub from "yup";


const schema = yub.object().shape({
    id:yub.string().required(),
    name:yub.string().min(2).required(),
    password: yub.string().min(6).required(),
    email: yub.string().email().required(),
    admin_flg:yub.string().required("Role is a required field"),
    carrier_id: yub.string().required("Company is a required field")
}).required()

export default function SiteManagementRegistration(props) {
    const {open, onClose, onOK, data} = props
    const [order_data, setOrder_data] = useState({on_the_truck: 0})
    const [error, setError] = useState({submit: false, message:"Vehicle Number is required"})
    const {state, dispatch} = useContext(Store)
    const {site, index} = state
    const [value, setValue] = useState(-1)
    const {register, handleSubmit, formState: {errors}, reset, control} = useForm({
        resolver: yupResolver(schema)
    });


    const onCreateOrder = ()=>{

        const data_submit = {
            ...order_data,
            site_id:site[index].id,
            order_id:data.id
        }
        if(!error.message)
            Request(dispatch, inputTruck(data_submit), onOK)
        // inputTruck(data_submit).then(res=>{
        //     if(res.result === 0){
        //         onOK()
        //     }
        // })
        setError({...error, submit: true})
    }


    const onOpenDocument = ()=>{
        // window.open("/document", "_blank");
        sessionStorage.setItem("mode", "add")
        if(value === 1){
            let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no,width=800,height=800';
            window.open('/work-plan/index', 'mypopup', features);
        }
        else if(value === 2){
            let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no,width=800,height=800';
            window.open('/ky-safe-work-instruction/index', 'mypopup', features);
        }else if(value === 3){
            let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no,width=800,height=800';
            window.open('/health-management-report/index', 'mypopup', features);
        }
    }
    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >


            <div className={"dialog"}>
                <div className={"title"}>資格追加</div>
                <div className={"subtitle"}>追加する資格情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <div className={"radio-doc"}>
                        <Radio.Group onChange={(e)=>setValue(e.target.value)} value={value}>
                            <div className={"column"}>
                                <Radio value={1}>重機作業計画書</Radio>
                                <Radio value={2}>KY安全作業指⽰書</Radio>
                                <Radio value={3}>体調管理報告書</Radio>
                            </div>

                        </Radio.Group>
                    </div>

                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={onOpenDocument}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>

            </div>
        </Modal>
    );
};
