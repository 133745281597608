import React, {useContext} from 'react';
import {Modal, Button} from 'antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {deleteTruck, getWorkers, removeWorker} from "../api";
import {Request} from "../utils";
import {Store} from "../state_store/provider";
import {WORKER} from "../state_store/actionType";


export default function WorkerDelete(props) {
    const {open, onClose, onOK, data} = props
    const {dispatch} = useContext(Store)
    const onDelete = ()=>{
        const {id} = data

        Request(dispatch, removeWorker(id), onOK).then(error=>{
            if(!error){
                getWorkers().then(res=>{
                    if(res.result ===0){
                        dispatch({type:WORKER, payload:res.workerList})
                    }
                })
            }
        })

    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>


                <div className={"delete-dialog-body column center"}>
                    <div className={"icon"}>
                        <ExclamationCircleOutlined/>
                    </div>
                    <div className={"title"}>作業者削除</div>
                    <div className={"subtitle"}>
                        この作業者を削除しますか？
                    </div>

                </div>
                <div className={"delete-dialog-footer row center"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >いいえ</Button>
                    <Button
                        onClick={onDelete}
                        type="primary" style={{backgroundColor:"#377EF3"}}>はい</Button>
                </div>
            </div>
        </Modal>
    );
};
