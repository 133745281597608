import React from 'react'
import {Status} from "../constant";



export default function OrderInfoTable(props){
    const {orderData, onSelect, id} = props


    return (<>

        <div className={"row tb-h g-tb-order"}>
            <div>状態</div>
            <div>受注番号</div>
            <div>注文日</div>
            <div>分類</div>
            <div>品目</div>
            <div>数量</div>
            <div>単位</div>
            <div>重量</div>
            <div>納品数</div>
            <div>残数</div>
            <div>手配会社</div>
            <div>納品場所</div>
        </div>
        {/*<div className={"striped"}>*/}


            {orderData.map(res => (
                <div className={`row sbd tb-li g-tb-order pointer ${res.id === id?"row-active":""}`} key={res.id}
                     onClick={()=>onSelect(res)}
                >
                    <div className={"text-wrapper"}>{Status[res.status]}</div>
                    <div className={"text-wrapper"}>{res.order_code}</div>
                    <div className={"text-wrapper"}>{res.order_time.split(" ")[0]}</div>
                    <div className={"text-wrapper"}>{res.category}</div>
                    <div className={"text-wrapper"}>{res.name}</div>
                    <div className={"text-wrapper"}>{res.amaunt}</div>
                    <div className={"text-wrapper"}>{res.unit}</div>
                    <div className={"text-wrapper"}>{res.weight}</div>
                    <div className={"text-wrapper"}>{res.amaunt_sum?res.amaunt_sum:0}</div>
                    <div className={"text-wrapper"}>{res.amaunt - res.amaunt_sum?res.amaunt_sum:0}</div>
                    <div className={"text-wrapper"}>{res.carrier_name}</div>
                    <div className={"text-wrapper"}>{res.pp_name}</div>
                </div>
            ))}
        {/*</div>*/}

    </>)
}