import React, {useContext, useEffect, useState} from 'react'
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {Collapse} from "react-collapse";

import {Store} from "../state_store/provider";
import {INDEX, SITE_TAB, TAB} from "../state_store/actionType";
import {useNavigate} from "react-router-dom";






export default function MenuSite(props){
    const [site, index] = props.site
    const navigate = useNavigate()
    const {state, dispatch} = useContext(Store)
    const [collapse, setCollapse] = useState(false)
    const [second_collapse, setSecond_collapse] = useState(false)
    const {site_tab, tab} = state

    const onSelect = ()=>{
        dispatch({type:INDEX, payload:index})
        localStorage.setItem("site_id", site.id)
        setCollapse(!collapse)

    }

    useEffect(()=>{
        if(state.index !== index){
            setCollapse(false)
            setSecond_collapse(false)
        }
    }, [state.index])

    const onChangeTab = (index)=>{
        dispatch({type:TAB, payload:index})
        dispatch({type:SITE_TAB, payload:-1})
        navigate("/ordering")


    }
    const onChangeSiteTab = (index)=>{
        dispatch({type:TAB, payload:"4"})
        dispatch({type:SITE_TAB, payload:index})
        navigate("/ordering")
    }

    return(
        <div className={"column"} style={{padding: 12, cursor:"pointer"}}>
            <div
                onClick={onSelect}
                className={"row"} style={{alignItems: "center"}}>

                {collapse?<MinusCircleOutlined/>:<PlusCircleOutlined/>}
                <div
                    className={"site-menu-item"}
                    style={{
                    marginLeft: 6
                }}>{site.name}</div>
            </div>
            <Collapse isOpened={collapse}>
                <div className={"column"}>
                    <div className={"row first-menu"}  onClick={()=>onChangeTab("1")}>

                        <div className={`site-menu-item ${tab==='1'?"active":""}`}>資材注⽂・配送</div>

                    </div>
                    <div className={"row first-menu"}  onClick={()=>onChangeTab("2")}>
                        <div className={`site-menu-item ${tab==='2'?"active":""}`}>資材配置・確認</div>

                    </div>
                    <div className={"row first-menu"}  onClick={()=>onChangeTab("3")}>
                        <div className={`site-menu-item ${tab==='3'?"active":""}`}>現場管理</div>

                    </div>

                    <div
                        onClick={()=>setSecond_collapse(!second_collapse)}

                        className={"row first-menu"}>

                        {second_collapse?<MinusCircleOutlined/>:<PlusCircleOutlined/>}

                        <div
                            className={"site-menu-item"}
                            style={{
                            marginLeft: 6
                        }}>現場情報
                        </div>
                    </div>
                    <Collapse isOpened={second_collapse}>
                        <div className={"row second-menu"} onClick={()=>onChangeSiteTab(0)}>
                            <div className={`site-menu-item ${site_tab===0?"active":""}`}>現場図面</div>

                        </div>
                        <div className={"row second-menu"} onClick={()=>onChangeSiteTab(1)}>
                            <div className={`site-menu-item ${site_tab===1?"active":""}`}>搬入位置</div>

                        </div>
                        <div className={"row second-menu"} onClick={()=>onChangeSiteTab(2)}>
                            <div className={`site-menu-item ${site_tab===2?"active":""}`}>搬入経路</div>

                        </div>
                        <div className={"row second-menu"}>
                            <div className={`site-menu-item ${site_tab===3?"active":""}`}
                                 onClick={()=>onChangeSiteTab(3)}>搬入経路位置</div>

                        </div>
                        <div className={"row second-menu"}>
                            <div className={`site-menu-item ${site_tab===4?"active":""}`}
                                 onClick={()=>onChangeSiteTab(4)}>作業重機位置</div>

                        </div>
                    </Collapse>




                </div>
            </Collapse>

        </div>
    )
}