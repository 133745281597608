import React, {useContext, useState, useEffect} from 'react';
import {Modal, Button, Divider, Input, DatePicker, Select, Checkbox} from 'antd';
import {addOrder, getOrderList} from "../api";
import {Store} from "../state_store/provider";
import {Option} from "antd/es/mentions";
import moment from "moment";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yub from "yup";
import {LOADING, ERROR, ORDER} from "../state_store/actionType";
import {Error, Loading, Request} from "../utils";
import {EDIT, EDITING} from "../constant/variables";



const schema = yub.object().shape({
    pid: yub.string().required(),
    name: yub.string().required().min(2),
    // unit: yub.string().required(),
    // weight: yub.number().required().positive(),
    amaunt: yub.number().required("Quantity is a required field").positive().typeError("Quantity must be a number"),
    carrier_id: yub.string().required("Company is a required field"),
    order_time:yub.date().min((new Date()).toDateString()).required(),
    // category:yub.string().required()
}).required()
export default function OrderDuplication(props) {
    const {open, onClose, onOK, data} = props

    const {state, dispatch} = useContext(Store)
    const {register, handleSubmit, formState: {errors}, reset, control, setValue, clearErrors} = useForm({
        resolver: yupResolver(schema)
    });


    const onCreateOrder = (submit_data)=>{
        const order_data = {
            ...data, ...submit_data,

            order_time: moment(submit_data.order_time).format("YY-MM-DD hh:mm:ss"),
        }
        delete order_data["id"];
        onClose()
        dispatch({type:LOADING, payload:true})
        Request(dispatch, addOrder(order_data), onOK).then(error=>{
            if(!error){
                getOrderList(order_data.site_id).then(res=>{
                    dispatch({type:ORDER, payload:res.orders})
                })
            }
        })
        // addOrder(order_data).then(res=>{
        //     Error(dispatch, res, onOK)
        //
        //
        // })
    }

    useEffect(()=>{
        clearErrors()
        for(const key in data){
            if(key === "order_time")
                setValue(key, moment(data[key]))
            else
                setValue(key, data[key])
        }
    },[data])

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >


            <div className={"dialog"}>
                <div className={"title"}>注⽂情報を追加</div>
                <div className={"subtitle"}>追加する注⽂情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"order_edition"} onSubmit={handleSubmit(onCreateOrder)}>

                        <div className={"input-title"}>納品場所</div>

                        <Controller
                            control={control}
                            render={({ field })=>{
                                return (
                                    <Select className={"select-field"}
                                            status={errors.pid?"error":""}
                                            {...field}

                                        // onChange={(value) => onChange({target: {name: "pid", value}})}
                                    >
                                        {state.point.map((item, index) => (
                                            <Option value={item.id}
                                                    key={item.id}
                                            >{item.name}</Option>
                                        ))}
                                    </Select>
                                )
                            }}
                            name={"pid"}
                        />

                        <div className={"input-title"}>注⽂⽇</div>
                        <Controller
                            control={control}
                            render={({field})=>{
                                return (
                                    <DatePicker
                                        status={errors.order_time?"error":""}
                                        {...field}
                                        // onChange={(date, dateString) => onChange({
                                        //     target: {
                                        //         name: "order_time",
                                        //         value: dateString
                                        //     }
                                        // })}
                                        placeholder={""}/>
                                )
                            }}
                            name={"order_time"}/>
                        <div className={"helper-text-error"}>
                            {errors.order_time?.message}</div>



                        <div className={"input-title"}>分類</div>
                        <input type="text"
                               className={`ant-input`}
                               {...register("category")}
                        />


                        <div className={"input-title"}>品⽬</div>
                        <input type="text"
                               className={`ant-input ${errors.name?"error":""}`}
                               {...register("name")}
                        />
                        <div className={"helper-text-error"}>
                            {errors.name?.message}</div>

                        <div className={"input-title"}>数量</div>
                        <input type="number"
                               step="0.01"
                               className={`ant-input ${errors.amaunt?"error":""}`}
                               {...register("amaunt")}
                        />
                        <div className={"helper-text-error"}>
                            {errors.amaunt?.message}</div>

                        <div className={"input-title"}>単位</div>
                        <input type="text"
                               className={`ant-input`}
                               {...register("unit")}
                        />

                        <div className={"input-title"}>重量 (kg)</div>
                        <input type="text"
                               className={`ant-input`}
                               {...register("weight")}
                        />

                        <div className={"input-title"}>⼿配会社</div>

                        <Controller
                            control={control}
                            render={({field})=>{
                                return (
                                    <Select className={"select-field"}
                                            status={errors.carrier_id?"error":""}
                                            {...field}
                                        // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                    >
                                        {state.group.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.id}>{item.company}</Option>
                                        ))}
                                    </Select>
                                )
                            }}
                            name={"carrier_id"}

                        />
                        <div className={"helper-text-error"}>
                            {errors.carrier_id?.message}</div>

                    </form>


                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        form={"order_edition"}
                        htmlType={"submit"}
                        type="primary" style={{backgroundColor:"#377EF3"}}>{EDIT}</Button>
                </div>

            </div>
        </Modal>
    );
};
