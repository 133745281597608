import React, {useContext} from 'react'
import logo from "../asset/logo.png";
import {AppbarMenuH, AppbarMenuV} from "./appbar_menu";
import home from "../asset/home.png";
import home_select from '../asset/home_selected.png'
import setup from "../asset/setup.png";
import setup_select from '../asset/setup_selected.png'
import work from "../asset/work.png";
import work_select from '../asset/work_selected.png'
import account from "../asset/account.png";
import logout from "../asset/logout.png";
import {useLocation, useNavigate} from "react-router-dom";
import {Store} from "../state_store/provider";
import {Role} from "../constant";



export default function AppBar(){
    const {state} = useContext(Store)
    const navigate = useNavigate()
    const {pathname} = useLocation();
    const onLogout = ()=>{
        window.location.href = "/login/logout"
    }
    return(
        <div className={["appbar-layout"]}>
            <div className={["appbar"]}>
                <div className={"row"} style={{justifyContent: "center", alignItems: "center"}}>
                    <div className={"logo"}>
                        <img alt={"no image"} src={logo}/>
                    </div>
                    <div className={"appbar-menu"}>
                        <AppbarMenuV icon={pathname==="/"?home_select:home}
                                     className={pathname==="/"?"active-route":""}
                                     title={"メイン"} onClick={()=>navigate("/")}/>
                        <AppbarMenuV icon={pathname==="/maintenance"?setup_select:setup}
                                     className={pathname==="/maintenance"?"active-route":""}
                                     title={"メンテナンス"} onClick={()=>navigate("/maintenance")}/>
                        <AppbarMenuV icon={pathname==="/work"?work_select:work} title={"work"}
                                     className={pathname==="/work"?"active-route":""}
                                     onClick={()=>navigate("/work")}/>
                    </div>
                </div>


                <div className={"appbar-menu"}>
                    <AppbarMenuH icon={account} title={`${state.user.name} ( ${Role[state.user.admin_flg]} ) `} style={{flexDirection: "row"}}/>
                    <AppbarMenuV icon={logout} title={"ログアウト"} onClick={()=>onLogout()}/>
                </div>

            </div>


        </div>
    )
}
