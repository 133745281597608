import React, {useContext, useEffect, useState} from 'react'
import {SearchOutlined} from "@ant-design/icons";
import addition from '../../../asset/addition.png'
import edition from '../../../asset/edit.png'
import deletion from '../../../asset/deletion.png'
import truck_check from '../../../asset/truck_check.png'
import scale from '../../../asset/scale.png'
import {Divider, Input, Select} from "antd";
import {Option} from "antd/es/mentions";
import Checkbox from "antd/es/checkbox/Checkbox";
import Top from '../../../asset/top.png'
import Down from '../../../asset/bottom.png'
import Copy from '../../../asset/copy.png'
import Delivery from '../../../asset/delivery_company.png'
import BusinessOperator from '../../../asset/business_operator.png'
import Document from '../../../asset/document.png'
import Move from '../../../asset/move.png'
import {
    getDriver,
    getGroup,
    getOrderList,
    getPlanPointList,
    getTruck,
    getTruckLoading, getTruckLoadingInfo, moveTruckLoading
} from "../../../api";
import {Store} from "../../../state_store/provider";
import {GROUP, ORDER, POINT} from "../../../state_store/actionType";
import OrderAddition from "../../../component/order_addition";


import OrderEdition from "../../../component/order_edition";
import OrderDelete from "../../../component/order_delete";
import VehicleAddition from "../../../component/vehicle_addition";
import Warning from "../../../component/warning";
import VehicleEdition from "../../../component/vehicle_edition";
import VehicleDelete from "../../../component/vehicle_delete";
import TruckAddition from "../../../component/truck_addition";
import TruckEdition from "../../../component/truck_edition";
import TruckDelete from "../../../component/truck_delete";
import Map from "../../../component/map_view";
import OrderDuplication from "../../../component/order_duplication";
import OrderInfoTable from "../../../component/order_info_table";

function TableIconButton({icon, title, onClick, size}) {
    return (

        <div className={"column center user-list-icon-btn"}
             onClick={onClick}
        >
            <img src={icon} alt={"no image"} style={{width:size??size, height:size??size}}/>
            <div className={"btn-title"}>{title}</div>
        </div>
    )
}

const initTruckLoadingData = {open:false, data:{}, open_edit:false, open_delete:false}
export default function OrderInfo() {
    const [expand, setExpand] = useState(0)
    const [reloadKey, setReloadKey] = useState(0)
    const {state, dispatch} = useContext(Store)
    const [openAddition, setOpenAddition] = useState(false)
    const [openEdition, setOpenEdition] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [selected, setSelected] = useState({})
    const [filterOrder, setFilterOrder] = useState([])
    const [truck, setTruck] = useState([])
    const [truckLoading, setTruckLoading] = useState([])
    const [driver, setDriver] = useState([])
    const [warning, setWarning] = useState(false)
    const [title, setTitle] = useState("")
    const [openVehicleAddition, setOpenVehicleAddition] =useState(false)
    const [openVehicleEdition, setOpenVehicleEdition] =useState(false)
    const [openVehicleDelete, setOpenVehicleDelete] =useState(false)
    const [truckLoadingData, setTruckLoadingData] = useState(initTruckLoadingData)
    const [truckInfo, setTruckInfo] = useState({})
    const [duplication, setDuplication] = useState(false)
    const [nameFilter, setNameFilter] = useState("")
    const {site, index} = state
    const [vehicleSelected, setVehicleSelected] = useState({})
    const [filter, setFilter] = useState({company:"_default", checked:false})
    useEffect(()=>{

        if(site.length > 0) {
            const id = site[index]?.id



            if (id) {
                getPlanPointList(id).then(res => {
                    dispatch({type: POINT, payload: res.plan_points})
                })

                getOrderList(id).then(res => {
                    if(res.result === 0){
                        dispatch({type: ORDER, payload: res.orders})
                        setFilterOrder(res.orders)
                    }

                })

                // }else {
                //     setFilterOrder(state.order)
                // }
            }

        }
        if (state.group.length === 0) {
            getGroup().then(res => {
                dispatch({type: GROUP, payload: res.groupList})
            })
        }



    },[state.index, state.site])



    useEffect(()=>{
        onChangeCompany(filter.company)
    }, [state.order])

    useEffect(()=>{
        resetTruckData()
        setVehicleSelected({})
        setTruck([])
        setSelected({})
    },[state.index])

    const onChangeCheckBox = (e)=>{
        const {checked} = e.target
        setFilter({...filter, checked:checked})
    }
    useEffect(()=>{
        onChangeCompany(filter.company)
    }, [filter.checked, nameFilter])
    const onChangeCompany =(e)=>{
        setFilter({...filter, company:e})
        let nameFilterOrder = state.order.filter(res=>!nameFilter|res.name.toLowerCase().includes(nameFilter.toLowerCase()))
        if(e === "_default"){
            setFilterOrder(nameFilterOrder.filter((res=>(res.status===0) || !filter.checked)))
        }else {
            setFilterOrder(nameFilterOrder.filter(res=> ((res.status===0) || !filter.checked) && (res.carrier_name === e) ))
        }

    }

    const onSelect = (res)=>{

        const {id} = res
        if(id !== selected.id){
            setVehicleSelected({})
        }
        getDriver(id).then(res=>{
            setDriver(res.driver)
        })
        getTruck(id).then(res=>{
            setTruck(res.trucks)
        })
        if(id !== selected.id)
            resetTruckData()
        setSelected(res)

    }

    const onNameFilter = (e) => {
        setNameFilter(e.target.value)
    }

    const onVehicleSelected = (res)=>{
        setVehicleSelected(res)
        getTruckLoading(res.id).then(res=>{
            if(res.result === 0){
                setTruckLoading(res.loadings)
            }
        })

        getTruckLoadingInfo(res.id).then(res=>{
            if(res.result === 0){
                setTruckInfo(res)
            }
        })
    }

    const onTruckLoadSelected = (res)=>{
        setTruckLoadingData({...truckLoadingData, data: res})
    }

    const resetTruckData = ()=>{
        setTruckLoadingData(initTruckLoadingData)
        setTruckLoading([])
        setTruckInfo({})
    }


    const onTruckUpdated =(delete_status = false)=>{
        setOpenVehicleAddition(false)
        setOpenVehicleEdition(false)
        setOpenVehicleDelete(false)
        getTruck(selected.id).then(res=>{
            setTruck(res.trucks)
        })
        if(delete_status){
            resetTruckData()
        }
    }
    const onMoveTruckLoading = (truck_id, old, newId) =>{
        if(newId in [0, truckLoading.length])
            moveTruckLoading(`${vehicleSelected.id}/${old}/${newId}`).then(res=>{
                if(res.result === 0){
                    setTruckLoadingData(initTruckLoadingData)
                    onVehicleSelected(vehicleSelected)
                }
            })
    }


    const onTruckLoadingUpdated = ()=>{
        setTruckLoadingData({...truckLoadingData, open: false, open_edit: false, open_delete: false})

        getTruckLoading(vehicleSelected.id).then(res=>{
            if(res.result === 0){
                setTruckLoading(res.loadings)
            }
        })
    }



    return (
        <div className={"column"}>
            {
                [0,1].includes(expand)?<div className={`order_info`} >
                    <div className={"wrapper"}>

                        <div className={"header row"}>
                            <div className={"row center"}>
                                <div className={"icon"}><img src={Document} width={14} alt={""}/></div>
                                <div className={"title"}>注文情報</div>
                            </div>
                            <div className={"row center"}>
                                <TableIconButton icon={Copy} title={"複製"}
                                    onClick={()=>setDuplication(!!selected.id)
                                    }
                                />
                                <TableIconButton icon={addition} title={"追加"}
                                                 onClick={()=>setOpenAddition(true)}
                                />
                                <TableIconButton icon={edition} title={"編集"}
                                                 onClick={()=>setOpenEdition(!!selected.id)}
                                />
                                <TableIconButton icon={deletion} title={"削除"}
                                                 onClick={()=>setOpenDelete(!!selected.id)}
                                />
                            </div>
                        </div>


                        <Divider className={"divider"}/>
                    </div>
                    <div className={"row search"}>
                        <Select defaultValue={"_default"} className={"select"} onChange={onChangeCompany}>
                            <Option value="_default">全ての手配会社</Option>
                            {
                                state.group.map(res=>{
                                    return (<Option value={res.company}>{res.company}</Option>)
                                })
                            }

                        </Select>
                        <Checkbox className={"checkbox"} onChange={onChangeCheckBox}>未納品の資材のみ表⽰</Checkbox>
                        <Input className={"search-input"}
                               onChange={(e)=>onNameFilter(e)}
                               suffix={<SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/>}
                        />

                    </div>

                    <div className={`column order-table table-wrapper ${expand===1?"expand-height":""}`}>
                        <OrderInfoTable onSelect={onSelect} id={selected.id} orderData={filterOrder}/>


                    </div>
                    <div className={"expand-btn-w"} onClick={()=>setExpand(expand===0?1:0)}>
                        <img src={scale} className={"expand-icon"}/>
                    </div>


                </div>:null
            }

            {
                expand===1? null : <div className={"row md card-wrapper"}>
                    {[0, 2].includes(expand)? <div className={`vehicle card column ${expand===2?"mini-card-expand":""}`}>
                        <div className={"row card_header"}>
                            <div className={"row center"}>
                                <div className={"icon"}><img src={BusinessOperator} width={16}/></div>
                                <div className={"title"}>配⾞</div>
                            </div>
                            <div className={"row center"}>
                                <TableIconButton icon={addition} title={"追加"}
                                                 onClick={()=>{
                                                     if (selected.id){
                                                         setOpenVehicleAddition(true)
                                                     }else {
                                                         setWarning(true)
                                                         setTitle("注文情報が選択されていません。")
                                                     }

                                                 }}/>
                                <TableIconButton icon={edition} title={"編集"}
                                                 onClick={()=>{
                                                     if (vehicleSelected.id){
                                                         setOpenVehicleEdition(true)
                                                     }else {
                                                         setWarning(true)
                                                         setTitle("配車情報が選択されていません。")
                                                     }

                                                 }}
                                />
                                <TableIconButton icon={deletion} title={"削除"}
                                                 onClick={()=>{
                                                     if (vehicleSelected.id){
                                                         setOpenVehicleDelete(true)
                                                     }else {
                                                         setWarning(true)
                                                         setTitle("配車情報が選択されていません。")
                                                     }

                                                 }}
                                />
                            </div>

                        </div>
                        <div className={"divider-wrapper"}>
                            <Divider className={"divider"}/>
                        </div>
                        <div className={`content`}>
                            <div className={"column table-wrapper"}>
                                <div className={"row table-header g-vehicle-load"}>
                                    <div>状態</div>
                                    <div>受注番号</div>
                                </div>
                                <div className={`striped ${expand !== 2 ? "f-mini-content-wrapper" : "f-expand-content-wrapper"}`}>


                                {
                                    truck.map(res=>(

                                            <div className={` ${res.id===vehicleSelected.id?"row-active": ""}`}>
                                                <div className={`g-vehicle-load sbd st-12 pointer`}
                                                     onClick={()=>onVehicleSelected(res)}
                                                >
                                                    <div className={"row item-center"}>
                                                        <img src={res.time_complete?truck_check:deletion}/>
                                                        <div className={"text-wrapper"}>{res.vehicle_number}</div>
                                                    </div>

                                                    <div className={"column"}>
                                                        <div className={"text-wrapper"}>{res.carrier_name?res.carrier_name:"Null"}</div>
                                                        <div className={"text-wrapper"}>{`予定 : ${res.time_plan}`}</div>
                                                        <div className={"text-wrapper"}>{`完了 : ${res.time_complete}`}</div>
                                                    </div>

                                                </div>
                                            </div>




                                    ))
                                }
                                </div>



                            </div>
                            <div className={"expand-btn-w"} onClick={()=>setExpand(expand===0?2:0)}>
                                <img src={scale} className={"expand-icon"}/>
                            </div>
                        </div>

                    </div>:null}
                    {[0, 3].includes(expand)? <div className={`load-item card column column ${expand===3? "mini-card-expand" : ""}`}>
                        <div className={"row card_header"}>

                            <div className={"row center"}>
                                <div className={"icon"}><img src={Delivery} width={16}/></div>
                                <div className={"title"}>積載品⽬</div>
                            </div>
                            <div className={"row center"}>
                                <TableIconButton icon={addition} title={"追加"}
                                                 onClick={()=>{
                                                     if (vehicleSelected.id){
                                                         setTruckLoadingData({...truckLoadingData, open: true})
                                                     }else {
                                                         setWarning(true)
                                                         setTitle("積載品目情報が選択されていません。")
                                                     }
                                                 }}/>
                                <TableIconButton icon={edition} title={"編集"}
                                                 onClick={()=>{
                                                     if (truckLoadingData.data.id){
                                                         setTruckLoadingData({...truckLoadingData, open_edit: true})
                                                     }else {
                                                         setWarning(true)
                                                         setTitle("積載品目情報が選択されていません。")
                                                     }
                                                 }}
                                />
                                <TableIconButton icon={deletion} title={"削除"}
                                                 onClick={()=>{
                                                     if (truckLoadingData.data.id){
                                                         setTruckLoadingData({...truckLoadingData, open_delete: true})
                                                     }else {
                                                         setWarning(true)
                                                         setTitle("積載品目情報が選択されていません。")
                                                     }
                                                 }}
                                />
                            </div>


                        </div>
                        <div className={"row sub-icon"} >

                            <TableIconButton icon={Top} title={"上へ"} size={12}
                                             onClick={()=>{
                                                 if (truckLoadingData.data.id){
                                                     const {id, index} = truckLoadingData.data
                                                     onMoveTruckLoading(id, index, index-1)
                                                 }else {
                                                     setWarning(true)
                                                     setTitle("積載品目情報が選択されていません。")
                                                 }
                                             }}
                            />
                            <TableIconButton icon={Down} title={"下へ"} size={12}
                                             onClick={()=>{
                                                 if (truckLoadingData.data.id){

                                                     const {id, index} = truckLoadingData.data
                                                     onMoveTruckLoading(id, index, index+1)
                                                 }else {
                                                     setWarning(true)
                                                     setTitle("積載品目情報が選択されていません。")
                                                 }
                                             }}
                            />
                        </div>
                        <div className={"divider-wrapper"}>
                            <Divider className={"divider"}/>
                        </div>
                        <div className={"content"}>
                            <div className={"column table-wrapper"}>
                                {/*<div className={"row table-header truck-row-alignment"}>*/}
                                {/*    <div>品⽬</div>*/}
                                {/*    <div>数量</div>*/}
                                {/*    <div>位置</div>*/}
                                {/*</div>*/}
                                <div className={"row tb-h g-load-item"}>
                                    <div>品⽬</div>
                                    <div>数量</div>
                                    <div>位置</div>
                                </div>
                                <div className={`${expand !== 3 ? "mini-content-wrapper" : "expand-content-wrapper"}`}>

                                {
                                    truckLoading.map((res, index)=>(



                                            <div className={`g-load-item sbd tb-li pointer ${index === truckLoadingData.data.index?"row-active": ""}`}
                                                 onClick={()=>onTruckLoadSelected({...res, index:index})}
                                            >
                                                <div className={"text-wrapper"}>
                                                    {res.name}
                                                </div>
                                                <div>{res.amaunt}</div>
                                                <div className={"text-wrapper"}>
                                                    {res.position}
                                                </div>

                                            </div>


                                        // <div className={`row table-li vehicle-load truck-row-alignment
                                        // ${index === truckLoadingData.data.index? "row-active":null}`}
                                        //      key={index}
                                        //      // style={index===truckLoadingData.data.index ?{backgroundColor:"#F0F1F3",
                                        //      //     fontWeight:600,
                                        //      //     color:"#1890ff"}:{}}
                                        //      onClick={()=>onTruckLoadSelected({...res, index:index})}
                                        // >
                                        //
                                        //     <div className={"vehicle-status"}>
                                        //         {res.name}
                                        //     </div>
                                        //     <div style={{
                                        //         borderLeft:"2px solid #F7F7F7"
                                        //     }}>{res.amaunt}</div>
                                        //     <div className={"vehicle-li"}>
                                        //         {res.position}
                                        //     </div>
                                        // </div>
                                    ))
                                }
                                </div>

                            </div>
                            <div className={"expand-btn-w"} onClick={()=>setExpand(expand===0?3:0)}>
                                <img src={scale} className={"expand-icon"}/>
                            </div>
                        </div>
                    </div>:null}
                    {[0, 4].includes(expand)? <div className={`travel-route column ${expand===4? "mini-card-expand" : ""}`}>
                        <div className={"row card_header"} style={{marginBottom:6, marginTop:6}}>
                            <div className={"row center"}>
                                <div className={"icon"}><img src={Move} width={16}/></div>
                                <div className={"title"}>移動経路</div>
                            </div>
                            <div className={"row center"}>
                                <div className={"icon-title"}>移動距離 <span>：̶ m</span></div>
                            </div>
                        </div>
                        <div className={"divider-wrapper"}>
                            <Divider className={"divider"}/>
                        </div>
                        <div className={"content"}>
                            <div className={"row table-header"} style={{justifyContent:"flex-start"}}>
                                <div style={{width:"unset"}}>移動経路</div>
                                <div style={{width:"unset"}}>{truckInfo?.distance?truckInfo.distance:0}m</div>
                            </div>
                            {(vehicleSelected.id)?<Map key={reloadKey} id={"order_map"} container_id={"order_container"}/>:""}
                            <div className={"expand-btn-w"} style={{bottom:-28}} onClick={()=>{
                                setExpand(expand===0?4:0)
                                setReloadKey(reloadKey + 1)
                            }}>
                                <img src={scale} className={"expand-icon"}/>
                            </div>
                        </div>
                    </div>:null}
                </div>

            }




        <OrderAddition
        open={openAddition}
        onOK={()=>setOpenAddition(false)}
        onClose={()=>setOpenAddition(false)}
        />

        <OrderEdition
        open={openEdition}
        onOK={()=>setOpenEdition(false)}
        onClose={()=>setOpenEdition(false)}
        data={selected}
        />
            <OrderDuplication
                open={duplication}
                onClose={()=>setDuplication(false)}
                onOK={()=>setDuplication(false)}
                data={selected}
            />
        <OrderDelete
            open={openDelete}
            onOK={()=>setOpenDelete(false)}
            onClose={()=>setOpenDelete(false)}
            data={selected}
        />

        <VehicleAddition
        open={openVehicleAddition}
        onOK={onTruckUpdated}
        onClose={()=>setOpenVehicleAddition(false)}
        data={selected}
        driver={driver}
        />
        <VehicleEdition
            open={openVehicleEdition}
            onOK={onTruckUpdated}
            onClose={()=>setOpenVehicleEdition(false)}
            data={selected}
            driver={driver}
            vehicle={vehicleSelected}
        />
        <VehicleDelete
            open={openVehicleDelete}
            onOK={()=>onTruckUpdated(true)}
            onClose={()=>setOpenVehicleDelete(false)}
            vehicle={vehicleSelected}
        />

            <TruckAddition
            open={truckLoadingData.open}
            onClose={()=>setTruckLoadingData({...truckLoadingData, open: false})}
            onOK={onTruckLoadingUpdated}
            data={vehicleSelected}
            order_selected={selected}
            />

            <TruckEdition
            open={truckLoadingData.open_edit}
            onClose={()=>setTruckLoadingData({...truckLoadingData, open_edit: false})}
            onOK={onTruckLoadingUpdated}
            vehicle_selected={vehicleSelected}
            order_selected={selected}
            data={truckLoadingData.data}
            />

            <TruckDelete
                open={truckLoadingData.open_delete}
                onClose={()=>setTruckLoadingData({...truckLoadingData, open_delete: false})}
                onOK={onTruckLoadingUpdated}
                data={truckLoadingData.data}
            />


        <Warning
            open={warning}
            onClose={()=>setWarning(false)}
            title={title}
        />
        </div>

    )
}



