export const initialState = {
    user:{},
    site:[],
    group:[],
    users:[],
    order:[],
    worker:[],
    work_content:[],
    heavy_machine: [],
    license: [],
    health_management:[],
    heavy_machine_point: [],
    management_history: [],
    selected_company:"_default",
    point:[],
    index: -1,
    placement_point:{},
    tab:"1",
    site_tab: 0,
    route_name:[],
    loading: false,
    error: {}
}
