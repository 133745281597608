import React from 'react';
import {Modal, Button} from 'antd';


export default function RoadDialog(props) {
    const {open, onClose, data} = props

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={450}
        >
            <div className={"dialog"}>


                <div className={"delete-dialog-body column center"}>
                    <div>
                        {data?<img style={{width: "100%"}} src={data.target.image_src}/>:""}

                    </div>

                </div>
                <div className={"delete-dialog-footer row center"}>

                    <Button
                        onClick={onClose}
                        type="primary" style={{backgroundColor:"#377EF3"}}>閉じる</Button>
                </div>
            </div>
        </Modal>
    );
};
