import React, {useContext, useEffect, useState} from 'react';
import {Modal, Button, Divider, DatePicker, Select, Checkbox} from 'antd';
import {addOrder, getOrderList} from "../api";
import {Store} from "../state_store/provider";

import {Option} from "antd/es/mentions";
import * as yub from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import moment from "moment";
import {Request} from "../utils";
import {ORDER} from "../state_store/actionType";


const schema = yub.object().shape({
    pid: yub.string().required(),
    name: yub.string().required().min(2),
    // unit: yub.string().required(),
    // weight: yub.number().required().positive(),
    quantity: yub.number().required("Quantity is a required field").positive().typeError("Quantity must be a number"),
    carrier_id: yub.string().required("Company is a required field"),
    order_time:yub.date().min((new Date()).toDateString()).required(),
    // category:yub.string().required()
}).required()

export default function OrderAddition(props) {
    const {open, onClose, onOK, pid} = props
    const {state, dispatch} = useContext(Store)
    const {site, index} = state
    const [company, setCompany] = useState("")
    const {register, handleSubmit, formState: {errors }, control, setValue} = useForm({
        resolver: yupResolver(schema)
    });

    const onCreateOrder = (orderDataForm) => {
        const data = {
            ...orderDataForm, amaunt: orderDataForm.quantity,
            arrMitsumoriComp: orderDataForm.quotation_list?.join(","),
            site_id: site[index].id,
            id: undefined,
            carrier_name_hidden: '',
            status: undefined,
            order_time: moment(orderDataForm.order_time).format("YY-MM-DD hh:mm:ss")
        }
        Request(dispatch, addOrder(data), onOK).then(error=>{
            if(!error){
                getOrderList(site[index].id).then(res=>{
                    dispatch({type:ORDER, payload:res.orders})
                })
            }
        })
    }

    useEffect(() => {
        if (pid) {
            // setOrder_data({...order_data, pid})
            setValue("pid", pid)
        }

    }, [pid])
    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >


            <div className={"dialog"}>
                <div className={"title"}>注⽂情報を追加</div>
                <div className={"subtitle"}>追加する注⽂情報を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>
                    <form id={"order_addition"} onSubmit={handleSubmit(onCreateOrder)}>
                        {!pid ? (
                            <>
                                <div className={"input-title"}>納品場所</div>

                                    <Controller
                                        control={control}
                                        render={({ field })=>{
                                            return (
                                                <Select className={"select-field"}
                                                        status={errors.pid?"error":""}
                                                        {...field}
                                                        // onChange={(value) => onChange({target: {name: "pid", value}})}
                                                >
                                                    {state.point.map((item, index) => (
                                                        <Option value={item.id}
                                                        key={item.id}
                                                        >{item.name}</Option>
                                                    ))}
                                                </Select>
                                            )
                                        }}
                                        name={"pid"}
                                    />
                                <div className={"helper-text-error"}>
                                    {errors.pid?.message}</div>

                            </>
                        ) : ""}


                        <div className={"input-title"}>注⽂⽇</div>
                        <Controller
                            control={control}
                            render={({field})=>{
                                return (
                                    <DatePicker
                                        status={errors.order_time?"error":""}
                                        {...field}
                                        // onChange={(date, dateString) => onChange({
                                        //     target: {
                                        //         name: "order_time",
                                        //         value: dateString
                                        //     }
                                        // })}
                                        placeholder={""}/>
                                )
                            }}
                            name={"order_time"}/>
                        <div className={"helper-text-error"}>
                            {errors.order_time?.message}</div>



                        <div className={"input-title"}>分類</div>
                        <input type="text"
                               className={`ant-input`}
                               {...register("category")}
                               />


                        <div className={"input-title"}>品⽬</div>
                        <input type="text"
                               className={`ant-input ${errors.name?"error":""}`}
                               {...register("name")}
                               />
                        <div className={"helper-text-error"}>
                            {errors.name?.message}</div>

                        <div className={"input-title"}>数量</div>
                        <input type="number"
                               step="0.01"
                               className={`ant-input ${errors.quantity?"error":""}`}
                               {...register("quantity")}
                               />
                        <div className={"helper-text-error"}>
                            {errors.quantity?.message}</div>

                        <div className={"input-title"}>単位</div>
                        <input type="text"
                               className={`ant-input`}
                               {...register("unit")}
                               />

                        <div className={"input-title"}>重量 (kg)</div>
                        <input type="text"
                               className={`ant-input`}
                               {...register("weight")}
                               />

                        <div className={"input-title"}>⼿配会社</div>

                        <Controller
                            control={control}
                            render={({field})=>{
                                return (
                                    <Select className={"select-field"}
                                            status={errors.carrier_id?"error":""}
                                            {...field}
                                            onChange={(e)=>{field.onChange(e); setCompany(e)} }
                                            // onChange={(value) => onChange({target: {name: "carrier_id", value}})}
                                    >
                                        {state.group.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.id}>{item.company}</Option>
                                        ))}
                                    </Select>
                                )
                            }}
                            name={"carrier_id"}

                        />
                        <div className={"helper-text-error"}>
                            {errors.carrier_id?.message}</div>

                        <div className={"input-title"}>見積依頼：</div>
                        <div className={"quotation"}>
                            <Controller
                                control={control}
                                render={({field})=>{
                                    return(
                                        <Checkbox.Group
                                            disabled={company!=="000"}
                                            {...field}
                                            // onChange={(value) => onChange({target: {name: "quotation_list", value}})}
                                        >
                                            {
                                                state.group.map(res => (

                                                    res.id!=="000"?<Checkbox key={res.id} value={res.id}>{res.company}</Checkbox>:null
                                                    )
                                                )
                                            }
                                        </Checkbox.Group>
                                    )
                                }}
                                name={"quotation_list"}/>
                        </div>

                    </form>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor: "#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        htmlType={"submit"}
                        form={"order_addition"}
                        type="primary" style={{backgroundColor: "#377EF3"}}>追加する</Button>
                </div>

            </div>
        </Modal>
    );
};
