import React, {useContext} from 'react'
import {Tabs} from "antd";
import OrderInfo from "./tab/order_info";
import Material from "./tab/material";
import SiteInfo from "./tab/site_info";
import {Store} from "../../state_store/provider";
import {TAB} from "../../state_store/actionType";
import SiteManagement from "./tab/site_management";
const { TabPane } = Tabs;

export default function MaterialOrdering(){
    const {state, dispatch} = useContext(Store)

    return(
        <div>
            <div className={"ordering"} >
                <Tabs activeKey={state.tab} onTabClick={(e)=>dispatch({type:TAB, payload:e})}>
                    <TabPane tab="資材注⽂‧配送" key="1" >
                        <OrderInfo/>
                    </TabPane>
                    <TabPane tab="資材配置‧確認" key="2">
                        <Material/>
                    </TabPane>
                    <TabPane tab="現場管理" key="3">
                        <SiteManagement/>
                    </TabPane>
                    <TabPane tab="現場情報" key="4">
                        <SiteInfo/>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}
