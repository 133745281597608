import React from 'react';
import {Modal, Button} from 'antd';
import {CheckCircleOutlined} from "@ant-design/icons";


export default function Success(props) {
    const {open, onClose, title} = props


    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>


                <div className={"delete-dialog-body column center"}>
                    <div className={"icon"}>
                        <CheckCircleOutlined style={{color:"#17AC17"}}/>
                    </div>
                    <div className={"title"} style={{fontSize:18}}>{title?title:"ステータスを更新しました"}</div>


                </div>
                <div className={"delete-dialog-footer row center"}>

                    <Button
                        onClick={onClose}
                        type="primary" style={{backgroundColor:"#377EF3"}}>OK</Button>
                </div>
            </div>
        </Modal>
    );
};
