import axios from "axios";


    var logList = [];


    const ajaxLog = function(url) {

        axios.post(url).then( ({data}) =>{
            if(data.result === 0){
                data.logList?.forEach(addLogRow)
                downloadCsv(logList);
                logList = []
            }
        })

    };

    /**
     * GPSログリストを取得する
     */
    export const ajaxGetLog = function(data) {
        console.log(data)
        let {userId, latFrom, latTo, lonFrom, lonTo, timeFrom, timeTo } = data

        // time編集
        if (!timeFrom) {
            timeFrom = timeFrom + " 00:00:00";
        }
        if (!timeTo) {
            timeTo = timeTo + " 23:59:59";
        }

        return ajaxLog( '/work/getLog/' + userId
            + '/' + timeFrom + '/' + timeTo + '/' + latFrom + '/' + latTo + '/' + lonFrom + '/' + lonTo, data );
    };


    /**
     * GPSログをテーブルに追加する
     * @param {Object} user adding user info
     */
    const addLogRow = function(log) {
        var logRow = [];
        logRow.push(log.user_id);
        logRow.push(log.lat);
        logRow.push(log.lon);
        logRow.push(log.time);

        logList.push(logRow);
    };

    var downloadCsv = (function() {

        var tableToCsvString = function(table) {
            var str = '\uFEFF';
            for (var i = 0, imax = table.length - 1; i <= imax; ++i) {
                var row = table[i];
                for (var j = 0, jmax = row.length - 1; j <= jmax; ++j) {
                    str += row[j];
                    //str += '"' + row[j].replace('"', '""') + '"';
                    if (j !== jmax) {
                        str += ',';
                    }
                }
                str += '\n';
            }
            return str;
        };

        var createDataUriFromString = function(str) {
            // 2020-04-22 FUJITA mod-start
            // return 'data:text/csv,' + encodeURIComponent(str);
            var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            // var blob = new Blob([bom, str], { type: 'text/csv' });
            var blob = new Blob(['', str], { type: 'text/csv' });
            var url = (window.URL || window.webkitURL).createObjectURL(blob);
            return url;
            // 2020-04-22 FUJITA mod-end
        }

        var downloadDataUri = function(uri, filename) {
            var link = document.createElement('a');
            link.download = filename;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        return function(table, filename) {
            if (!filename) {
                filename = 'output.csv';
            }
            var uri = createDataUriFromString(tableToCsvString(table));
            downloadDataUri(uri, filename);
        };

    })();







