import React, {useContext, useState, useEffect} from 'react'
import {ExclamationCircleOutlined, SearchOutlined} from "@ant-design/icons";
import position from '../../../asset/position.png'
import scale from '../../../asset/scale.png'
import {Divider, Input, Select} from "antd";
import {Option} from "antd/es/mentions";
import Checkbox from "antd/es/checkbox/Checkbox";
import Position from '../../../asset/position.png'
import Arrangement from '../../../asset/arrangement_information.png'
import Document from '../../../asset/document.png'
import Map from "../../../component/map_view";


import {Store} from "../../../state_store/provider";
import {getMaterialInfo, getOrderList} from "../../../api";
import MaterialInfoDialog from "../../../component/material_info_dialog";
import InfoConfirmationDialog from "../../../component/info_confirmation";
import OrderInfoTable from "../../../component/order_info_table";
import {ORDER} from "../../../state_store/actionType";


function MaterialInfo({data, onClick, onConfirm}){
    return (
        <div className={"mtr-info-layout"}>
            <div className={"mtr-header"}>
                <div className={"items"}>
                    <img src={position} alt={"position"}/>
                    <div className={"header-title"}>{data.plan_name}</div>
                </div>
                <div className={"items"}>
                    <div className={"header-btn"} onClick={onConfirm}>確認</div>
                    <div className={"btn-icon"} onClick={onClick}>
                        <ExclamationCircleOutlined/>
                    </div>

                </div>

            </div>

            <div className={"info"}>
                <div>{data.name + " ✕ " + data.plan_amaunt + "個"}</div>
                <div>{data.status === 2?"完了 ： "+data.confirm_time:"予定 ： " + data.time_plan}</div>
            </div>

        </div>
    )
}

export default function Material() {
    const {state, dispatch} = useContext(Store)
    const [filterOrder, setFilterOrder] = useState([])
    const [selected, setSelected] = useState({})
    const [materialInfo, setMaterialInfo] = useState([])
    const [infoDialog, setInfoDialog] = useState({open:false, data:{}})
    const [infoConfirm, setInfoConfirm] = useState({open:false, data:{}})
    const [expand, setExpend] = useState(0)
    const [filter, setFilter] = useState({company: "_default", check:false, name:""})
    const {site, index, order} = state
    useEffect(()=>{
            setFilterOrder(order)
            // getPlacementPointInfo().then(res => res)
    }, [order])

    useEffect(()=>{
        if(order.length === 0){
            const id = site[index]?.id
            getOrderList(id).then(res => {
                if(res.result === 0)
                    dispatch({type: ORDER, payload: res.orders})
            })
        }

    },[site])


    const onChange = ()=>{
        let tempFilter = state.order.filter(res=>res.name.toLowerCase().includes(filter.name.toLowerCase()))
        tempFilter = tempFilter.filter(res=>(res.status === 0) || !filter.check)

        if(filter.company === "_default"){
            setFilterOrder(tempFilter)
        }else {
            setFilterOrder(tempFilter.filter(res=>res.carrier_name === filter.company))
        }
    }

    const updateSelected = (res) => {
      setSelected(res)
        getMaterialInfo(res.id).then(res=>{
            if(res.result === 0){
                setMaterialInfo(res.material_info)
            }
        })
    }
    useEffect(()=>{
        onChange()
    }, [filter])

    const onChangeName = (e)=>{
        const {value} = e.target
        setFilter({...filter, name: value})
    }

    const onChangeCompany = (e)=>{
        setFilter({...filter, company: e})
    }
    const onChangeCheckBox = (e)=>{
        const {checked} = e.target
        setFilter({...filter, check: checked})
    }

    console.log("material rerender")
    return (
        <div className={"material column"}>

            {[0, 1].includes(expand)?<div className={"row md card-wrapper"}>
                <div className={`location column ${expand===1?"material-expand":""}`}>
                    <div className={"row card_header"}>
                        <div className={"row center"}>
                            <div className={"icon"}><img src={Position} width={16} alt={""}/></div>
                            <div className={"title"}>配置位置</div>
                        </div>
                        <div className={"row center"}/>


                    </div>
                    <div className={"divider-wrapper"}>
                        <Divider className={"divider"}/>
                    </div>
                    <div className={"content"} id={"material_container"}>


                        <Map id={"material_map"} key={expand} container_id={"material_container"}/>





                        <div className={"expand-btn-w"}
                            onClick={()=>setExpend(expand===0?1:0)}
                        >
                            <img src={scale} className={"expand-icon"}/>
                        </div>
                    </div>

                </div>

                {!expand?<div className={"placement column"}>
                    <div className={"row card_header"}>
                        <div className={"row center"}>
                            <div className={"icon"}><img src={Arrangement} width={16} alt={""}/></div>
                            <div className={"title"}>配置情報</div>
                        </div>
                        <div className={"row center"}>

                        </div>
                    </div>
                    <div className={"divider-wrapper"}>
                        <Divider className={"divider"}/>
                    </div>
                    <div className={"content"}>
                        {
                            materialInfo.map(res=>{
                                return <MaterialInfo key={res.id} data={res}
                                                     onConfirm={()=>setInfoConfirm({data:res, open: true})}
                                                     onClick={()=>setInfoDialog({data:res, open:true})}/>
                            })
                        }


                    </div>
                </div>:null}

            </div>:null}


            {
                ([0, 2].includes(expand)? <div className={`order_info card`}>
                    <div className={"wrapper"}>
                        <div className={"header row"}>
                            <div className={"row center"}>
                                <div className={"icon"}><img src={Document} width={12} /></div>
                                <div className={"title"}>資材情報</div>
                            </div>
                            <div className={"row center"}>

                            </div>
                        </div>

                        <Divider className={"divider"}/>
                    </div>
                    <div className={"row search"}>
                        <Select defaultValue={"_default"} className={"select"} onChange={onChangeCompany}>
                            <Option value="_default">全ての手配会社</Option>
                            {
                                state.group.map(res=>{
                                    return (<Option value={res.company}>{res.company}</Option>)
                                })
                            }


                        </Select>
                        <Checkbox className={"checkbox"}
                                  onChange={onChangeCheckBox}
                        >未納品の資材のみ表⽰</Checkbox>
                        <Input className={"search-input"}
                               onChange={onChangeName}
                               suffix={<SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/>}
                        />

                    </div>

                    <div className={`column table-wrapper ${expand===2?"expand-table":""}`}>
                        <OrderInfoTable orderData={filterOrder} id={selected.id} onSelect={updateSelected}/>
                        {/*<div className={"row table-header"}>*/}
                        {/*    <div>状態</div>*/}
                        {/*    <div>受注番号</div>*/}

                        {/*</div>*/}
                        {/*{filterOrder.map(res => (*/}
                        {/*    <div className={"row table-li"} key={res.id}*/}
                        {/*         onClick={()=>updateSelected(res)}*/}
                        {/*         style={res.id===selected.id ?{backgroundColor:"#F0F1F3",*/}
                        {/*             fontWeight:600,*/}
                        {/*             color:"#1890ff"}:{}}*/}
                        {/*    >*/}
                        {/*        <div>{Status[res.status]}</div>*/}
                        {/*        <div>{res.order_code}</div>*/}
                        {/*    </div>*/}
                        {/*))}*/}

                    </div>
                    <div className={"expand-btn-w"}
                         onClick={()=>setExpend(expand===0?2:0)}
                    >
                        <img src={scale} className={"expand-icon"}/>
                    </div>
                </div>:null)
            }


            <MaterialInfoDialog open={infoDialog.open} data={infoDialog.data} onClose={()=>setInfoDialog({open: false, data:{}})}/>
            <InfoConfirmationDialog open={infoConfirm.open} data={infoConfirm.data} onClose={()=>setInfoConfirm({open: false, data:{}})} />
        </div>
    )
}




