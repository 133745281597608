import React, {useContext} from 'react';
import {Modal, Button} from 'antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {deleteSite} from "../api";
import {Store} from "../state_store/provider";
import {INDEX, SITE} from "../state_store/actionType";
import {Request} from "../utils";

export default function DeleteDialog(props) {
    const {open, onClose, onOK} = props
    const {state, dispatch} = useContext(Store)
    const onDelete = ()=>{
        const id = state.site[state.index].id
        Request(dispatch, deleteSite(id), onOK).then(error=>{
            if(!error){
                const site = state.site.filter(res=>res.id !== id)
                dispatch({type:SITE, payload:site})
                if(state.index === site.length)
                    dispatch({type:INDEX, payload:0})
            }
        })
        // deleteSite(id).then(res=>{
        //     if (res.result === 0){
        //
        //         onOK()
        //         const site = state.site.filter(res=>res.id !== id)
        //         dispatch({type:SITE, payload:site})
        //         if(state.index === site.length)
        //             dispatch({type:INDEX, payload:0})
        //
        //     }
        // })
    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>


                <div className={"delete-dialog-body column center"}>
                    <div className={"icon"}>
                        <ExclamationCircleOutlined/>
                    </div>
                    <div className={"title"}>現場を削除</div>
                    <div className={"subtitle"}>
                        この現場に関連する情報が失われます。
                    </div>
                    <div className={"subtitle"}>
                        削除してよろしいですか？
                    </div>

                </div>
                <div className={"delete-dialog-footer row center"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={onDelete}
                        type="primary" style={{backgroundColor:"#377EF3"}}>削除する</Button>
                </div>
            </div>
        </Modal>
    );
};
