import React, {useContext, useEffect, useState} from 'react';
import {Modal, Button, Divider, Select, Input, AutoComplete} from 'antd';
import {addRoutePoint, getRoutePointName} from "../api";
import {Store} from "../state_store/provider";
import {ROUTE_NAME} from "../state_store/actionType";
import {Option} from "antd/es/mentions";
import {Request} from "../utils";

export default function RoadAddition(props) {

    const {open, onClose, data:{lat, lng}} = props
    const [route, setRoute] = useState({name:""})
    const [error, setError] = useState({submit:false, msg:"Name is required"})
    const {state, dispatch} = useContext(Store)
    const id = state.site[state.index].id
    const onCreateRoad = ()=>{
        if(!error.msg)
        Request(dispatch, addRoutePoint({siteId:id, lat, lng, name:route.name}), onClose).then(error=>{
            if(!error){}
            getRoutePointName(id).then(res=>{
                if(res.result === 0)
                    dispatch({type:ROUTE_NAME, payload:res.routePoints})

            })
        })
        setError({...error, submit: true})
    }

    useEffect(()=>{
        getRoutePointName(id).then(res=>{
            if(res.result === 0)
                dispatch({type:ROUTE_NAME, payload:res.routePoints})
        })
    }, [])

    const onChange = (value)=>{
        setRoute({name: value})
        setError({...error, msg: value?"": "Name is required"})
    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>資材希望位置表⽰名追加</div>
                <div className={"subtitle"}>追加する資材希望位置表⽰名を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>


                    <AutoComplete style={{width:"100%"}}

                                  value={route.name}
                                  onChange={onChange}
                                  options={state.route_name?.map((res, index)=>({value:res.name, key:index}))}
                                  filterOption={(inputValue, option) =>{
                                      return option.value.toLowerCase().includes(inputValue.toLowerCase())
                                  }
                                  }
                    >

                        <input type="text"
                               onChange={(e)=>onChange(e.target.value)}
                               className={`ant-input`}/>

                    </AutoComplete>
                    <div className={"helper-text-error"}>
                        {error.submit?error.msg:""}</div>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={onCreateRoad}
                        type="primary" style={{backgroundColor:"#377EF3"}}>追加する</Button>
                </div>
            </div>
        </Modal>
    );
};
