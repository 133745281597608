
import React, {useContext} from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {Store} from "../state_store/provider";

const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

export default function Progress(){
    const {state} = useContext(Store)
    return(
        <>
        {state.loading?<div className={"ant-modal-mask spin-mask"}>
                <Spin indicator={antIcon}/>
            </div>:""}
        </>
    )
}