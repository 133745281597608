import React, {useContext} from 'react';
import {Modal, Button} from 'antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {getSiteManagementHistories, removeHealthManagementReport} from "../api";
import {FormFormat, Request} from "../utils";
import {Store} from "../state_store/provider";
import {MANAGEMENT_HISTORY} from "../state_store/actionType";


export default function HealthReportDelete(props) {
    const {open, onClose, onOK, data} = props
    const {dispatch} = useContext(Store)

    const onDelete = ()=>{
        const site_id = localStorage.getItem("site_id")
        const point_id = sessionStorage.getItem("point_id")
        let submit_data = {
            point_id,
            keys: JSON.stringify({...data, report_date:data.date, site_id})
        }
        Request(dispatch, removeHealthManagementReport(FormFormat(submit_data)), onOK).then(error=>{
            if(!error){
                getSiteManagementHistories(FormFormat(submit_data)).then(res=>{
                    if(res.result === 0){
                        dispatch({type:MANAGEMENT_HISTORY, payload: res.historyList})

                    }

                })
            }
        })

    }


    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>


                <div className={"delete-dialog-body column center"}>
                    <div className={"icon"}>
                        <ExclamationCircleOutlined/>
                    </div>
                    <div className={"title"}>体調管理報告書削除</div>
                    <div className={"subtitle"}>
                        この体調管理報告書を削除しますか？
                    </div>

                </div>
                <div className={"delete-dialog-footer row center"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >いいえ</Button>
                    <Button
                        onClick={onDelete}
                        type="primary" style={{backgroundColor:"#377EF3"}}>はい</Button>
                </div>
            </div>
        </Modal>
    );
};
