import React, {useContext, useEffect, useState} from 'react';
import {Modal, Button, Divider, Select, AutoComplete} from 'antd';
import {editRoutePoint, getRoutePointName} from "../api";
import {Store} from "../state_store/provider";
import {ROUTE_NAME} from "../state_store/actionType";
import {Request} from "../utils";
import {EDIT, EDITING} from "../constant/variables";

export default function RoadEdition(props) {

    const {open, onClose, data} = props
    const [route, setRoute] = useState({name:""})
    const [error, setError] = useState({submit:false, msg:"Name is required"})
    const {state, dispatch} = useContext(Store)
    const id = state.site[state.index].id
    const onCreateRoad = ()=>{
        const {routePointId} = data.target
        if(!error.msg)
        Request(dispatch, editRoutePoint({id:routePointId, name:route.name}), onClose).then(err=>{
            if(!err){
                getRoutePointName(id).then(item=>{
                    if(item.result===0){
                        dispatch({type:ROUTE_NAME, payload:item.routePoints})
                        onClose()
                    }
                })
            }
        })
        setError({...error, submit: true})
    }

    useEffect(()=>{
        if(data){
            const {name} = data.target
            setRoute({name})
        }
    }, [data])

    const onChange = (value)=>{
        setRoute({name: value})
        setError({...error, msg: value?"": "Name is required"})

    }

    return (

        <Modal visible={open}
               footer={null}
               closable={false}
               width={400}
        >
            <div className={"dialog"}>
                <div className={"title"}>資材希望位置表⽰名{EDITING}</div>
                <div className={"subtitle"}>追加する資材希望位置表⽰名を⼊⼒してください。</div>
                <Divider className={"divider"}/>

                <div className={"add-dialog-body"}>

                    <AutoComplete style={{width:"100%"}}

                    value={route.name}
                    onChange={onChange}
                    options={state.route_name?.map((res, index)=>({value:res.name, key:index}))}
                    filterOption={(inputValue, option) =>{
                    return option.value.toLowerCase().includes(inputValue.toLowerCase())
                }
                }
                    >

                    <input type="text"
                           onChange={(e)=>onChange(e.target.value)}
                           className={`ant-input`}/>

                </AutoComplete>
                <div className={"helper-text-error"}>
                    {error.submit?error.msg:""}</div>
                </div>
                <div className={"add-dialog-footer row"}>
                    <Button type="primary" style={{backgroundColor:"#606060"}}
                            onClick={onClose}
                    >キャンセル</Button>
                    <Button
                        onClick={onCreateRoad}
                        type="primary" style={{backgroundColor:"#377EF3"}}>{EDIT}</Button>
                </div>
            </div>
        </Modal>
    );
};
