import React, {useContext, useEffect, useState} from 'react'
import {CloseOutlined} from '@ant-design/icons'

import addition from '../../asset/addition.png'
import edit from '../../asset/edit.png'
import delete_icon from '../../asset/deletion.png'

import CardMenu from './car_menu'
import MenuSite from "../../component/menu_site";

import SiteAddition from "../../component/site_addition";
import AddDialog from "../../component/add_dialog";
import DeleteDialog from "../../component/delete_dialog";
import {Route, Routes, Navigate} from "react-router-dom";

import MaterialOrdering from "./material_ordering";
import {getAuthUser, getPlacementPointInfo, getSite} from "../../api";
import {Store} from "../../state_store/provider";
import {INDEX, PLACEMENT_POINT, SITE, USER} from "../../state_store/actionType";
import EditDialog from "../../component/edit_dialog";
import {Icon} from '../../component/icon'
import FieldInformation from '../../asset/field_information.png'
const { innerWidth: width, innerHeight: height } = window;

export default function Home() {

    const [mini, setMini] = useState(width<=812)
    const [open_addition, setOpen_addition] = useState(false)
    const [open_edit, setOpen_edit] = useState(false)
    const [open_delete, setOpen_delete] = useState(false)
    const {state, dispatch} = useContext(Store)
    const {site, index} = state
    useEffect(()=>{
        initializer()
    }, [])


    const initializer = ()=>{
        getAuthUser().then(res=>{

            if(res){
                localStorage.setItem("user", res.id)
                dispatch({type:USER, payload:res})

            }

        })

        getSite().then(res=>{
            if(res.result === 0){
                dispatch({type:SITE, payload:res.sites})
                dispatch({type:INDEX, payload:0})
                if(res.sites.length > 0){
                    localStorage.setItem("site_id", res.sites[0].id)
                }
            }


        })
    }

    useEffect(()=>{
        const id = state.site[index]?.id
        if(id)
            getPlacementPointInfo(id).then(placement=>{
                if (placement.result === 0){

                    fetch(
                        "/mapfiles/" + placement.mapfile
                    )
                        .then((res) => res.text())
                        .then((kmlText) => {
                            const parser = new DOMParser();
                            const kml = parser.parseFromString(kmlText, "text/xml");
                            let long = parseFloat(kml.getElementsByTagName("longitude")[0].childNodes[0].data)
                            let lat = parseFloat(kml.getElementsByTagName("latitude")[0].childNodes[0].data)
                            dispatch({type:PLACEMENT_POINT, payload:{...placement, center:[lat, long], kml:kmlText}})

                        }).catch(error=>{
                        dispatch({type:PLACEMENT_POINT, payload:{...placement, error:true}})
                    });
                }
            })

    }, [state.index])

    return (
        <>
            <div className={"row"} style={{height: 40, alignItems: "center", margin: "0 8px"}}>
                <div className={"site-btn"} style={{backgroundColor:"white"}}>現場名</div>
                <div className={"site-btn"}>{site[index]?.name}</div>

            </div>
            <div className={"row"} style={{minHeight: "calc(100vh - 120px)", backgroundColor: "#F7F7F7"}}>
                {mini?<div className={"mini-drawer"}>
                        <div
                            className={"column center"}
                            onClick={()=>setMini(!mini)}
                        >
                        <Icon src={FieldInformation} size={20}/>
                        <div className={"mini-title"}>現場情報</div>
                    </div>
                    </div>
                :
                    <div className={"drawer-layout"}>
                        <div style={{padding: "12px 16px"}}>
                            <div className={"row"} style={{justifyContent: "space-between"}}>
                                <div style={{fontSize: 14, fontWeight: 600}}>現場情報</div>
                                <div className={"column click"} onClick={()=>setMini(!mini)}>
                                    <CloseOutlined style={{fontSize: 16}}/>
                                    <div style={{fontSize: 8}}>閉じる</div>
                                </div>
                            </div>

                            {
                                state.site.map((res,index) => (
                                    <div style={{
                                        backgroundColor: "#F0F1F3",
                                        width: "100%", marginTop: 12, borderRadius: 6
                                    }}
                                    // className={state.index === index?"select-background":""}
                                    >
                                        <MenuSite site={[res, index]}/>
                                    </div>
                                ))

                            }


                            <SiteAddition icon={addition}
                                          onClick={()=>setOpen_addition(true)}
                                          title={"現場情報追加"}/>
                            <SiteAddition icon={edit} onClick={()=>setOpen_edit(true)}
                                          title={"現場情報編集"}/>
                            <SiteAddition icon={delete_icon} title={"現場情報削除"}
                                            onClick={()=>setOpen_delete(true)}
                            />
                        </div>

                    </div>
                }
                <div className={"main-layout"}>

                    <Routes>
                        <Route exact path={"/"} element={<CardMenu/>}/>
                        <Route exact path={"/ordering"} element={<MaterialOrdering/>}/>
                        <Route exact path={"/*"} element={<Navigate to={"/"}/>}/>

                    </Routes>

                </div>
            </div>
            <AddDialog open={open_addition} onClose={()=>setOpen_addition(false)}
            onOK={()=>setOpen_addition(false)}
            />
            <EditDialog open={open_edit} onClose={()=>setOpen_edit(false)}
                        onOK={()=>setOpen_edit(false)}/>
            <DeleteDialog
            open={open_delete}
            onClose={()=>setOpen_delete(false)}
            onOK={()=>setOpen_delete(false)}
            />
        </>
    )
}
