import React, {useContext, useEffect, useState} from 'react'
import addition from '../../asset/addition.png'
import edition from '../../asset/edit.png'
import deletion from '../../asset/deletion.png'
import field_management from '../../asset/field_management.png'
import machine01 from '../../asset/machine01.png'
import machine02 from '../../asset/machine02.png'
import machine03 from '../../asset/machine03.png'
import {Divider} from "antd";
import {Store} from "../../state_store/provider";
import {getHeavyMachinery, getUsers, getWorkers} from "../../api";
import {HEAVY_MACHINE, USERS} from "../../state_store/actionType";
import TableIconButton from "../../component/table_icon_button";
import HeavyRegistration from "../../component/heavy_registration";
import HeavyEdition from "../../component/heavy_edition";
import HeavyDelete from "../../component/heavy_delete";
import {getConstructionMachineNameLabel, getWeightLabel} from "../../utils";


const machines = [machine01, machine02, machine03]

export default function Heavy(){

    const {state, dispatch} = useContext(Store)
    const [openAddition, setOpenAddition] = useState(false)
    const [openEdition, setOpenEdition] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [selected, setSelected] = useState({})
    useEffect(()=>{
        getHeavyMachinery().then(res=>{
            if(res.result === 0){
                dispatch({type:HEAVY_MACHINE, payload:res.heavyMachineryList})
            }
        })
    },[])

    const onOK =()=>{
        setSelected({})
        setOpenDelete(false)
        setOpenAddition(false)
        setOpenEdition(false)
    }

    return(
        <div className={"user-list"}>
            <div className={"wrapper"}>
                <div className={"header row"}>
                    <div className={"row center"}>
                        <div className={"icon"}><img src={field_management} width={18}  alt={""}/></div>
                        <div className={"title"}>重機登録</div>
                    </div>
                    <div className={"row center"}>
                        <TableIconButton icon={addition} title={"追加"}
                                         selected={openAddition}
                                         onClick={()=>setOpenAddition(true)}/>
                        <TableIconButton icon={edition} title={"編集"}
                                         selected={openEdition}
                                         onClick={()=>setOpenEdition(!!selected.id)}
                        />

                        <TableIconButton icon={deletion} title={"削除"}
                                         selected={openDelete}
                                         onClick={()=>setOpenDelete(!!selected.id)}
                        />
                    </div>
                </div>

                <Divider className={"divider"}/>
            </div>

            <div className={"column table-wrapper"}>
                <div className={"row g-heavy tb-h"}>
                    <div>重機名</div>
                    <div>作業装置</div>
                    <div>⾞両系建築機械名</div>
                    <div>型式</div>
                    <div>機体重量</div>
                    <div>機体能⼒</div>
                    <div>アイコン</div>

                </div>
                {state.heavy_machine.map((res, index)=>(
                    <div className={`row g-heavy sbd pointer tb-li ${res.id===selected.id?"row-active":""}`} key={res.id}

                         onClick={()=>setSelected(res)}
                    >
                        <div className={"text-wrapper"}>{res.name}</div>
                        <div className={"text-wrapper"}>{res.device}</div>
                        <div className={"text-wrapper"}>{getConstructionMachineNameLabel(res.construction_machine_name)}</div>
                        <div className={"text-wrapper"}>{res.model}</div>
                        <div className={"text-wrapper"}>{getWeightLabel(res.weight)}</div>
                        <div className={"text-wrapper"}>{res.ability}</div>
                        <div><img src={`/images/${res.image}`} style={{width:"auto", height:32}} alt={""}/></div>
                    </div>
                ))}



            </div>
            <HeavyRegistration open={openAddition}
                                onOK={onOK}
                                onClose={()=>setOpenAddition(false)}
            />
            <HeavyEdition  open={openEdition}
                            onOK={onOK}
                            onClose={()=>setOpenEdition(false)}
                            data={selected}
            />
            <HeavyDelete open={openDelete}
                           onOK={onOK}
                           onClose={()=>setOpenDelete(false)}
                           data={selected}
            />

        </div>
    )

}



