import React from 'react'
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Login from '../screen/login'
import Main from "../screen/main";
import WorkPlan from "../screen/workplan/work_plan";
import Safety from "../screen/workplan/safety";
import SafetyIndex from "../screen/workplan/safety_index";



export default function Index() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="login" element={<Login />} />
                <Route exact path={"/work-plan/index"} element={<WorkPlan/>}/>
                <Route exact path={"/ky-safe-work-instruction/index"} element={<Safety/>}/>
                <Route exact path={"/health-management-report/index"} element={<SafetyIndex/>}/>
                <Route path="/*" element={<Main/>}/>

            </Routes>
        </BrowserRouter>
    );
}